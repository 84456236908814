import { useRouter } from 'next/router';

export const useHash = () => {
	const { asPath } = useRouter();
	const hash = asPath.split('#')[1];
	const parsedHash = new URLSearchParams(hash);

	return {
		getHash: (type: string) => parsedHash.get(type),
	};
};
