import React, { FC, memo } from 'react';
import cls from './BookingPayment.module.css';
import classNames from 'classnames';
import { Flex, Input } from '../ui';
import { IconInfoCircle, IconInfoEnter } from '../icon';
import { Checkbox } from '../../old/ui';
import Link from 'next/link';
import { Tooltip } from '../ui/Tooltip';
import useTranslation from 'next-translate/useTranslation';
import { TEtgRates } from '@main/src/types/booking/accomodationBooking';
import { getCurrency, getTextPrice } from '@common/helpers';
import { EActivityType } from '@main/src/types/entities/activity';
import { useSelector } from 'react-redux';
import { ICommonData } from '@main/src/types/common';

interface IBookingPaymentProps {
	className?: string;
	nightCount: number;
	room: TEtgRates;
	hasUseAccept: boolean;
	onUserAccept(v: boolean): () => void;
}

export const BookingPayment = memo((props: IBookingPaymentProps) => {
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { className, nightCount, room, onUserAccept, hasUseAccept } = props;
	const { locale } = useSelector((state: ICommonData) => state);
	const { t } = useTranslation('api_hotel');
	const uiTranslations = useTranslation('ui');
	const price = getTextPrice(EActivityType.accommodation, null, {
		value: parseFloat(room.payment_options.payment_types[0].show_amount),
		currency: getCurrency(room.payment_options.payment_types[0].show_currency_code),
	});
	const priceDay = getTextPrice(EActivityType.accommodation, null, {
		value: parseFloat(room.daily_prices[0]),
		currency: getCurrency(room.payment_options.payment_types[0].show_currency_code),
	});

	const getAgreementLink = () => {
		if (locale?.code === 'ru') return 'https://ostrovok.ru/legal/order/agreement/bg/10/';
		return 'https://ostrovok.ru/legal/order/agreement/bg/11';
	};

	return (
		<div className={classNames(cls.BookingPayment, className)}>
			{/*<Flex className={cls.header} gap='10px'>*/}
			{/*	<div className={cls.headerItem}>*/}
			{/*		<p>Оплата сейчас</p>*/}
			{/*		<Tooltip*/}
			{/*			classNamePopup={cls.popup}*/}
			{/*			content={*/}
			{/*				<span className={cls.toolTip}>*/}
			{/*					<p>Политика оплаты</p>*/}
			{/*					<span>Проживание целиком оплачивается банковской картой в момент бронирования</span>*/}
			{/*				</span>*/}
			{/*			}*/}
			{/*		>*/}
			{/*			<IconInfoCircle />*/}
			{/*		</Tooltip>*/}
			{/*	</div>*/}
			{/*	<div className={cls.headerItem}>*/}
			{/*		<p>Важная информация</p>*/}
			{/*		<Tooltip*/}
			{/*			classNamePopup={cls.popup}*/}
			{/*			content={*/}
			{/*				<span className={cls.toolTip}>*/}
			{/*					<p>Политика оплаты</p>*/}
			{/*					<span>Проживание целиком оплачивается банковской картой в момент бронирования</span>*/}
			{/*				</span>*/}
			{/*			}*/}
			{/*		>*/}
			{/*			<IconInfoCircle />*/}
			{/*		</Tooltip>*/}
			{/*	</div>*/}
			{/*</Flex>*/}
			{/*<Flex className={cls.PromoCode} alignItems='center' gap='20px'>*/}
			{/*	<p>Применить промокод:</p>*/}
			{/*	<div className={cls.input}>*/}
			{/*		<SelectBooking placeholder='PROMOCODE' />*/}
			{/*		<IconInfoEnter className={cls.enter} />*/}
			{/*	</div>*/}
			{/*</Flex>*/}
			<Flex className={cls.summa} justifyContent='between' alignItems='end'>
				<Flex className={cls.info} gap='8px' direction='column'>
					<p>{uiTranslations.t('booking.bookingBlock.summa')}</p>
					<span>
						{t('search.nights', { count: nightCount })} x {priceDay}
					</span>
				</Flex>
				<p className={cls.price}>{price}</p>
			</Flex>
			<Flex className={cls.police} gap='10px'>
				<Checkbox checked={hasUseAccept} onChange={onUserAccept(!hasUseAccept)} />{' '}
				<p>
					{uiTranslations.t('booking.hotel.accept')}{' '}
					<Link href={getAgreementLink()} target='_blank'>
						{uiTranslations.t('booking.hotel.linkAcceptText')}
					</Link>
				</p>
			</Flex>
		</div>
	);
});
