import { v4 as uuidv4 } from 'uuid';
import { ProviderBtn } from '../../index';
import { ProviderButtonProps } from '@new/pages/BookingHotel/components/BookingAuthBlock/useLogin';
import { useRouter } from 'next/router';
import { openAuthUrl } from '@new/pages/BookingHotel/components/BookingAuthBlock/helpers';

export const VK_STATE_KEY = 'vk-a-state';
export const VK_LAST_URL = 'vk-last-url';
export const Vk = (props: ProviderButtonProps) => {
	const router = useRouter();
	const responseType = 'code';
	const scope = 'email';
	const onClick = () => {
		const state = uuidv4();
		sessionStorage.setItem(VK_STATE_KEY, state);
		sessionStorage.setItem(VK_LAST_URL, router.asPath);
		const href = `https://oauth.vk.com/authorize?scope=${scope}&state=${state}&response_type=${responseType}&client_id=${props.clientId}&redirect_uri=${props.redirectUri}`;
		if (router.asPath.startsWith('/login')) {
			location.href = href;
		} else {
			openAuthUrl(href);
		}
	};

	return <ProviderBtn {...{ ...props, onClick }} />;
};
