import { IParams } from './types';
import { getBrowserHttpClient } from '@app/api/browserHttpApi';
import { captureException } from '@sentry/core';

export const fetchCardEngage = async ({ type, env, cardId }: IParams): Promise<boolean> => {
	const api = await getBrowserHttpClient();
	try {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const response: any = await api.post<any, SalesClientCardReq>(
			'/sales/client/card-engage',
			{
				card_id: cardId,
				de: env as DataEnv,
				ct: type,
			},
			{
				headers: {},
			}
		);

		return true;
	} catch (error) {
		captureException(error);

		return false;
	}
};
