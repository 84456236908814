import { getBrowserHttpClient } from '@app/api/browserHttpApi';
import { captureException } from '@sentry/core';
import { IFetchRatesHotelParams } from '@app/api/services/shared/rooms/ratesHotel/types';

export const fetchRatesHotel = async ({
	mapSession,
	mapRequest,
	cardId,
}: IFetchRatesHotelParams): Promise<RoomsRatesHotelResp | null> => {
	const api = await getBrowserHttpClient();
	try {
		const response = await api.post<RoomsRatesHotelResp, RoomsRatesHotelReq>(
			'/client/rooms/rates-hotel',
			{
				map_ses: mapSession,
				map_req: mapRequest,
				card_id: cardId,
			},
			{
				headers: {},
			}
		);

		return response;
	} catch (error) {
		captureException(error);

		return null;
	}
};
