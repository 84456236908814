import { IFetchOstorovokTokenizeReq, IFetchOstorovokTokenizeRes } from './types';
import { captureException } from '@sentry/core';

export const fetchOstorovokTokenize = async (params: IFetchOstorovokTokenizeReq): Promise<boolean | null> => {
	try {
		const response = await fetch('https://api.payota.net/api/public/v1/manage/init_partners', {
			body: JSON.stringify(params),
			headers: {
				'Content-type': 'application/json; charset=UTF-8',
			},
			method: 'POST',
			mode: 'no-cors',
		});
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		// const result = (await response.json()) as IFetchOstorovokTokenizeRes;

		// TODO why response.ok, response.status equal always 0 for mode: 'no-cors'
		return true;
	} catch (error) {
		captureException(error);

		return null;
	}
};
