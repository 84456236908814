import { ElementType } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';

import cls from './HotelFilterDatePreview.module.css';
import { Flex } from '@main/src/new/components/ui';
import { HotelFilterInput } from '../HotelFilterInput';
import { ICommonData } from '@main/src/types/common';
import { IconCalendarBlue } from '@main/src/new/old/Icons';
import { DEFAULT_LOCALE } from '@app/constants/locales';

interface HotelFilterDatePreviewProps {
	className?: string;
	from: string;
	to?: string;
	showNightsCount?: boolean;
	onClick?: () => void;
	invert?: boolean;
}

export const HotelFilterDatePreview = ({
	className,
	from,
	to,
	showNightsCount = true,
	onClick,
	invert,
}: HotelFilterDatePreviewProps) => {
	const { locale } = useSelector((state: ICommonData) => state);
	const { t } = useTranslation('ui');

	const nights = dayjs(to).diff(from, 'days');

	return (
		<div className={classNames(className, cls.HotelFilterDatePreview)} onClick={onClick}>
			<Flex gap='10px' direction='row' className={cls.inputs}>
				<HotelFilterInput
					className={classNames('', { [cls.invertInput]: showNightsCount })}
					value={dayjs(from)
						.locale(locale?.code || DEFAULT_LOCALE.code)
						.format('D MMM')}
					icon={IconCalendarBlue as ElementType}
					invert={invert}
				/>
				<HotelFilterInput
					className={classNames('', { [cls.invertInput]: showNightsCount })}
					value={dayjs(to)
						.locale(locale?.code || DEFAULT_LOCALE.code)
						.format('D MMM')}
					icon={IconCalendarBlue as ElementType}
					invert={invert}
				/>
			</Flex>
			{showNightsCount && (
				<Flex className={cls.title} alignItems='center' justifyContent='flex-end'>
					<span>{t('booking.night', { count: nights })}</span>
				</Flex>
			)}
		</div>
	);
};
