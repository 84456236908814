import React, { memo } from 'react';
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';

import { IRoom } from '@new/pages/BookingHotel/types';
import { Room } from '@new/layouts/components/Header/components/Filters/GuestsInput/components/HotelFilterGuests/Room';
import { Flex } from '@new/components/ui';
import { IconPlus } from '@main/src/new/old/Icons';

import cls from './HotelFilterGuests.module.css';

interface IHotelFilterGuestsProps {
	className?: string;
	rooms: IRoom[];
	onChangeAdult(roomIndex: number): (v: number) => void;
	onChangeChild(roomIndex: number): (v: number) => void;
	onChangeChildAge(roomIndex: number, childIndex: number): (v: number) => void;
	onAddRoom(): void;
	onRemoveRoom(v: number): () => void;
}

export const MAX_ROOMS = 6;

export const HotelFilterGuests = memo((props: IHotelFilterGuestsProps) => {
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { className, rooms, onChangeAdult, onChangeChild, onChangeChildAge, onAddRoom, onRemoveRoom } = props;
	const uiTranslations = useTranslation('ui');

	return (
		<div className={classNames(cls.HotelFilterGuests, className)}>
			{rooms.map((room, index) => {
				return (
					<Room
						key={index}
						room={room}
						index={index}
						onChangeAdult={onChangeAdult}
						onChangeChild={onChangeChild}
						onChangeChildAge={onChangeChildAge}
						onRemoveRoom={onRemoveRoom}
					/>
				);
			})}
			{rooms.length < MAX_ROOMS && (
				<Flex
					className={cls.btn}
					maxWidth
					gap='10px'
					alignItems='center'
					justifyContent='flex-center'
					onClick={onAddRoom}
				>
					<Flex className={cls.icon} alignItems='center' justifyContent='flex-center'>
						<IconPlus />
					</Flex>
					<p>{uiTranslations.t('_filter.booking.addRoom')}</p>
				</Flex>
			)}
		</div>
	);
});
