import useTranslation from 'next-translate/useTranslation';
import React, { FormEvent, ReactElement, useState, MouseEvent, useMemo } from 'react';

import { Button, Flex } from '@new/components/ui';
import { Input } from '@new/pages/BookingHotel/components/Input';

import { AuthDocs } from '../AuthDocs';
import { Timer } from '../Timer';
import { LoginStatusesType } from '../../useLogin';

import cls from './Passwordless.module.css';

const isValidEmail = (email: string) =>
	// eslint-disable-next-line no-useless-escape
	/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
		email
	);
const SendCodeForm = ({
	loading,
	error,
	sendPasswordLessCode,
	email,
	setEmail,
}: {
	loading: boolean;
	error: string;
	sendPasswordLessCode(email: string): Promise<void>;
	email: string;
	setEmail(v: string): void;
}) => {
	const [value, setValue] = useState(email);
	const { t } = useTranslation('ui');

	const handlerChangeEmail = (e: FormEvent<HTMLInputElement>) => {
		setEmail(e.currentTarget.value);
		setValue(e.currentTarget.value);
	};
	const handlerSendEmail = async () => {
		void (await sendPasswordLessCode(String(value) || ''));
	};

	const hasInvalidEmail = !isValidEmail(value);
	const hasDisable = loading || hasInvalidEmail;
	const hasInputValid = (hasInvalidEmail && value !== '') || !!error;

	return (
		<Flex gap='8px' direction='column' className={cls.wrap}>
			<div className={cls.title}>{t('authPages.auth.passwordLessEmail')}</div>
			<Input
				required
				type='email'
				name='email'
				id='email'
				placeholder='example@email.com'
				invalid={hasInputValid}
				className={cls.fieldEmail}
				onChange={handlerChangeEmail}
				defaultValue={email}
				disabled={loading}
				autoFocus={true}
			/>
			{!error && <div className={cls.description}>{t('authPages.auth.passwordLessDescription')}</div>}
			{error && <div className={cls.error}>{error}</div>}
			<Flex direction='row' gap='8px' className={cls.buttonWrap}>
				<Button disabled={hasDisable} className={cls.requestCode} onClick={handlerSendEmail}>
					{t('authPages.auth.continue')}
				</Button>
			</Flex>
		</Flex>
	);
};

const VerifyAndLoginForm = ({
	loading,
	error,
	email,
	login,
	onTimerEnd,
	backToMailForm,
	loginStatus,
	sendPasswordLessCode,
}: {
	loading: boolean;
	error: string;
	email: string;
	login: (code: string) => Promise<void>;
	onTimerEnd: () => void;
	backToMailForm: () => void;
	loginStatus: LoginStatusesType;
	sendPasswordLessCode(email?: string): Promise<void>;
}) => {
	const { t } = useTranslation('ui');
	const { t: authTranslation } = useTranslation('auth');

	const [code, setCode] = useState<string>('');

	const handlerChangeCode = (e: FormEvent<HTMLInputElement>) => {
		setCode(e.currentTarget.value);
	};
	const handlerSendAuthCode = async () => {
		await login(code);
	};

	const handlerClickResendCode = async (e: MouseEvent<HTMLAnchorElement>) => {
		await sendPasswordLessCode(email);
		e.preventDefault();
		e.stopPropagation();
	};

	const hasInvalidCode = code.length > 5;
	const hasInputDisable = loading;
	const hasSendBtnDisable = code.length < 5;
	const hasInputValid = hasInvalidCode && code !== '';

	return (
		<Flex gap='8px' direction='column' className={cls.wrap}>
			<div className={cls.title}>{t('authPages.auth.enterCode')}</div>
			<Input
				required
				name='code'
				id='code'
				minLength={5}
				maxLength={5}
				placeholder={t('authPages.auth.code')}
				invalid={hasInputValid}
				className={cls.fieldEmail}
				onChange={handlerChangeCode}
				defaultValue={code}
				disabled={hasInputDisable}
				error={error}
				autoFocus={true}
			/>
			{loginStatus === 'showRefetchTimer' && (
				<Timer text={t('authPages.auth.timerText')} onExpire={onTimerEnd} timeInSec={60} />
			)}
			{loginStatus !== 'showRefetchTimer' && (
				<a className={cls.link} onClick={handlerClickResendCode}>
					{t('authPages.auth.codeRefetch')}
				</a>
			)}
			{error && <div className={cls.error}>{error}</div>}
			<div className={cls.description}>{authTranslation('form.code.input_help')}</div>
			<Flex className={cls.buttons} direction='row' gap='8px'>
				<Button disabled={hasSendBtnDisable} className={cls.requestCode} onClick={handlerSendAuthCode}>
					{t('authPages.auth.send')}
				</Button>
				<Button className={cls.requestCode} outline={true} onClick={backToMailForm}>
					{t('authPages.auth.emailChange')}
				</Button>
			</Flex>
		</Flex>
	);
};

interface PasswordLessLoginProps {
	loginStatus: LoginStatusesType;
	email: string;
	setEmail(v: string): void;
	loading: boolean;
	error: string;
	sendPasswordLessCode: (email: string) => Promise<void>;
	login: (code: string) => Promise<void>;
	onTimerEnd: () => void;
	backToMailForm: () => void;
}

export const PasswordLess = ({
	loginStatus,
	email,
	setEmail,
	sendPasswordLessCode,
	error,
	loading,
	login,
	onTimerEnd,
	backToMailForm,
}: PasswordLessLoginProps) => {
	const verifyForm = (
		<VerifyAndLoginForm
			email={email}
			error={error}
			loading={loading}
			login={login}
			onTimerEnd={onTimerEnd}
			backToMailForm={backToMailForm}
			loginStatus={loginStatus}
			sendPasswordLessCode={sendPasswordLessCode}
		/>
	);
	const status: Record<LoginStatusesType, ReactElement<unknown, ''>> = {
		init: (
			<SendCodeForm
				sendPasswordLessCode={sendPasswordLessCode}
				error={error}
				loading={loading}
				setEmail={setEmail}
				email={email}
			/>
		),
		showCodeForm: verifyForm,
		showRefetchTimer: verifyForm,
		showEmailForm: <></>,
	};
	const Component = useMemo(() => () => status[loginStatus] || <></>, [loginStatus]);

	return (
		<Flex gap='8px' className={cls.email} direction='column'>
			<Component />
		</Flex>
	);
};
