import React, { FC, memo, useLayoutEffect, useRef, useState } from 'react';
import cls from './BlockCheckouts.module.css';
import classNames from 'classnames';
import { Flex } from '@new/components/ui';
import { IconArrowDown } from '@new/components/icon';
import useTranslation from 'next-translate/useTranslation';
import { getAnswerParams } from '@common/helpers';
import { Checkbox } from '@main/src/new/old/ui';

interface IBlockCheckoutsProps {
	className?: string;
	title: string;
	filters: {
		key: string;
		value: string;
		count: number;
	}[];
	filterChange: (value: string) => () => void;
	localParams: string[];
	selectType: string;
}

const DEFAULT_HEIGHT = 54;
export const BlockCheckouts = (props: IBlockCheckoutsProps) => {
	const uiTranslations = useTranslation('ui');
	const tagsTranslations = useTranslation('api_cat');

	const { className, title, filters, localParams, filterChange, selectType } = props;
	const refContainer = useRef<HTMLDivElement | null>(null);
	const [open, setOpen] = useState(false);
	const showBtn = filters.length > 4;
	const changeVisible = () => {
		setOpen(prev => !prev);
	};

	return (
		<div className={classNames(className, cls.BlockCheckouts)}>
			<div className={cls.header}>{tagsTranslations.t(title)}</div>
			<div ref={refContainer} className={classNames(cls.content, open && cls.full)}>
				<Flex gap='2px' wrap justifyContent='between'>
					{filters.map((filter, index) => {
						let translationTitle = tagsTranslations.t(filter.value);
						if (translationTitle === filter.value && filter.value.includes('lang')) {
							translationTitle = tagsTranslations.t(`activity.${filter.value}`);
						}

						return (
							<Flex className={cls.item} gap='10px' alignItems='center' key={`group.${index}`}>
								<Checkbox checked={localParams.includes(filter.key)} onClick={filterChange(filter.key)} />
								<p>
									{translationTitle} <span>({filter.count})</span>
								</p>
							</Flex>
						);
					})}
				</Flex>
			</div>

			{showBtn && (
				<Flex maxWidth justifyContent='flex-end'>
					<Flex
						className={classNames(cls.moreButton, open && cls.open)}
						role='button'
						gap='6px'
						alignItems='center'
						onClick={changeVisible}
					>
						<p>{open ? uiTranslations.t('_filter.filters.hide') : uiTranslations.t('_filter.filters.showAll')} </p>
						<IconArrowDown />
					</Flex>
				</Flex>
			)}
		</div>
	);
};
