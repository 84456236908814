import { range } from 'lodash';

export const usePagination = (totalItems: number, currentPage: number, pageSize: number) => {
	const totalPages = Math.ceil(totalItems / pageSize);
	if (totalPages === 1) return {};
	let startPage = currentPage - 2;
	let endPage = currentPage + 2;

	if (startPage < 1) {
		startPage = 1;
		endPage = Math.min(totalPages, 5);
	}
	if (endPage > totalPages) {
		startPage = Math.max(1, totalPages - 4);
		endPage = totalPages;
	}

	const pages = range(startPage, endPage + 1);

	const canPrevious = currentPage > 1;
	const canNext = currentPage < totalPages;

	return { totalItems, currentPage, totalPages, startPage, endPage, pages, canPrevious, canNext };
};
