import { Input } from '@new/pages/BookingHotel/components/Input';
import useTranslation from 'next-translate/useTranslation';
import React, { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { ICommonData } from '@main/src/types/common';

export const CardData = memo(() => {
	const { control } = useFormContext();
	const { t } = useTranslation('api_hotel');
	const { locale } = useSelector((state: ICommonData) => state);

	return (
		<>
			<Controller
				name='cardNumber'
				control={control}
				render={({ field, fieldState }) => {
					//eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					const value = field.value as string;

					return (
						<Input
							required
							placeholder='1111 2222 3333 4444'
							label={t('credit_card.card_number')}
							value={value}
							onChange={field.onChange}
							invalid={fieldState.invalid}
						/>
					);
				}}
				rules={{
					required: true,
					pattern:
						/^(?:([0-9]{16}?)|(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|(3(?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/,
				}}
			/>
			<Controller
				name='expired'
				control={control}
				render={({ field, fieldState }) => {
					//eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					const value = field.value as string;

					return (
						<Input
							required
							placeholder={t('credit_card.exp')}
							label={t('credit_card.exp_month_year')}
							value={value}
							onChange={field.onChange}
							invalid={fieldState.invalid}
						/>
					);
				}}
				rules={{
					required: true,
					pattern: /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
				}}
			/>
			{/*<SwitcherBlock label='Запомнить карту' />*/}

			<Controller
				name='cvvCVC'
				control={control}
				render={({ field, fieldState }) => {
					//eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					const value = field.value as string;

					return (
						<Input
							required
							placeholder='***'
							label={t('credit_card.cvv')}
							value={value}
							onChange={field.onChange}
							invalid={fieldState.invalid}
						/>
					);
				}}
				rules={{
					required: true,
					pattern: /^[0-9]{3}/,
				}}
			/>
			<Controller
				name='name'
				control={control}
				render={({ field, fieldState }) => {
					//eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					const value = field.value as string;

					return (
						<Input
							required
							placeholder={locale?.code === 'ru' ? 'IVAN IVANOV' : 'JOHN DOE'}
							label={t('credit_card.name')}
							onChange={field.onChange}
							value={value}
							invalid={fieldState.invalid}
						/>
					);
				}}
				rules={{
					required: true,
					minLength: 5,
				}}
			/>
		</>
	);
});
