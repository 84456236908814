import React, { FC, useCallback, useMemo, useState } from 'react';
import moment, { Moment } from 'moment/moment';
import { useRouter } from 'next/router';
import { RangePicker } from '@new/old/ui';
import 'moment/locale/ru';
import type { RangeValue } from 'rc-picker/lib/interface';
import { useHotelGuestState } from '@common/hooks';
import cls from './DateRangeInput.module.css';
import enUS from 'antd/lib/locale/en_US';
import ruRU from 'antd/lib/locale/ru_RU';
import 'moment/locale/ru';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import { ICommonData } from '@main/src/types/common';
import { RangePickerProps } from 'antd/lib/date-picker';
import { range } from 'lodash/fp';

interface ICityInputProps {
	className?: string;
}

export const DateRangeInput: FC<ICityInputProps> = props => {
	const { bookGuestsState, setGuestsState } = useHotelGuestState();
	const { locale } = useSelector((state: ICommonData) => state);

	const handlerRangeChange = useCallback(
		(values: RangeValue<moment.Moment | null>, formatString: [string, string]) => {
			if (values && values[0] && values[1]) {
				const fromDate = values[0];
				let toDate = values[1];
				const diffFromToDates = toDate.diff(fromDate, 'days');

				if (diffFromToDates > 30) {
					toDate = moment(fromDate).add(30, 'days');
				} else if (diffFromToDates === 0) {
					toDate = moment(fromDate).add(1, 'days');
				} else {
					toDate = values[1];
				}

				setGuestsState({
					...bookGuestsState,
					fromDate: values[0].format('YYYY-MM-DD'),
					toDate: toDate.format('YYYY-MM-DD'),
				});
			}
		},
		[bookGuestsState]
	);

	const handlerDisableDate = useCallback(
		(value: Moment) => {
			const now = moment();
			const fromDate = moment(bookGuestsState.fromDate);
			const toDate = moment(bookGuestsState.toDate);
			const lastDate = moment(now).add('2', 'years');

			return value.isBefore(now.subtract(1, 'days')) || value < now.startOf('day') || value > lastDate.endOf('day');

			// return value && value < moment().endOf('day');
		},
		[bookGuestsState]
	);

	const antDLocale = locale?.code === 'ru' ? ruRU : enUS;

	return (
		<ConfigProvider locale={antDLocale}>
			<RangePicker
				format='D MMM YYYY'
				onChange={handlerRangeChange}
				value={[moment(bookGuestsState.fromDate), moment(bookGuestsState.toDate)]}
				disabledDate={handlerDisableDate}
				allowClear={false}
			/>
		</ConfigProvider>
	);
};
