import {
	NEXT_PUBLIC_APPLE_CLIENT_ID,
	NEXT_PUBLIC_FB_CLIENT_ID,
	NEXT_PUBLIC_GOOGLE_CLIENT_ID,
	NEXT_PUBLIC_VK_CLIENT_ID,
	NEXT_PUBLIC_YANDEX_CLIENT_ID,
} from '@app/constants';
import { Apple, Facebook, Google, Vk, Yandex } from './components/ProviderBtn/components/Providers';
import { OauthConfigType } from './useLogin';
import { Translate } from 'next-translate';

export const makeOAuthConfig = (t: Translate, county: string): OauthConfigType => {
	if (county === 'ru') {
		return {
			yandex: {
				redirectUri: `${global.location?.origin || ''}/auth/callback/yandex`,
				clientId: NEXT_PUBLIC_YANDEX_CLIENT_ID,
				title: t('authPages.login.social.yandex'),
				titleMobile: t('authPages.login.socialMobile.yandex'),
				imagePath: '/statics/yandex_logo.svg',
				Component: Yandex,
			},
			vk: {
				redirectUri: `${global.location?.origin || ''}/auth/callback/vk`,
				clientId: NEXT_PUBLIC_VK_CLIENT_ID,
				title: t('authPages.login.social.vk'),
				titleMobile: t('authPages.login.socialMobile.vk'),
				imagePath: '/statics/vk_logo.svg',
				Component: Vk,
			},
		} as OauthConfigType;
	}

	return {
		google: {
			clientId: NEXT_PUBLIC_GOOGLE_CLIENT_ID,
			redirectUri: `${global.location?.origin || ''}/auth/callback/google`,
			title: t('authPages.login.social.google'),
			titleMobile: t('authPages.login.socialMobile.google'),
			imagePath: '/statics/google_logo.svg',
			Component: Google,
		},
		apple: {
			redirectUri: `${global.location?.origin || ''}/auth/callback/apple`,
			clientId: NEXT_PUBLIC_APPLE_CLIENT_ID,
			title: t('authPages.login.social.apple'),
			titleMobile: t('authPages.login.socialMobile.apple'),
			imagePath: '/statics/apple_logo.svg',
			Component: Apple,
		},
		yandex: {
			redirectUri: `${global.location?.origin || ''}/auth/callback/yandex`,
			clientId: NEXT_PUBLIC_YANDEX_CLIENT_ID,
			title: t('authPages.login.social.yandex'),
			titleMobile: t('authPages.login.socialMobile.yandex'),
			imagePath: '/statics/yandex_logo.svg',
			Component: Yandex,
		},
		vk: {
			redirectUri: `${global.location?.origin || ''}/auth/callback/vk`,
			clientId: NEXT_PUBLIC_VK_CLIENT_ID,
			title: t('authPages.login.social.vk'),
			titleMobile: t('authPages.login.socialMobile.vk'),
			imagePath: '/statics/vk_logo.svg',
			Component: Vk,
		},
		// facebook: {
		// 	redirectUri: `${global.location?.origin || ''}/auth/callback/facebook`,
		// 	clientId: NEXT_PUBLIC_FB_CLIENT_ID,
		// 	i18nKey: t('authPages.login.social.facebook'),
		// 	imagePath: '/statics/facebook.svg',
		// 	Component: Facebook,
		// },
	};
};
