import { v4 as uuidv4 } from 'uuid';
import { ProviderBtn } from '../../index';
import { ProviderButtonProps } from '@new/pages/BookingHotel/components/BookingAuthBlock/useLogin';
import { useRouter } from 'next/router';
import { openAuthUrl } from '@new/pages/BookingHotel/components/BookingAuthBlock/helpers';

export const GOOGLE_STATE_KEY = 'g-a-state';
export const GOOGLE_LAST_URL = 'g-a-last-url';

export const Google = (props: ProviderButtonProps): JSX.Element => {
	const router = useRouter();
	const scope = 'https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile';
	const accessType = 'online';
	const responseType = 'token';
	const onClick = () => {
		const state = uuidv4();
		sessionStorage.setItem(GOOGLE_STATE_KEY, state);
		sessionStorage.setItem(GOOGLE_LAST_URL, router.asPath);
		const href = `https://accounts.google.com/o/oauth2/v2/auth?scope=${scope}&state=${state}&access_type=${accessType}&response_type=${responseType}&client_id=${props.clientId}&redirect_uri=${props.redirectUri}`;
		if (router.asPath.startsWith('/login')) {
			location.href = href;
		} else {
			openAuthUrl(href);
		}
	};

	return <ProviderBtn {...{ ...props, onClick }} />;
};
