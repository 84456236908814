import { createModel } from '@rematch/core';
import { EActivityProviderContact } from 'src/types/entities/rate';
import { IActivityProviderProps } from 'src/types/activity/details';

const initialState: IActivityProviderProps = {
	id: '1',
	avatarUrl: '/Provider.png',
	name: 'Merry Chi',
	rating: 4.8,
	description:
		'Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem, laboriosam nisi ut quia doloremque exercitationem atque beatae iste repellat porro.',
	contacts: [
		{
			type: EActivityProviderContact.email,
			value: 'Marrychi@mail.com',
		},
		{
			type: EActivityProviderContact.phone,
			value: '+7 (123) 456-78-90',
		},
	],
};

export const provider = createModel()({
	state: initialState,
});
