export interface IDefaultCity {
	city_id: string;
	display_name: string;
	lat_lng: number[];
	cards: number;
	image: string;
}
export const defaultCities = {
	russia: {
		en: [
			{
				city_id: '0225586eced7f183',
				display_name: 'Moscow',
				lat_lng: [55.750446100000005, 37.617494300000004],
				cards: 7400,
				image: '/statics/new/stub/city/moscow.jpg',
			},
			{
				city_id: 'd85c179628b9c4de',
				display_name: 'Sankt-Peterburg',
				lat_lng: [59.917442449999996, 30.30525445],
				cards: 7900,
				image: '/statics/new/stub/city/sankt-peterburg.jpg',
			},
			{
				city_id: 'f73364d7346e9870',
				display_name: 'Kazan',
				lat_lng: [55.766937350000006, 49.10091575],
				cards: 850,
				image: '/statics/new/stub/city/kazan.jpg',
			},
			{
				city_id: 'b719dd5188018f6e',
				display_name: 'Nizhniy Novgorod',
				lat_lng: [56.2950165, 43.91950225],
				cards: 1600,
				image: '/statics/new/stub/city/nizhniy.jpg',
			},
			{
				city_id: 'ba4e7e2a73133a61',
				display_name: 'Ekaterinburg',
				lat_lng: [56.78821815, 60.475181449999994],
				cards: 1700,
				image: '/statics/new/stub/city/ekaterinburg.jpg',
			},
			{
				city_id: '836354af8a02a899',
				display_name: 'Sochi',
				lat_lng: [43.7129412, 39.5799515],
				cards: 700,
				image: '/statics/new/stub/city/sochi.jpg',
			},
		],
		ru: [
			{
				city_id: '0225586eced7f183',
				display_name: 'Москва',
				lat_lng: [55.750446100000005, 37.617494300000004],
				cards: 7400,
				image: '/statics/new/stub/city/moscow.jpg',
			},
			{
				city_id: 'd85c179628b9c4de',
				display_name: 'Санкт-Петербург',
				lat_lng: [59.917442449999996, 30.30525445],
				cards: 7900,
				image: '/statics/new/stub/city/sankt-peterburg.jpg',
			},
			{
				city_id: 'f73364d7346e9870',
				display_name: 'Казань',
				lat_lng: [55.766937350000006, 49.10091575],
				cards: 850,
				image: '/statics/new/stub/city/kazan.jpg',
			},
			{
				city_id: 'b719dd5188018f6e',
				display_name: 'Нижний Новгород',
				lat_lng: [56.2950165, 43.91950225],
				cards: 1600,
				image: '/statics/new/stub/city/nizhniy.jpg',
			},
			{
				city_id: 'ba4e7e2a73133a61',
				display_name: 'Екатеринбург',
				lat_lng: [56.78821815, 60.475181449999994],
				cards: 1700,
				image: '/statics/new/stub/city/ekaterinburg.jpg',
			},
			{
				city_id: '836354af8a02a899',
				display_name: 'Сочи',
				lat_lng: [43.7129412, 39.5799515],
				cards: 700,
				image: '/statics/new/stub/city/sochi.jpg',
			},
		],
	},
	thailand: {
		ru: [
			{
				city_id: '0225586eced7f183',
				display_name: 'Паттайя',
				lat_lng: [12.9366674, 100.8864587],
				cards: 310,
				image: '/statics/new/stub/city/pattaya.jpg',
			},
			{
				city_id: 'd85c179628b9c4de',
				display_name: 'Пхукет',
				lat_lng: [7.97908465, 98.33552376453237],
				cards: 195,
				image: '/statics/new/stub/city/phuket.jpg',
			},
			{
				city_id: 'f73364d7346e9870',
				display_name: 'Краби',
				lat_lng: [8.0634637, 98.9162345],
				cards: 55,
				image: '/statics/new/stub/city/krabi.jpg',
			},
			{
				city_id: 'b719dd5188018f6e',
				display_name: 'Бангкок',
				lat_lng: [13.7524938, 100.4935089],
				cards: 132,
				image: '/statics/new/stub/city/bangkok.jpg',
			},
			{
				city_id: 'ba4e7e2a73133a61',
				display_name: 'Самуи',
				lat_lng: [9.5285113, 99.9347221],
				cards: 137,
				image: '/statics/new/stub/city/samui.jpg',
			},
			{
				city_id: '836354af8a02a899',
				display_name: 'Панган',
				lat_lng: [9.734950300000001, 100.0305710845208],
				cards: 700,
				image: '/statics/new/stub/city/KoPhangan.jpg',
			},
		],
		en: [
			{
				city_id: '0225586eced7f183',
				display_name: 'Pattaya',
				lat_lng: [12.9366674, 100.8864587],
				cards: 110,
				image: '/statics/new/stub/city/pattaya.jpg',
			},
			{
				city_id: 'd85c179628b9c4de',
				display_name: 'Phuket',
				lat_lng: [7.97908465, 98.33552376453237],
				cards: 195,
				image: '/statics/new/stub/city/phuket.jpg',
			},
			{
				city_id: 'f73364d7346e9870',
				display_name: 'Krabi',
				lat_lng: [8.0634637, 98.9162345],
				cards: 55,
				image: '/statics/new/stub/city/krabi.jpg',
			},
			{
				city_id: 'b719dd5188018f6e',
				display_name: 'Bangkok',
				lat_lng: [13.7524938, 100.4935089],
				cards: 72,
				image: '/statics/new/stub/city/bangkok.jpg',
			},
			{
				city_id: 'ba4e7e2a73133a61',
				display_name: 'Ko Samui',
				lat_lng: [9.5285113, 99.9347221],
				cards: 67,
				image: '/statics/new/stub/city/samui.jpg',
			},
			{
				city_id: '836354af8a02a899',
				display_name: 'Ko Pha-ngan',
				lat_lng: [9.734950300000001, 100.0305710845208],
				cards: 52,
				image: '/statics/new/stub/city/KoPhangan.jpg',
			},
		],
	},
};
