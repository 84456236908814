import { useEffect, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { CURRENT_LOCALE } from '@app/api/browserHttpApi';
import { useLocalStorage } from 'usehooks-ts';
import { useRouter } from 'next/router';

export type ProviderTypes = 'google' | 'apple' | 'yandex' | 'vk';
export interface ProviderButtonProps {
	clientId: string;
	redirectUri: string;
	imagePath: string;
	title: string;
	titleMobile: string;
	order?: number;
	onClick?: () => void;
}
export type OauthConfigType = Record<
	ProviderTypes,
	ProviderButtonProps & {
		Component: (props: ProviderButtonProps) => JSX.Element;
	}
>;

interface useLoginProps {
	oauthConfig: OauthConfigType;
	login(params: Auth2WebLoginReq): Promise<Auth2WebTokenResp>;
	sendPasswordlessCode(requestParams: Auth2WebOnetimeEmailReq): Promise<void>;
	onSuccess: () => void;
}

const getProvidersByDevice = (isAppleDevice: boolean) => {
	if (isAppleDevice) return ['apple', 'yandex', 'vk', 'google'];
	return ['google', 'yandex', 'vk', 'apple'];
};

export type LoginStatusesType = 'init' | 'showEmailForm' | 'showCodeForm' | 'showRefetchTimer';

export const useLogin = ({ oauthConfig, login, sendPasswordlessCode, onSuccess }: useLoginProps) => {
	const { t } = useTranslation('ui');
	const router = useRouter();
	const currentPath = router.asPath;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [loginStatus, setLoginStatus] = useState<LoginStatusesType>('init');
	const [email, setEmail] = useLocalStorage<string>('q_auth_u_email', '');

	const providers = getProvidersByDevice(false);

	useEffect(() => {
		setError('');
	}, [loginStatus]);

	const socialButtons = Object.entries(oauthConfig)
		.sort(([a], [b]) => providers.indexOf(a) - providers.indexOf(b))
		.map(([, i]) => i);

	const sendCode = async (val?: string) => {
		const sendEmail = val ?? email;
		if (sendEmail) {
			setLoading(true);
			try {
				await sendPasswordlessCode({
					email: sendEmail,
					lang: CURRENT_LOCALE as LangCodeForUI,
				});
				setLoading(false);
				setEmail(sendEmail);
				setLoginStatus('showRefetchTimer');
			} catch (e: any) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				if (e?.request?.status === 429) {
					setLoading(false);
					setEmail(sendEmail);
					setError(t('authPages.auth.moreRequests'));
					return;
				}
				setError(t('authPages.auth.unknownError'));
				setLoading(false);
			}
		}
	};

	const doLogin = async (code: string) => {
		if (code) {
			setLoading(true);
			try {
				await login({
					auth_type: 'email',
					email,
					code,
					lang: CURRENT_LOCALE as LangCodeForUI,
				});
				setLoading(false);
				onSuccess();
				if (currentPath.startsWith('/login')) {
					await router.push('/');
				}
			} catch (e: any) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				if (e?.request?.status === 401) {
					setError(t('authPages.auth.codeError'));
					setLoading(false);
					return;
				}
				setError(t('authPages.auth.unknownError'));
				setLoading(false);
			}
		}
	};

	const onTimerEnd = () => setLoginStatus('showCodeForm');

	const backToMailForm = () => setLoginStatus('init');

	return {
		socialButtons,
		loginStatus,
		setLoginStatus,
		email,
		error,
		loading,
		sendCode,
		doLogin,
		onTimerEnd,
		backToMailForm,
		setEmail,
	};
};
