import { createModel } from '@rematch/core';
import { ICategoriesArticle } from 'src/types/entities/knowledgeBase/categories';

const initialState: ICategoriesArticle[] = [
	{
		id: '1',
		author: 'John Doe',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisi eu consectetur consectetur, nisl nisl consectetur nisl, euismod nisl nisl euismod nisl.',
		image: 'https://picsum.photos/400',
		slug: 'vacations-break',
		title: 'The magic of worlds best places',
	},
	{
		id: '2',
		author: 'John Doe',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisi eu consectetur consectetur, nisl nisl consectetur nisl, euismod nisl nisl euismod nisl.',
		image: 'https://picsum.photos/400',
		slug: 'vacations-break',
		title: 'The magic of worlds best places',
	},
	{
		id: '3',
		author: 'John Doe',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisi eu consectetur consectetur, nisl nisl consectetur nisl, euismod nisl nisl euismod nisl.',
		image: 'https://picsum.photos/400',
		slug: 'vacations-break',
		title: 'The magic of worlds best places',
	},
	{
		id: '4',
		author: 'John Doe',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisi eu consectetur consectetur, nisl nisl consectetur nisl, euismod nisl nisl euismod nisl.',
		image: 'https://picsum.photos/400',
		slug: 'vacations-break',
		title: 'The magic of worlds best places',
	},
	{
		id: '5',
		author: 'John Doe',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisi eu consectetur consectetur, nisl nisl consectetur nisl, euismod nisl nisl euismod nisl.',
		image: 'https://picsum.photos/400',
		slug: 'vacations-break',
		title: 'The magic of worlds best places',
	},
	{
		id: '6',
		author: 'John Doe',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisi eu consectetur consectetur, nisl nisl consectetur nisl, euismod nisl nisl euismod nisl.',
		image: 'https://picsum.photos/400',
		slug: 'vacations-break',
		title: 'The magic of worlds best places',
	},
	{
		id: '7',
		author: 'John Doe',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisi eu consectetur consectetur, nisl nisl consectetur nisl, euismod nisl nisl euismod nisl.',
		image: 'https://picsum.photos/400',
		slug: 'vacations-break',
		title: 'The magic of worlds best places',
	},
	{
		id: '8',
		author: 'John Doe',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisi eu consectetur consectetur, nisl nisl consectetur nisl, euismod nisl nisl euismod nisl.',
		image: 'https://picsum.photos/400',
		slug: 'vacations-break',
		title: 'The magic of worlds best places',
	},
	{
		id: '9',
		author: 'John Doe',
		description:
			'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec euismod, nisi eu consectetur consectetur, nisl nisl consectetur nisl, euismod nisl nisl euismod nisl.',
		image: 'https://picsum.photos/400',
		slug: 'vacations-break',
		title: 'The magic of worlds best places',
	},
];

export const knowledgeBaseCategory = createModel()({
	state: initialState,
});
