import React, { InputHTMLAttributes, memo, ReactNode, useState } from 'react';
import cls from './Input.module.css';
import classNames from 'classnames';
import { Tooltip } from '@new/components/ui/Tooltip';
import { IconInfoCircle } from '@new/components/icon';

interface IInputProps extends InputHTMLAttributes<HTMLInputElement> {
	className?: string;
	label?: string;
	error?: string;
	isError?: boolean;
	invalid?: boolean;
	labelPopupContent?: ReactNode;
}

export const Input = memo((props: IInputProps) => {
	const { className, label, required, error, isError, invalid, labelPopupContent, ...otherProps } = props;
	const [isFocusStatus, changeFocusStatus] = useState(false);
	return (
		<div
			className={classNames(
				className,
				cls.container,
				required && cls.required,
				isFocusStatus && cls.focused,
				invalid && cls.invalid
			)}
		>
			{label && !labelPopupContent && <p className={cls.label}>{label}</p>}
			{label && labelPopupContent && (
				<Tooltip classNamePopup={cls.popup} content={labelPopupContent}>
					<div className={cls.toolTipWrapper}>
						<p className={cls.label}>{label}</p> <IconInfoCircle />
					</div>
				</Tooltip>
			)}
			<input
				{...otherProps}
				onFocus={() => {
					changeFocusStatus(true);
				}}
				onBlur={() => {
					changeFocusStatus(false);
				}}
			/>
			{error && isError && <div className={cls.error}>{error}</div>}
		</div>
	);
});
