import classNames from 'classnames';

import { ProviderButtonProps } from '../../useLogin';

import cls from './ProviderBtn.module.css';

export const ProviderBtn = ({ title, titleMobile, imagePath, onClick }: ProviderButtonProps) => {
	return (
		<div className={cls.wrapper} onClick={onClick}>
			<div className={cls.icon}>
				<img
					className={cls.img}
					alt='Qvedo login with Apple'
					loading='lazy'
					decoding='async'
					data-nimg='fill'
					src={imagePath}
				/>
			</div>
			<div className={classNames(cls.text, cls.textDesc)}>{title}</div>
			<div className={classNames(cls.text, cls.textMobile)}>{titleMobile}</div>
		</div>
	);
};

ProviderBtn.displayName = 'ProviderBtn';
