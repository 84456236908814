import { combineCommonModels } from '@app/stores/helpers';
import { init, RematchStore } from '@rematch/core';
import { user } from '../../models';
import { IInitState, RootModel } from './types';

export const models = combineCommonModels<RootModel>({ abc: user });

export const makeStore = (initState: IInitState): RematchStore<RootModel, RootModel> => {
	const state = init<RootModel>({ models }).getState();

	return init<RootModel>({
		models,
		redux: { initialState: { ...state, ...initState } },
	});
};

export default makeStore;
