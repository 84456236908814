import { MobileModal } from '@main/src/new/components/ui/MobileModal';

import { Menu } from '../Menu';

interface NavigateModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const NavigateModal = ({ isOpen, onClose }: NavigateModalProps) => {
	return (
		<MobileModal isOpen={isOpen} close={onClose} type='drawer' top='0%' zIndex={998}>
			<Menu onClick={onClose} />
		</MobileModal>
	);
};
