import { getBrowserHttpClient } from '@app/api/browserHttpApi';
import { captureException } from '@sentry/core';
import axios, { AxiosError } from 'axios';
import { TEtgCreateError, TEtgCreateOstorovokError, TEtgCreateParams } from './type';

export const fetchOrderEtgCreate = async ({
	card_id,
	hotel_rate,
	first_name_original,
	last_name_original,
	rooms,
	user,
	search_opt,
	arrival_datetime,
}: TEtgCreateParams): Promise<OrderUsrEtgCreateResp | TEtgCreateError | TEtgCreateOstorovokError> => {
	const api = await getBrowserHttpClient();
	try {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const result = await api.post<OrderUsrEtgCreateResp, OrderUsrEtgCreateReq>(
			'/client/order/etg-create',
			{
				card_id,
				hotel_rate,
				first_name_original,
				last_name_original,
				rooms,
				user,
				search_opt,
				arrival_datetime,
			},
			{
				headers: {},
			}
		);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return result;
	} catch (error) {
		captureException(error);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (error?.status === 412) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			const errors = error?.data?.errors as string[];

			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return errors[0] as TEtgCreateError;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
		} else if (error?.status === 422) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			const errors = error?.data?.errors as string[];

			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return errors[0] as TEtgCreateError;
		}

		return 'unknown error';
	}
};
