import classNames from 'classnames';
import { ChangeEvent, InputHTMLAttributes, memo, useEffect, useRef } from 'react';

import cls from './MobileInput.module.css';

export interface IInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange' | 'onFocused'> {
	className?: string;
	onChange?: (i: string) => void;
	focus?: boolean;
	onFocused?: () => void;
}

export const MobileInput = memo((props: IInputProps) => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { className, onChange, focus, onFocused, ...otherProps } = props;
	const inputRef = useRef<HTMLInputElement | null>(null);
	const handlerChange = (e: ChangeEvent<HTMLInputElement>) => {
		onChange?.(e.target.value);
	};
	const handlerFocused = (e: ChangeEvent<HTMLInputElement>) => {
		onFocused?.();
	};
	useEffect(() => {
		if (focus && !!inputRef.current) {
			inputRef.current.focus();
		}
	}, [focus]);

	return (
		<input
			type='text'
			ref={inputRef}
			className={classNames(cls.input, className)}
			onChange={handlerChange}
			onFocus={handlerFocused}
			{...otherProps}
		/>
	);
});
