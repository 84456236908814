import { createModel } from '@rematch/core';
import { IUserLocation } from '../../../types/common';
import { fallbackStartHomeData } from '@app/api/services/node/startHome/constant';

export const initialState: IUserLocation = {
	point: fallbackStartHomeData.data.point,
	city: {
		display_name: '',
		country_code: '',
		id: '',
	},
};

export const userLocation = createModel()({
	state: initialState,
});
