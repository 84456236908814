import { createModel } from '@rematch/core';
import { IETours } from 'src/types/entities/etour';
import { TTagKey } from '../../../types/search';

export const initialState: IETours = {
	filters: undefined,
};

export const eTours = createModel()({
	state: initialState,
	reducers: {
		setFilterTagActive(state: IETours, payload: TTagKey): IETours {
			if (state.filters && state.filters?.tags) {
				const tags = state.filters?.tags;
				tags[payload].isActive = !tags[payload].isActive;

				return {
					...state,
					filters: {
						...state.filters,
						tags,
					},
				};
			} else {
				return state;
			}
		},
	},
});
