import { createModel } from '@rematch/core';
import { IVisitor } from '@main/src/types/common/visitor';

// TODO move to contacts
export const initialState: IVisitor = {
	visitor: '',
	latLng: [55.75, 37.61],
	cc: 'ru',
};

export const visitor = createModel()({
	state: initialState,
});
