export const RESTAURANT_CAT_LIST = ['category', 'price_level', 'cuisine', 'diet', 'theme', 'additional_filters'];
export const ATTRACTION_CAT_LIST = ['category', 'additional_filters'];
export const ACTIVITY_CAT_LIST = [
	'group_type',
	'category',
	'additional_filters',
	'schedule_type',
	'housing',
	'lang',
	'transfer',
];
export const PUBLIC_PLACE_CAT_LIST = ['category'];
export const ACCOMMODATION_CAT_LIST = ['kd', 'st', 'se'];

export const CAT_TAGS = {
	'1': RESTAURANT_CAT_LIST,
	'2': ATTRACTION_CAT_LIST,
	'3': ACCOMMODATION_CAT_LIST,
	'4': PUBLIC_PLACE_CAT_LIST,
	'5': ACTIVITY_CAT_LIST,
};
