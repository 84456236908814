import { createModel } from '@rematch/core';
import { IUserLocation } from '../../../types/common';
import { fallbackStartHomeData } from '@app/api/services/node/startHome/constant';
import { ICardResultV2 } from '@main/src/types/map/searchArea';
import { IActivityCard } from '@main/src/types/entities/activity';

export const initialState: IActivityCard[] = [];

export const nearby = createModel()({
	state: initialState,
});
