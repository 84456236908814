import 'mobile-select/dist/style/mobile-select.css';
import { useRef, useEffect, memo } from 'react';
import MobileSelect, { CustomConfig } from 'mobile-select';

export interface MSProps {
	config: Omit<CustomConfig, 'trigger'>;
	placeholder?: string;
	children?: JSX.Element;
}

export const Select = memo((props: MSProps) => {
	const triggerRef = useRef(null);
	const instanceRef = useRef<any>(null);

	useEffect(() => {
		if (!instanceRef.current) {
			instanceRef.current = new MobileSelect({
				...props.config,
				trigger: triggerRef.current as unknown as HTMLElement,
				triggerDisplayValue: !props.children,
			});
		}

		return () => {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
			instanceRef?.current.destroy();
			instanceRef.current = null;
		};
	}, []);

	return (
		<div className='ms-default-trigger' ref={triggerRef}>
			{props.children}
		</div>
	);
});
