import React, { memo, useMemo } from 'react';

import { Flex } from '@main/src/new/components/ui';
import { Checkout } from '@main/src/new/components/ui/Checkout';

import cls from './ItemTag.module.css';

interface IItemTagProps {
	className?: string;
	tag: {
		key: string;
		value: string;
		count: number;
	};
	onClick: () => void;
	value: boolean;
	translatedTag: string;
}

export const ItemTag = memo(({ tag, onClick, value, translatedTag }: IItemTagProps) => {
	const currentCount = useMemo(() => {
		if (tag.count < 10000) {
			return tag.count.toString();
		}

		return '9999+';
	}, [tag]);

	return (
		<Flex className={cls.item} alignItems='center' justifyContent='between' maxWidth onClick={onClick}>
			<p>{translatedTag}</p>
			<span>{currentCount}</span>
			<Checkout onClick={onClick} value={value} />
		</Flex>
	);
});
