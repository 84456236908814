import { ElementType } from 'react';
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';

import { IconProfile } from '@main/src/new/components/icon';
import { Flex } from '@main/src/new/components/ui';

import { HotelFilterInput } from '../HotelFilterInput';
import { IRoom } from '../../../Filters/GuestsInput/components/HotelFilterGuests/Room';

import cls from './HotelFilterGuestsPreview.module.css';

interface HotelFilterGuestPreview {
	className?: string;
	rooms: IRoom[];
}

export const HotelFilterGuestsPreview = ({ className, rooms }: HotelFilterGuestPreview) => {
	const { t } = useTranslation('api_hotel');

	const getRoomValue = (room: IRoom, i: number) => {
		const res: string[] = [t('search.index_room', { count: i + 1 })];
		res.push(t('search.count_adults', { count: room.adults }));
		if (room.children.length) res.push(t('search.count_children', { count: room.children.length }));

		return res.join(', ');
	};

	return (
		<Flex gap='10px' direction='column' className={classNames(cls.HotelFilterGuestsPreview, className)}>
			{rooms.map((room, i) => (
				<HotelFilterInput key={i} value={getRoomValue(room, i)} icon={IconProfile as ElementType} />
			))}
		</Flex>
	);
};
