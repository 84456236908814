import { createModel } from '@rematch/core';
import { ILocale } from 'src/types/common';

export const initialState: ILocale = {
	code: '',
	name: '',
	originalName: '',
	countryCode: '',
};

export const locale = createModel()({
	state: initialState,
});
