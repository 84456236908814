import { captureException } from '@sentry/core';
import axios from 'axios';
import { TEtgStatusError, TEtgStatusOstorovokError, TEtgStatusParams } from './type';
import { getBrowserHttpClient } from '@app/api/browserHttpApi';

export const fetchOrderEtgStatus = async ({
	order_id,
}: TEtgStatusParams): Promise<OrderUsrEtgStatusResp | TEtgStatusError | TEtgStatusOstorovokError> => {
	const api = await getBrowserHttpClient();
	try {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const result = await api.post<OrderUsrEtgStatusResp, OrderUsrEtgStatusReq>(
			'/client/order/etg-status',
			{
				order_id,
			},
			{
				headers: {},
			}
		);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return result;
	} catch (error) {
		captureException(error);
		if (axios.isAxiosError(error)) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			if (error?.status === 412) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				const errors = error?.data?.errors as string[];

				// eslint-disable-next-line @typescript-eslint/no-unsafe-return
				return errors[0] as TEtgStatusError;
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
			} else if (error?.status === 422) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				const errors = error?.data?.errors as string[];
				return errors[0] as TEtgStatusError;
			}
		}

		return 'unknown error';
	}
};
