import { IFetchHotelRatesDirectParams, IRoomsRatesDirectReq, IRoomsRatesHotelResp } from './types';
import { captureException } from '@sentry/core';
import { mapper } from './mapper';
import { getBrowserHttpClient } from '@app/api/browserHttpApi';
import { IRoomsRatesHotel } from '@main/src/types/booking/accomodationBooking';
import { fetchRatesHotel } from '@app/api/services/shared/rooms/ratesHotel';
import { fetchCurrencyRates } from '@app/api/services/node/currencyRates';
import { getCurrency } from '@common/helpers';
import { DEFAULT_CURRENCY } from '@app/constants/defaults';

let hasFirst = true;
export const fetchHotelRatesDirect = async ({
	cardId,
	checkoutDate,
	guests,
	checkinDate,
	currency,
	locale,
	mapRequest,
	mapSession,
}: IFetchHotelRatesDirectParams): Promise<IRoomsRatesHotel | null> => {
	const api = await getBrowserHttpClient();
	try {
		const currencyRates = await fetchCurrencyRates();
		if (hasFirst && mapSession && mapRequest) {
			const cachedRates = await fetchRatesHotel({
				cardId,
				mapRequest,
				mapSession,
			});

			if (cachedRates) {
				hasFirst = false;
				return mapper(checkinDate, checkoutDate, cachedRates, currencyRates, currency, locale);
			}
		}

		const currentCurrency = locale?.code === 'ru' ? getCurrency(DEFAULT_CURRENCY) : getCurrency('USD');
		const response: IRoomsRatesHotelResp = await api.post<IRoomsRatesHotelResp, IRoomsRatesDirectReq>(
			'/client/rooms/rates-direct',
			{
				card_id: cardId,
				search_opt: {
					currency: currentCurrency.code,
					guests: guests,
					checkin: checkinDate,
					checkout: checkoutDate,
				},
			},
			{
				headers: {},
			}
		);

		return mapper(checkinDate, checkoutDate, response, currencyRates, currency, locale);
	} catch (error) {
		captureException(error);

		return null;
	}
};
