import { combineCommonModels } from '@app/stores/helpers';
import { accommodation } from '@app/stores/models/accommodation';
import { init, RematchStore } from '@rematch/core';
import { eTours, attraction, guides, activity, restaurant, public_place, userLocation } from '../../models';
import { TInitState, RootModel } from './types';

const models = combineCommonModels<RootModel>({
	accommodation,
	activity,
	attraction,
	restaurant,
	public_place,
	userLocation,
});

export const makeStore = (initState: TInitState): RematchStore<RootModel, RootModel> => {
	const state = init<RootModel>({ models }).getState();

	return init<RootModel>({
		models,
		redux: { initialState: { ...state, ...initState } },
	});
};

export default makeStore;
