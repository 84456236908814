import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { ICommonData } from '@main/src/types/common';
import { fetchHotelRatesDirect } from '@app/api/services/shared/hotelRatesDirect';
import { ILocalStorageGuestsState } from '@new/pages/BookingHotel/types';
import { getCurrency } from '@common/helpers';
import { DEFAULT_CURRENCY } from '@app/constants/defaults';
import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { IRates } from '@main/src/types/booking/accomodationBooking';
import { useIsFirstRender } from 'usehooks-ts';
import { REFETCH_RATES_BY_MINUTES } from '@app/constants/ratesDirect';
import { RootState } from '@app/stores/pages/offerDetail';
import { useRouter } from 'next/router';
import { urlParams } from '@common/helpers/urtParams';

const REFETCH_RATES_BY_MILISECONDS = 1000 * 60 * REFETCH_RATES_BY_MINUTES;

export interface IUseRoomRates {
	cardId: string;
	bookGuestsState: ILocalStorageGuestsState;
	enabledAutoReq?: boolean;
	requestId?: string | null;
	session?: string | null;
}

export const useRoomRates = ({ cardId, bookGuestsState, enabledAutoReq, requestId, session }: IUseRoomRates) => {
	const { locale } = useSelector((state: ICommonData) => state);
	// const currentCurrency = locale?.code === 'ru' ? getCurrency(DEFAULT_CURRENCY) : getCurrency('USD');
	const [lastTimeRequestRates, setLastTimeRequestRates] = useState<Dayjs>(dayjs());
	const [ratesData, setRatesData] = useState<IRates | undefined>(undefined);
	const [hasRunInterval, setHasRunInterval] = useState<boolean>(true);
	const currency = useSelector((state: RootState) => state.currency);
	const router = useRouter();

	const requestRates = useQuery(
		['rated-direct', cardId, JSON.stringify(bookGuestsState)],
		async () => {
			const res = await fetchHotelRatesDirect({
				cardId,
				checkoutDate: bookGuestsState.toDate as string,
				currency: currency || getCurrency(DEFAULT_CURRENCY),
				locale,
				guests: bookGuestsState.rooms,
				checkinDate: bookGuestsState.fromDate,
				mapRequest: requestId,
				mapSession: session,
			});
			setTimeout(() => {
				void router.replace(urlParams().replace(router.asPath, ['session', 'request']), undefined, {
					shallow: true,
				});
			}, 1000);
			return res;
		},
		{
			enabled: false,
			staleTime: REFETCH_RATES_BY_MILISECONDS,
		}
	);

	useEffect(() => {
		if (requestRates.isFetching) {
			setRatesData({
				rates: null,
				status: 'loading',
			});
		} else if (ratesData?.status === 'loading' && requestRates.isSuccess && requestRates.data) {
			setRatesData({
				rates: requestRates.data,
				status: 'success',
			});
			setLastTimeRequestRates(dayjs());
		} else if (ratesData?.status === 'loading' && (requestRates.isError || !requestRates.data)) {
			setRatesData({
				rates: null,
				status: 'error',
			});
			setLastTimeRequestRates(dayjs());
		}
	}, [requestRates, ratesData]);

	const isFirstRender = useIsFirstRender();
	useEffect(() => {
		if (isFirstRender && enabledAutoReq) {
			setTimeout(() => {
				void requestRates.refetch();
			}, 1000);
		}
	}, [isFirstRender, enabledAutoReq]);

	useEffect(() => {
		const intervalId = setInterval(() => {
			if (hasRunInterval) {
				void requestRates.refetch();
			}
		}, REFETCH_RATES_BY_MILISECONDS);

		return () => clearInterval(intervalId);
	}, [hasRunInterval]);

	useEffect(() => {
		const handlerVisibilityChange = (event: Event) => {
			setHasRunInterval(!document.hidden);
		};
		document.addEventListener('visibilitychange', handlerVisibilityChange);

		return () => window.removeEventListener('visibilitychange', handlerVisibilityChange);
	}, []);

	useEffect(() => {
		if (hasRunInterval) {
			const now = dayjs();
			const hasRunByTime = now.diff(dayjs(lastTimeRequestRates), 'minutes') >= REFETCH_RATES_BY_MINUTES;
			if (hasRunByTime) {
				void requestRates.refetch();
			}
		}
	}, [hasRunInterval, lastTimeRequestRates]);

	useEffect(() => {
		if (session && requestId) {
			void requestRates.refetch();
		}
	}, []);

	return {
		ratesData,
		setHasRunInterval,
		requestRates,
	};
};
