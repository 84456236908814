import queryString from 'query-string';
import { forIn } from 'lodash';

import { saveJsonParse } from './saveJsonParse';

type Params = Record<string, unknown>;

export const urlParams = () => {
	return {
		objToQuery: (params: Params) => {
			const stringParams: Record<keyof Params, string> = {};

			forIn(
				params,
				(val, key) =>
					(stringParams[key] = ['string', 'number', 'boolean'].includes(typeof val) ? String(val) : JSON.stringify(val))
			);

			return queryString.stringify(stringParams, { arrayFormat: 'bracket' });
		},
		replace: (url: string, keys: (keyof Params)[]): string => {
			const restParams: Params = {};
			const { url: plainUrl, query } = queryString.parseUrl(url);

			forIn(query, (val, key) => {
				if (keys.includes(key)) return;
				restParams[key] = val;
			});

			return `${plainUrl}?${urlParams().objToQuery(restParams)}`;
		},
		pick: (url: string, key: keyof Params) => {
			const { query } = queryString.parseUrl(url);
			const value = query[key] || '';

			if (typeof value === 'string') return value;

			return JSON.parse(query[key] as string) as string | Record<string, string>;
		},
		parse: (url: string): Params => {
			const returnedParams: Params = {};
			const { query } = queryString.parseUrl(url);

			forIn(query, (val, key) => {
				returnedParams[key] = saveJsonParse(val as string);
			});

			return returnedParams;
		},
	};
};
