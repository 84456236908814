import React from 'react';
import cls from './AuthDocs.module.css';

import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { IconCheck } from '@new/old/Icons';
import { Flex } from '@new/components/ui';

interface IAuthDocsProps {
	className?: string;
}

export const AuthDocs = ({}: IAuthDocsProps) => {
	const { t } = useTranslation('ui');

	return (
		<div className={cls.docs}>
			<p>{t('authPages.login.docsBlock.desc')} </p>
			<Flex gap='6px' direction='column'>
				<Flex gap='10px' alignItems='center' className={cls.link}>
					<IconCheck />
					<Link href='/user-agreement'>{t('authPages.login.docsBlock.agreement')}</Link>
				</Flex>
				<Flex gap='10px' alignItems='center' className={cls.link}>
					<IconCheck />
					<Link href='/privacy-policy'>{t('authPages.login.docsBlock.confidentiality')}</Link>
				</Flex>
				<Flex gap='10px' alignItems='center' className={cls.link}>
					<IconCheck />
					<Link href='/data-use'>{t('authPages.login.docsBlock.personalData')}</Link>
				</Flex>
			</Flex>
		</div>
	);
};
