import { useState } from 'react';
import dayjs from 'dayjs';
import { RangeKeyDict } from 'react-date-range';
import useTranslation from 'next-translate/useTranslation';
import { useLocalStorage } from 'usehooks-ts';

import { IconCloseRed } from '@main/src/new/old/Icons';
import { Flex } from '@main/src/new/components/ui';
import { MobileModal } from '@main/src/new/components/ui/MobileModal';
import { ILocalStorageGuestsState, IRoom } from '@main/src/new/pages/BookingHotel/types';
import { LOCAL_STORAGE_BOOKING } from '@main/src/new/pages/BookingHotel/BookingHotel';

import { FilterModalDateEdit } from '../FilterModalDateEdit';
import { FilterModalGuestsEdit } from '../FilterModalGuestsEdit';
import { DEFAULT_CHILDREN_AGE } from '../../../Filters/Filters';

import cls from './FiltersModalEdit.module.css';

interface FiltersModalEditProps {
	isOpen: boolean;
	onClose: () => void;
	bookState: ILocalStorageGuestsState;
	setBookState: (val: ILocalStorageGuestsState) => void;
}

export const FiltersModalEdit = ({ isOpen, onClose, bookState, setBookState }: FiltersModalEditProps) => {
	const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
	// TODO: uncomment
	// const notifications = useNotifications();
	const { t } = useTranslation('api_hotel_misc');
	const [, setArrivalState] = useLocalStorage(`qvd/${LOCAL_STORAGE_BOOKING}/hotel/arrival`, {
		date: '',
		formatted: '',
	});

	const handlerDateRangeChange = ({ range1 }: RangeKeyDict) => {
		if (!range1) return;
		const from = dayjs(range1.startDate);
		const to = dayjs(range1.endDate);
		const resultDiff = to.diff(from, 'days');
		let rangeDates = { fromDate: from.format('YYYY-MM-DD'), toDate: to.format('YYYY-MM-DD') };
		if (resultDiff > 30) {
			rangeDates = { fromDate: from.format('YYYY-MM-DD'), toDate: from.add(30, 'days').format('YYYY-MM-DD') };
			// notifications.info({ content: t('Calendar.Grid.dateIsOutOfRange'), duration: 2000 });
		} else if (resultDiff === 0) {
			rangeDates = { fromDate: from.format('YYYY-MM-DD'), toDate: from.add(1, 'days').format('YYYY-MM-DD') };
		}

		const nextGuestState = {
			...bookState,
			...rangeDates,
		};
		setBookState(nextGuestState);
		setArrivalState({
			date: rangeDates.fromDate,
			formatted: '14:00',
		});
		if (resultDiff >= 1) {
			setIsDateRangeOpen(false);
		}
	};

	const handlerChangeAdult = (roomIndex: number) => (value: number) => {
		const cloneRooms = [...bookState.rooms];
		cloneRooms[roomIndex].adults = value;

		setBookState({
			...bookState,
			rooms: cloneRooms,
		});
	};

	const handlerChangeChild = (roomIndex: number) => (value: number) => {
		const cloneRooms = [...bookState.rooms];
		const cloneChildren = [...cloneRooms[roomIndex].children];

		if (cloneChildren.length >= value) {
			cloneRooms[roomIndex].children = cloneChildren.slice(0, value);
		} else if (value === cloneChildren.length + 1) {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			cloneRooms[roomIndex].children = [...cloneChildren, ...[DEFAULT_CHILDREN_AGE]];
		}

		setBookState({
			...bookState,
			rooms: cloneRooms,
		});
	};

	const handlerChangeChildAge = (roomIndex: number, childIndex: number) => (value: number) => {
		const cloneRooms = [...bookState.rooms];
		cloneRooms[roomIndex].children[childIndex] = value;

		setBookState({
			...bookState,
			rooms: cloneRooms,
		});
	};

	const handlerAddRoom = () => {
		setBookState({
			...bookState,
			rooms: [...bookState.rooms, ...[{ adults: 2, children: [] }]],
		});
	};

	const handlerRemoveRoom = (index: number) => () => {
		const nextRooms = bookState.rooms
			.map((i, indexR) => {
				if (index === indexR) {
					return null;
				}

				return i;
			})
			.filter(f => !!f) as IRoom[];
		setBookState({
			...bookState,
			rooms: nextRooms,
		});
	};

	return (
		<MobileModal isOpen={isOpen} close={onClose} top='15%' type='drawer' zIndex={999}>
			<IconCloseRed className={cls.close} onClick={onClose} />
			<Flex direction='column' gap='24px' className={cls.content}>
				<FilterModalDateEdit
					from={bookState.fromDate}
					to={bookState.toDate}
					onChangeDateRange={handlerDateRangeChange}
					onOpenDateRangeChange={v => () => setIsDateRangeOpen(v)}
					isOpenDateRange={isDateRangeOpen}
					onClick={() => setIsDateRangeOpen(true)}
				/>
				{!isDateRangeOpen && (
					<FilterModalGuestsEdit
						rooms={bookState.rooms}
						onChangeAdult={handlerChangeAdult}
						onChangeChild={handlerChangeChild}
						onChangeChildAge={handlerChangeChildAge}
						onAddRoom={handlerAddRoom}
						onRemoveRoom={handlerRemoveRoom}
					/>
				)}
			</Flex>
		</MobileModal>
	);
};
