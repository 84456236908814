import React, { FC, useMemo, useState } from 'react';
import { FilterInput } from '../FilterInput';
import { Button, Flex } from '@new/components/ui';
import cls from './FiltersInput.module.css';
import { IconFilter } from '@new/components/icon';
import useTranslation from 'next-translate/useTranslation';
import { BlockCheckouts } from './components/BlockCheckouts';
import { IconCloseRed } from '@new/old/Icons';
import { useMapContext } from '@components/QMap3/context';
import { getFilterConfig } from '@main/shared/filters';
import { convertTypeToGlobMapCardType } from '@app/api/services/shared/serp/mapper';
import { CAT_TAGS } from '@new/layouts/components/Header/components/Filters/FiltersInput/constants';

interface ISelectTypeProps {
	className?: string;
	type?: string | null;
}

export const FiltersPopup: FC<ISelectTypeProps> = props => {
	const uiTranslations = useTranslation('ui');

	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { result, selectType, params, changeParams } = useMapContext();
	const [localParams, changeLocalParams] = React.useState(params);

	const tagsTranslations = useTranslation('api_cat');

	const { className } = props;
	const [visible, setVisible] = useState(false);
	const handleChangeLocalParams = React.useCallback(
		(value: string) => () => {
			// console.log(value);
			let nextParams: string[] = [];
			if (localParams.includes(value)) {
				nextParams = localParams.filter(f => f !== value);
			} else {
				nextParams = [...localParams, value];
			}
			changeLocalParams(nextParams);
		},
		[localParams, changeLocalParams]
	);

	const handleOk = React.useCallback(() => {
		changeParams(localParams);
		setVisible(false);
	}, [localParams, changeParams, setVisible]);

	const filterBlocks = useMemo(() => {
		if (result) {
			const preparedType = convertTypeToGlobMapCardType(selectType as string);
			if (preparedType) {
				const filtersByType = getFilterConfig(preparedType, {});
				const catTagsSorted = CAT_TAGS[preparedType];

				if (result.tagGroups.length === 0) {
					return <p className={cls.empty}>{uiTranslations.t('_filter.filters.empty')}</p>;
				}

				return catTagsSorted.map((category, index) => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					const currentCategory = filtersByType.filters[category];
					// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-argument
					const currentFilters = Object.entries(currentCategory.values).map(([key, value]) => {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
						return {
							key,
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
							value: value.t,
							count: result.tags[key] || 0,
						};
					});

					if (currentCategory) {
						return (
							<BlockCheckouts
								key={`group.${index}`}
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
								title={currentCategory.title.t}
								// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
								filters={currentFilters}
								filterChange={handleChangeLocalParams}
								localParams={localParams}
								selectType={selectType}
							/>
						);
					}
				});
			}
		}

		return <p className={cls.empty}>{uiTranslations.t('_filter.filters.empty')}</p>;
	}, [handleChangeLocalParams, localParams, result, selectType, tagsTranslations]);

	const handleClear = React.useCallback(() => {
		changeLocalParams([]);
		changeParams([]);
		setVisible(false);
	}, [setVisible, changeParams]);
	const content = useMemo(() => {
		return (
			<>
				<div className={cls.header}>
					<p>{uiTranslations.t('_filter.filters.advancedFilter')}</p>
					<IconCloseRed onClick={() => setVisible(false)} />
				</div>
				<Flex className={cls.scroll} direction='column'>
					{filterBlocks}
				</Flex>
				<Flex className={cls.footer} alignItems='center' justifyContent='between'>
					<p role='button' onClick={handleClear}>
						{uiTranslations.t('_filter.filters.clear')}
					</p>
					<Button onClick={handleOk}>{uiTranslations.t('_filter.filters.apply')}</Button>
				</Flex>
			</>
		);
	}, [filterBlocks, handleOk, handleClear, setVisible]);

	React.useEffect(() => {
		changeLocalParams(params);
	}, [params]);

	return (
		<FilterInput
			visible={visible}
			content={content}
			changeVisible={setVisible}
			className={cls.FiltersInput}
			position='bottomRight'
			overlayClass='FiltersModal'
		>
			<IconFilter />
			<div>{uiTranslations.t('_filter.moreFiltersShort')}</div>
		</FilterInput>
	);
};
