import { useSelector } from 'react-redux';
import { DateRange, RangeKeyDict } from 'react-date-range';
import dayjs from 'dayjs';
import * as Locales from 'date-fns/locale';

import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import { ICommonData } from '@main/src/types/common';

import { HotelFilterDatePreview } from '../HotelFilterDatePreview';

import cls from './FilterModalDateEdit.module.css';

export const getDateFnsLocale = ({ locale, region }: { locale: string; region: null | string }): Locale => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/restrict-plus-operands,@typescript-eslint/no-unsafe-return
	return Locales[locale.substring(0, 2) + region] ?? Locales[locale.substring(0, 4)] ?? Locales.ru;
};

interface FilterModalDateEditProps {
	className?: string;
	from: string;
	to?: string;
	onChangeDateRange(e?: RangeKeyDict): void;
	onOpenDateRangeChange(e: boolean): () => void;
	isOpenDateRange: boolean;
	onClick?: () => void;
}

export const FilterModalDateEdit = ({
	from,
	to,
	isOpenDateRange,
	onChangeDateRange,
	onClick,
}: FilterModalDateEditProps) => {
	const { locale } = useSelector((state: ICommonData) => state);
	const rangePickerLocale = locale?.code === 'en' ? 'enUS' : 'ru';

	return (
		<>
			<HotelFilterDatePreview from={from} to={to} showNightsCount onClick={onClick} invert />
			{isOpenDateRange && (
				<DateRange
					editableDateInputs={false}
					onChange={onChangeDateRange}
					moveRangeOnFirstSelection={false}
					ranges={[{ startDate: dayjs(from).toDate(), endDate: to ? dayjs(to).toDate() : undefined }]}
					showDateDisplay={false}
					showMonthAndYearPickers={false}
					locale={getDateFnsLocale({ locale: rangePickerLocale, region: null })}
					className={cls.DatePicker}
					minDate={new Date()}
				/>
			)}
		</>
	);
};
