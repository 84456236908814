import { Link } from '@new/components/ui';
import classNames from 'classnames';
import React, { memo, useCallback } from 'react';
import cls from './OfferForProvider.module.css';
import useTranslation from 'next-translate/useTranslation';

interface IOfferForProviderProps {
	className?: string;
}

export const OfferForProvider = memo((props: IOfferForProviderProps) => {
	const { className } = props;
	const { t } = useTranslation('ui');

	return (
		<div className={classNames(className, cls.OfferForProvider)}>
			<p className={cls.title} dangerouslySetInnerHTML={{ __html: t('_joinus.provider.title') }} />
			<p className={cls.desc} dangerouslySetInnerHTML={{ __html: t('_joinus.provider.desc') }} />

			<Link href='https://my.qvedo.com' target='_blank' className={cls.lk}>
				{t('_joinus.provider.btn')}
			</Link>

			{/*<Link href=''>*/}
			{/*	<a>Помощь в регистрации</a>*/}
			{/*</Link>*/}
		</div>
	);
});
