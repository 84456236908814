import { COOKIE_PERMISSION } from '@app/constants/cookie';
import { EAnswerModal, ICommonData } from '@main/src/types/common';
import { setCookies } from 'cookies-next';
import useTranslation from 'next-translate/useTranslation';
import React, { useCallback, useState, FC } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import cls from './ModalCookie.module.css';
import { Button } from '@main/src/new/components/ui';
import Link from 'next/link';
export const ModalCookie: FC = () => {
	const { useCookie } = useSelector((state: ICommonData) => state);
	const { t } = useTranslation('ui');
	const [hide, setHide] = useState(false);

	const handlerCookie = useCallback(
		(type: EAnswerModal) => (e: React.MouseEvent<HTMLElement>) => {
			e.preventDefault();
			setCookies(COOKIE_PERMISSION, type, {
				expires: new Date(Date.now() + 86400e6),
			});
			setHide(true);
		},
		[]
	);

	if (useCookie) {
		return null;
	}
	return (
		<div className={classNames(cls.container, hide && cls.hide)}>
			<p>
				{t('_modalCookie.text')}
				<Link href='/cookie-policy'>{t('_modalCookie.police')}</Link>
			</p>
			<div>
				<Button onClick={handlerCookie(EAnswerModal.REJECTED)} outline>
					{t('_modalCookie.cancel')}
				</Button>
				<Button onClick={handlerCookie(EAnswerModal.ACCEPTED)}>{t('_modalCookie.agree')}</Button>
			</div>
		</div>
	);
};
