import { IResponseResult } from './types';

export const fallbackStartHomeData: IResponseResult = {
	error: true,
	data: {
		point: [55.75222, 37.615559],
		city: {
			display_name: 'Москва, Центральный федеральный округ, Россия',
			country_code: 'ru',
			id: '0225586eced7f183',
		},
		ids: [
			'6a4717c0-44ca-11ed-9230-6ac337dc5499',
			'5a64a5f0-a60b-11ed-96f5-d170d7c0b07a',
			'0f895ee0-454e-11ed-9695-b62bf6f035f0',
			'a02abff0-6080-11ed-9b24-330ba8dd5460',
			'3712de90-607f-11ed-a46c-5eddd661e3b7',
			'979f0130-6025-11ed-8a1a-1a8a040679bb',
			'c711cab0-c1a5-11ed-9aff-1cae06cfc99a',
			'a92ab130-bffa-11ed-af1e-07f7bedd22a6',
			'6e4f9300-be60-11ed-9cbd-a0bc9feadb63',
		],
		top: {
			restaurant: [
				{
					id: '6a4717c0-44ca-11ed-9230-6ac337dc5499',
					m: -1,
					point: [55.73771750145315, 37.820504493455054],
				},
				{
					id: '5a64a5f0-a60b-11ed-96f5-d170d7c0b07a',
					m: -1,
					point: [60.477882180222544, 30.28176068263778],
				},
				{
					id: '0f895ee0-454e-11ed-9695-b62bf6f035f0',
					m: -1,
					point: [44.03565721823117, 43.064909706608475],
				},
			],
			attraction: [
				{
					id: 'a02abff0-6080-11ed-9b24-330ba8dd5460',
					m: -1,
					point: [56.16679840961422, 47.314723490497265],
				},
				{
					id: '3712de90-607f-11ed-a46c-5eddd661e3b7',
					m: -1,
					point: [44.040297860576864, 43.08680861487787],
				},
				{
					id: '979f0130-6025-11ed-8a1a-1a8a040679bb',
					m: -1,
					point: [43.99885622978155, 40.13672835670955],
				},
			],
			activity: [
				{
					id: 'c711cab0-c1a5-11ed-9aff-1cae06cfc99a',
					m: 167.91145112985512,
					point: [61.70155759488139, 30.688301318488303],
				},
				{
					id: 'a92ab130-bffa-11ed-af1e-07f7bedd22a6',
					m: -1,
					point: [55.75510245531203, 37.612533141424805],
				},
				{
					id: '6e4f9300-be60-11ed-9cbd-a0bc9feadb63',
					m: -1,
					point: [53.29314147557964, 158.28782679305297],
				},
			],
		},
	},
};
