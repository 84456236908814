import { useState } from 'react';

import { Flex } from '@main/src/new/components/ui';
import { useHotelGuestState } from '@common/hooks';

import { FiltersModalEdit } from '../FiltersModalEdit';
import { HotelFilterDatePreview } from '../HotelFilterDatePreview';
import { HotelFilterGuestsPreview } from '../HotelFilterGuestsPreview';

import cls from './FiltersModalPreview.module.css';

export const FiltersModalPreview = () => {
	const [isOpen, setIsOpen] = useState(false);

	const { bookGuestsState, setGuestsState } = useHotelGuestState();

	return (
		<>
			<Flex direction='column' className={cls.FiltersModalPreview} gap='10px' onClick={() => setIsOpen(true)}>
				<HotelFilterDatePreview from={bookGuestsState.fromDate} to={bookGuestsState.toDate} showNightsCount={false} />
				<HotelFilterGuestsPreview rooms={bookGuestsState.rooms} />
			</Flex>
			<FiltersModalEdit
				isOpen={isOpen}
				onClose={() => setIsOpen(false)}
				bookState={bookGuestsState}
				setBookState={setGuestsState}
			/>
		</>
	);
};
