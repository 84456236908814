import { createModel } from '@rematch/core';
import { IStatistics } from 'src/types/entities/statistics';

export const initialState: IStatistics = {
	topVideoUrl: '/video.mp4',
	locations: 465738,
	payed: 219345,
	tours: 352786,
	users: 583162,
};

export const statistics = createModel()({
	state: initialState,
});
