import { b2b } from '@app/stores/models';
import { combineCommonModels } from '@app/stores/helpers';
import { init, RematchStore } from '@rematch/core';
import { ICommonData } from 'src/types/common';

import { RootModel } from './types';

export const models = combineCommonModels<RootModel>({ b2b });

export const makeStore = (initState: ICommonData): RematchStore<RootModel, RootModel> => {
	const state = init<RootModel>({ models }).getState();

	return init<RootModel>({
		models,
		redux: { initialState: { ...state, ...initState } },
	});
};
