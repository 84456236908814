import { v4 as uuidv4 } from 'uuid';
import { ProviderBtn } from '../../index';
import { ProviderButtonProps } from '../../../../useLogin';
import { useRouter } from 'next/router';
import { openAuthUrl } from '@new/pages/BookingHotel/components/BookingAuthBlock/helpers';

export const FACEBOOK_STATE_KEY = 'f-a-state';
export const FACEBOOK_LAST_URL = 'f-a-last-url';

export const Facebook = (props: ProviderButtonProps): JSX.Element => {
	const router = useRouter();
	const scope = 'email,public_profile';
	const responseType = 'token';
	const onClick = () => {
		const state = uuidv4();
		sessionStorage.setItem(FACEBOOK_STATE_KEY, state);
		sessionStorage.setItem(FACEBOOK_LAST_URL, router.asPath);
		const href = `https://www.facebook.com/v18.0/dialog/oauth?scope=${scope}&state=${state}&response_type=${responseType}&client_id=${props.clientId}&redirect_uri=${props.redirectUri}`;
		if (router.asPath.startsWith('/login')) {
			location.href = href;
		} else {
			openAuthUrl(href);
		}
	};

	return <ProviderBtn {...{ ...props, onClick }} />;
};
