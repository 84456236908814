import React, { FC, memo } from 'react';
import { WhiteContainer } from '@new/components/WhiteContainer';
import cls from './DownloadApps.module.css';
import classNames from 'classnames';
import { Flex } from '@new/components/ui';
import Image from 'next/image';
import { apps } from '@new/app/constants';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { DEFAULT_LOCALE } from '@app/constants/defaults';

interface IDownloadAppsProps {
	className?: string;
}

export const DownloadApps = memo((props: IDownloadAppsProps) => {
	const { className } = props;
	const { t, lang } = useTranslation('ui');
	const locale = DEFAULT_LOCALE === lang ? 'ru' : 'en';

	return (
		<div>
			<WhiteContainer className={classNames(className, cls.DownloadApps)}>
				<Flex className={cls.qrBlock} alignItems='center'>
					<div>
						<p className={cls.title} dangerouslySetInnerHTML={{ __html: t('_mobile.title') }} />
						<p className={cls.desc}>
							{t('_mobile.desc2')}
							<br />
							<br />
							<b> {t('_mobile.tagline')}</b>
						</p>
					</div>
					<Image src='/statics/new/qrApps.png' alt='qr code donwnload apps' height={134} width={134} />
				</Flex>
				<Flex className={cls.links} alignItems='center'>
					{apps.map(({ href, src }) => (
						<Link href={href} key={href} target='_blank'>
							<Image src={src} width={134} height={38} alt={'app'} />
						</Link>
					))}
				</Flex>
				<div className={cls.clouds}>
					<Image src={'/statics/new/clouds.png'} alt='' fill />
				</div>
				<div className={cls.phones}>
					<Image src={`/statics/new/apps-${locale}.png`} alt='' fill />
				</div>
			</WhiteContainer>
		</div>
	);
});
