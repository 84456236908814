import React, { FC, ReactNode } from 'react';

import { AllRights } from '@new/components/AllRights';
import { Flex } from '@new/components/ui';
import { OfferForProvider } from '@new/widgets/OfferForProvider';
import { DownloadApps } from '@new/widgets/DownloadApps';
import { Metrics } from '@new/components/Metrics';

import { Header } from './../components/Header';
import { Footer } from './../components/Footer';
import { ModalCookie } from '../../old/Modal';

import cls from './MainLayout.module.css';
import classNames from 'classnames';
import { useAuthContext } from '@new/auth';
import { GoToMobileSmall } from '@new/widgets/GoToMobileSmall';

export enum SectionsMain {
	'offerProvider',
	'downloadApps',
}

export const SectionsBlocks = {
	[SectionsMain.downloadApps]: (key: string) => <DownloadApps key={key} />,
	[SectionsMain.offerProvider]: (key: string) => <OfferForProvider key={key} />,
};

interface IMainLayoutProps {
	children: ReactNode;
	elements?: SectionsMain[];
	header?: boolean;
	footer?: boolean;
	mobileStoreUrl?: string | null;
	mobileSiteUrl?: string;
	locationName?: string;
	filters?: React.ReactElement;
	withTitle?: boolean;
	className?: string;
}

export const MainLayout: FC<IMainLayoutProps> = ({
	children,
	header = true,
	footer = true,
	elements = [SectionsMain.downloadApps, SectionsMain.offerProvider],
	mobileStoreUrl,
	mobileSiteUrl,
	locationName,
	filters,
	withTitle = true,
	className,
}) => {
	return (
		<>
			<Metrics />
			<Flex className={classNames(cls.container, className)} direction='column' justifyContent='between'>
				{mobileStoreUrl && <GoToMobileSmall url={mobileStoreUrl} />}
				{header && <Header locationName={locationName} filters={filters} withTitle={withTitle} />}
				<div className={cls.content} id='content'>
					<section>
						<Flex gap='40px' direction='column'>
							{children}
							{elements.map((i, index) => SectionsBlocks[i](`section-${index}`))}
						</Flex>
					</section>
					{footer && <Footer />}
				</div>
				{footer && <AllRights mobileUrl={mobileSiteUrl} />}
				<ModalCookie />
			</Flex>
		</>
	);
};
