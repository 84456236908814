import React, { FC, useCallback, useMemo, useState } from 'react';
import { FilterInput } from './FilterInput';
import { Flex } from '@main/src/new/components/ui';
import cls from './ActivityInput.module.css';
import Image from 'next/image';
import { IconCategory } from '@new/components/icon';
import useTranslation from 'next-translate/useTranslation';
import { LIST_TYPES } from './constants';
import classNames from 'classnames';
import selectTypeCls from './SelectType.module.css';

interface ISelectTypeProps {
	className?: string;
	onChange(value: string): void;
	value: string | null;
}

export const SelectType: FC<ISelectTypeProps> = ({ value, onChange }) => {
	const { t, lang } = useTranslation('ui');
	const [visible, setVisible] = useState(false);
	const currentType = LIST_TYPES.find(f => f.type === value);

	const handleClickType = useCallback(
		(nextValue: string) => () => {
			onChange(!nextValue || currentType?.type === nextValue ? 'any' : nextValue);
			setVisible(false);
		},
		[setVisible, onChange, currentType?.type]
	);

	const content = useMemo(() => {
		return (
			<Flex gap='20px'>
				{LIST_TYPES.map(({ image, icon, type, key }, index: number) => (
					<Flex
						key={index}
						gap='6px'
						className={classNames(cls.item, type === currentType?.type ? cls.active : '')}
						direction='column'
						onClick={handleClickType(type)}
					>
						<div>
							<Image src={image} alt='' layout='fill' />
							<div className={classNames(cls.svg, cls[type])}>{icon}</div>
						</div>
						<p dangerouslySetInnerHTML={{ __html: t(`${key}.title`) }} className={selectTypeCls.title}></p>
						<span>{t(`${key}.desc`)}</span>
					</Flex>
				))}
			</Flex>
		);
	}, [handleClickType, lang, currentType]);

	// TODO need icons
	let icon = <IconCategory />;
	let titleText = t('_filter.watch');
	if (currentType) {
		titleText = t(`${currentType.key}.titleShort`);
		icon = currentType.icon;
	}

	const handlerClick = useCallback(() => {
		setVisible(true);
	}, [visible]);

	return (
		<FilterInput
			visible={visible}
			content={content}
			changeVisible={setVisible}
			className={cls.TypeInput}
			trigger='hover'
		>
			{icon}
			<div onClick={handlerClick}>{titleText}</div>
		</FilterInput>
	);
};
