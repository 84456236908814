import React, { memo, useState } from 'react';
import classNames from 'classnames';
import { setCookies } from 'cookies-next';
import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';

import { storeUrls } from '@app/constants/apps';
import { COOKIE_MOBILE_ONBOARD } from '@app/constants/cookie';
import { IconCloseRed } from '../../old/Icons';

import cls from './GoToMobileSmall.module.css';

interface IGoToMobileProps {
	url: string;
}

export const GoToMobileSmall = memo(({ url }: IGoToMobileProps) => {
	const { t } = useTranslation('ui');
	const [hasOpen, setHasOpen] = useState<boolean>(true);
	const isIos = url === storeUrls.apple;

	const handleClick = () => {
		setCookies(COOKIE_MOBILE_ONBOARD, true, {
			maxAge: 60 * 60 * 24 * 365,
		});
		setHasOpen(false);
	};

	if (hasOpen) {
		return (
			<>
				<div className={classNames(cls.GoToMobile, isIos && cls.ios)}>
					<div className={cls.left}>
						<IconCloseRed onClick={handleClick} />
						<Image src='/statics/new/ICO.png' alt='icon' width={40} height={40} />
						<p>{t('_modalBlur.title')}</p>
					</div>
					<div className={cls.btnWrapper} onClick={handleClick}>
						<Link href={url} className='ant-btn ant-btn-primary btn--big' target='_blank'>
							{t('_modalBlur.btn')}
						</Link>
					</div>
				</div>
			</>
		);
	}

	return null;
});
