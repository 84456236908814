import { combineCommonModels } from '@app/stores/helpers';
import { init, RematchStore } from '@rematch/core';
import { supplier, nearby } from '@app/stores/models';
import { RootModel, IMakeStoreParams } from './types';

export const models = combineCommonModels<RootModel>({ supplier });
export const makeStore = ({ common, supplier }: IMakeStoreParams): RematchStore<RootModel, RootModel> => {
	const state = init<RootModel>({ models }).getState();

	return init<RootModel>({
		models,
		redux: { initialState: { ...state, ...common, supplier } },
	});
};
