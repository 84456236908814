import React, { FC, memo } from 'react';
import cls from './BookingSelectTypePayment.module.css';
import classNames from 'classnames';
// import { IconMaster, IconMir, IconShield, IconVisa, IconYandexPay } from '../icon';

import { Flex } from '@main/src/new/components/ui';
import { IconCheck } from '@main/src/new/old/Icons';
import { IconMaster, IconMir, IconShield, IconVisa, IconYandexPay } from '@main/src/new/components/icon';
import { SwitcherBlock } from '../SwitcherBlock';
import { FormProvider, useForm } from 'react-hook-form';
import { IFormPayInput } from '../../types';
import { CardData } from './components/CardData';
import useTranslation from 'next-translate/useTranslation';

interface IBookingSelectTypePaymentProps {
	className?: string;
}

export const BookingSelectTypePayment = memo((props: IBookingSelectTypePaymentProps) => {
	const { className } = props;
	const { t } = useTranslation('api_hotel');

	return (
		<div className={classNames(className, cls.BookingSelectTypePayment)}>
			{/*<div className={cls.title}>Выберете способ оплаты</div>*/}
			{/*<div className={cls.items}>*/}
			{/*	<div className={classNames(cls.selectItem, cls.activity)}>*/}
			{/*		<div className={cls.checkBox}>*/}
			{/*			<IconCheck />*/}
			{/*		</div>*/}
			{/*		<p>Банковская карта</p>*/}
			{/*		<div className={cls.imgs}>*/}
			{/*			<IconVisa />*/}
			{/*			<IconMaster />*/}
			{/*			<IconMir />*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*	<div className={cls.selectItem}>*/}
			{/*		<div className={cls.checkBox}>*/}
			{/*			<IconCheck />*/}
			{/*		</div>*/}
			{/*		<p>Yandex Pay</p>*/}
			{/*		<div className={cls.imgs}>*/}
			{/*			<IconYandexPay />*/}
			{/*		</div>*/}
			{/*	</div>*/}
			{/*</div>*/}
			<Flex className={cls.info} gap='12px' alignItems='center'>
				<IconShield />
				<div>
					<p>{t('booking.reservation_guarantee')}</p>
					<span>{t('booking.reservation_guarantee_help')}</span>
				</div>
			</Flex>
			<div className={cls.cardBlock}>
				<CardData />
			</div>
		</div>
	);
});
