import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import { Flex, Popover as PopoverComponent, Portal } from '@main/src/new/components/ui';

import cls from './FilterInput.module.css';

interface IFilterInputProps {
	className?: string;
	overlayClass?: string;
	children: ReactNode;
	content?: ReactNode;
	visible?: boolean;
	changeVisible: (i: boolean) => void;
	trigger?: 'hover' | 'focus' | 'click';
	position?: 'bottomLeft' | 'bottomRight';
	withOverlay?: boolean;
}

export const FilterInput: FC<IFilterInputProps> = props => {
	const {
		className,
		children,
		content,
		visible = false,
		changeVisible,
		trigger = 'click',
		position = 'bottomLeft',
		overlayClass = 'FilterInput',
		withOverlay,
	} = props;

	if (typeof window === 'undefined') {
		return (
			<Flex className={classNames(cls.inputBlock, visible && cls.isOpen, className)} alignItems='center'>
				{children}
			</Flex>
		);
	}
	return (
		<>
			<PopoverComponent
				placement={position}
				content={content}
				visible={visible}
				overlayClassName={classNames(cls.popover, overlayClass)}
				overlayInnerStyle={{
					border: '1px solid #FFFFFF',
					boxShadow: '0px 10px 20px rgba(17, 111, 164, 0.4)',
					borderRadius: '24px',
				}}
				onVisibleChange={changeVisible}
				trigger={trigger}
			>
				<Flex className={classNames(cls.inputBlock, visible && cls.isOpen, className)} alignItems='center'>
					{children}
				</Flex>
			</PopoverComponent>
			<Portal elementId={'content'}>
				<div className={classNames(withOverlay && cls.overlay, visible && cls.isOpen)} />
			</Portal>
		</>
	);
};
