import React, { ReactNode, memo } from 'react';
import cls from './Tooltip.module.css';
import classNames from 'classnames';

interface ITooltipProps {
	className?: string;
	children: ReactNode;
	content: ReactNode;
	classNamePopup?: string;
	position?: 'top' | 'topCenter';
}

export const Tooltip = memo((props: ITooltipProps) => {
	const { className, children, classNamePopup, content, position = 'top' } = props;

	return (
		<div className={classNames(cls.Tooltip, cls[position], className)}>
			<span className={classNames(cls.popup, classNamePopup)}>{content}</span>
			{children}
		</div>
	);
});
