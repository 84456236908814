import { IconTg, IconVk, IconYouTube } from '@new/components/icon';

export const SOCIALS = [
	{
		href: 'https://www.youtube.com/@QVEDO',
		text: 'YouTube',
		icon: <IconYouTube />,
	},
	{
		href: 'https://vk.com/qvedo',
		text: 'ВКонтакте',
		icon: <IconVk />,
	},
	{
		href: 'https://t.me/qvedo',
		text: 'Telegram',
		icon: <IconTg />,
	},
];
