import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import { Flex } from '../ui';
import cls from './AllRights.module.css';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { setCookies } from 'cookies-next';
import { COOKIE_CLIENT_VERSION, MOBILE_COOKIE_CLIENT_VERSION_VALUE } from '@app/constants/version';
import { NEXT_PUBLIC_QVEDO_MOBILE_SITE_HOST, NEXT_PUBLIC_QVEDO_ROOT_DOMAIN } from '@app/constants';

export interface IAllRightsProps {
	mobileUrl?: string;
}
export const AllRights = memo(({ mobileUrl }: IAllRightsProps) => {
	const { t, lang } = useTranslation('ui');
	// const router = useRouter();
	// const preparedLocale = router.locale && router.locale !== router.defaultLocale ? `/${router.locale}` : '';
	// const nextMobileUrl = useMemo(() => {
	// 	if (mobileUrl) {
	// 		return mobileUrl;
	// 	}
	// 	return `${NEXT_PUBLIC_QVEDO_MOBILE_SITE_HOST}${preparedLocale}${router.asPath}`;
	// }, [mobileUrl]);
	//
	// const handlerLinkVersionClick = useCallback(() => {
	// 	setTimeout(() => {
	// 		setCookies(COOKIE_CLIENT_VERSION, MOBILE_COOKIE_CLIENT_VERSION_VALUE, {
	// 			domain: NEXT_PUBLIC_QVEDO_ROOT_DOMAIN,
	// 			expires: new Date('01.01.2099'),
	// 		});
	// 	}, 0);
	// }, []);

	return (
		<section className={cls.AllRights}>
			<Flex alignItems='center' justifyContent='flex-center' direction='column'>
				<p>{t('footer.allRights')}</p>
				{/*<div className={cls.version}>*/}
				{/*	<Link href={nextMobileUrl} onClick={handlerLinkVersionClick}>*/}
				{/*		{t('footer.mobileVersion')}*/}
				{/*	</Link>*/}
				{/*</div>*/}
			</Flex>
		</section>
	);
});
