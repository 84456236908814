import React, { memo } from 'react';
import classNames from 'classnames';
import { IFormInfoInput, ILocalStorageGuestsState, ILocalStorageState } from '../../types';
import { GuestData } from './components/GuestData';
import useTranslation from 'next-translate/useTranslation';
import { ClientData } from './components/ClientData';
import { Controller, useFieldArray } from 'react-hook-form';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { useAuthContext } from '@main/src/new/auth';

import cls from './BookingFormGuests.module.css';

interface IBookingFormGuestsProps {
	className?: string;
	bookState: ILocalStorageState;
	bookGuestsState: ILocalStorageGuestsState;
	formInfoMethods: UseFormReturn<IFormInfoInput, any, undefined>;
}

// export const convertCtxGuestsToRooms = (ctxGuests: RoomsGuestInfo[]): EtgOrderBookingFinishRoom[] => {
// 	return ctxGuests.map(r => {
// 		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
// 		const adults = [...Array(r.adults)].map(() => ({
// 			first_name: '',
// 			last_name: '',
// 		}));
//
// 		const children = r.children
// 			? r.children.map(age => ({
// 					first_name: '',
// 					last_name: '',
// 					is_child: true,
// 					age,
// 			  }))
// 			: [];
//
// 		return {
// 			guests: [...adults, ...children],
// 		};
// 	});
// };

export const BookingFormGuests = memo((props: IBookingFormGuestsProps) => {
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { className, bookState, bookGuestsState, formInfoMethods } = props;
	const hotelTranslation = useTranslation('api_hotel');
	const bookingTranslation = useTranslation('ui');
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { getHasAnonymous } = useAuthContext();
	const isDisable = getHasAnonymous();

	// const [isRunRoomsF, setIsRunRoomsF] = useState<boolean>(false);
	const roomsF = useFieldArray({
		control: formInfoMethods.control,
		name: 'rooms',
	});

	// useEffect(() => {
	// 	if (!isRunRoomsF) {
	// 		const rooms = convertCtxGuestsToRooms(bookGuestsState.rooms);
	// 		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// 		// @ts-ignore
	// 		roomsF.replace(rooms);
	// 		setIsRunRoomsF(true);
	// 	}
	// }, [bookGuestsState, isRunRoomsF]);

	return (
		<div className={classNames(className, cls.className, isDisable && cls.disabled)}>
			<div className={cls.header}>
				<p className={cls.title}>{bookingTranslation.t('booking.hotel.guestTitle')}</p>
				{/*<p className={cls.desc}>{hotelTranslation.t('booking.name_help')}</p>*/}
			</div>
			<div className={cls.form}>
				<ClientData bookState={bookState} bookGuestsState={bookGuestsState} />
				{/*<SelectBooking label='Электронная почта' required placeholder='example@mail.com' />*/}
				{/*<SelectBooking label='Телефон для связи' required placeholder='+71234567890' />*/}
				{/*<SelectBooking label='Имя' required placeholder='IVAN' />*/}
				{/*<SelectBooking label='Фамилия' required placeholder='IVANOV' />*/}
				{/*<SelectBooking label='Гражданство' required placeholder='Российская Федерация' />*/}
				{/*<div className={cls.btn}>*/}
				{/*	<div className={cls.svg}>*/}
				{/*		{' '}*/}
				{/*		<IconPlus />{' '}*/}
				{/*	</div>*/}
				{/*	<p>Добавить гостя</p>*/}
				{/*</div>*/}
			</div>
			<div className={cls.formGuest}>
				{roomsF.fields.map((roomField, index) => {
					const title =
						(roomsF.fields.length > 1 &&
							hotelTranslation.t('booking.room_out_of', {
								number: index + 1,
								count: roomsF.fields.length,
							})) ||
						undefined;
					return <GuestData key={index} index={index} title={title} />;
				})}
			</div>
			<div className={cls.dop}>
				<p className={cls.title}>{hotelTranslation.t('booking.your_requests')}</p>
				<p className={cls.desc}>{hotelTranslation.t('booking.your_requests_help')}</p>
				<Controller
					name='comment'
					control={formInfoMethods.control}
					render={({ field, fieldState }) => {
						//eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						const value = field.value;

						return (
							<textarea
								placeholder={hotelTranslation.t('booking.special_request_placeholder')}
								className={cls.textarea}
								required={false}
								value={value}
								onChange={field.onChange}
								disabled={isDisable}
							/>
						);
					}}
					rules={{
						required: false,
					}}
				/>
			</div>
		</div>
	);
});
