import { v4 as uuidv4 } from 'uuid';
import { ProviderBtn } from '@new/pages/BookingHotel/components/BookingAuthBlock/components';
import { ProviderButtonProps } from '@new/pages/BookingHotel/components/BookingAuthBlock/useLogin';
import { useRouter } from 'next/router';
import { openAuthUrl } from '@new/pages/BookingHotel/components/BookingAuthBlock/helpers';

export const YANDEX_STATE_KEY = 'y-a-state';
export const YANDEX_LAST_URL = 'y-a-last-url';
export const Yandex = (props: ProviderButtonProps): JSX.Element => {
	const router = useRouter();
	const scope = 'login%253Aemail%2Blogin%253Ainfo';
	const onClick = () => {
		const state = uuidv4();
		sessionStorage.setItem(YANDEX_STATE_KEY, state);
		sessionStorage.setItem(YANDEX_LAST_URL, router.asPath);
		const retpath = `https%3A%2F%2Foauth.yandex.ru%2Fauthorize%3Fclient_id%3D${props.clientId}%26response_type%3Dtoken%26scope%3D${scope}%26state%3D${state}%26redirect_uri%3D${props.redirectUri}`;
		const href = `https://passport.yandex.ru/auth/welcome?retpath=${retpath}&noreturn=1&origin=oauth`;
		if (router.asPath.startsWith('/login')) {
			location.href = href;
		} else {
			openAuthUrl(href);
		}
	};

	return <ProviderBtn {...{ ...props, onClick }} />;
};
