import classNames from 'classnames';
import React, { useState, memo, useMemo, useCallback } from 'react';
import { IconLangWorld } from '../icon';
import { Flex, Popover } from '../ui';
import cls from './LangCurrencyButton.module.css';
import { locales } from '@app/constants/locales';
import { currencies } from '@app/constants/currencies';
import { ICommonData, ICurrency, ILocale } from 'src/types/common';
import { COOKIE_CURRENCY, COOKIE_LOCALE, COOKIE_NEXT_LOCALE } from '@app/constants/cookie';
import { setCookies } from 'cookies-next';
import { useSelector } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import { DEFAULT_CURRENCY } from '@app/constants/defaults';
import { NEXT_PUBLIC_QVEDO_ROOT_DOMAIN } from '@app/constants';

interface ILangCurrencyButtonProps {
	className?: string;
}

export const LangCurrencyButton = memo((props: ILangCurrencyButtonProps) => {
	const { locale, currency } = useSelector((state: ICommonData) => state);
	const [currencyInfo, setCurrency] = useState<ICurrency | null | undefined>(currency);
	const { lang } = useTranslation();

	const { className } = props;
	const [visible, setVisible] = useState({ currency: false, local: false });

	const LanguageHandler = useCallback(
		(item: ILocale) => () => {
			setCookies(COOKIE_LOCALE, JSON.stringify(item), {
				domain: NEXT_PUBLIC_QVEDO_ROOT_DOMAIN,
				expires: new Date('01.01.2099'),
			});
			setCookies(COOKIE_NEXT_LOCALE, item.code, {
				domain: NEXT_PUBLIC_QVEDO_ROOT_DOMAIN,
				expires: new Date('01.01.2099'),
			});
			// await setLanguage(item.code);

			global.location.reload();

			setVisible({ ...visible, local: false });
		},
		[visible]
	);

	const CurrencyHandler = useCallback(
		(item: ICurrency) => () => {
			setCookies(COOKIE_CURRENCY, JSON.stringify(item), {
				domain: NEXT_PUBLIC_QVEDO_ROOT_DOMAIN,
				expires: new Date('01.01.2099'),
			});
			setCurrency(item);

			global.location.reload();

			setVisible({ ...visible, currency: false });
		},
		[visible, global.location]
	);

	const handleVisibleChange = (newVisible: boolean) => {
		setVisible({ ...visible, local: newVisible });
	};

	const handleVisibleChangeCurr = (newVisible: boolean) => {
		setVisible({ ...visible, currency: newVisible });
	};
	const contentLocales = useMemo(
		() => (
			<div className={cls.containerModal}>
				{locales.map((i, index) => (
					<p key={index} onClick={LanguageHandler(i)}>
						{i.originalName}
					</p>
				))}
			</div>
		),
		[locales]
	);

	const contentCurrencies = useMemo(
		() => (
			<div className={cls.containerModal}>
				{currencies.map((i, index) => (
					<p key={index} onClick={CurrencyHandler(i)}>
						{i.code}
					</p>
				))}
			</div>
		),
		[currencies]
	);
	return (
		<Flex className={classNames(className, cls.LangCurrencyButton)} alignItems='center' gap='10px'>
			<Popover
				className={cls.lang}
				trigger='click'
				content={contentLocales}
				visible={visible.local}
				onVisibleChange={handleVisibleChange}
			>
				<IconLangWorld />
				<p>{locale?.originalName || lang}</p>
			</Popover>
			<Popover
				className={cls.currency}
				trigger='click'
				content={contentCurrencies}
				visible={visible.currency}
				onVisibleChange={handleVisibleChangeCurr}
			>
				{currencyInfo?.code || DEFAULT_CURRENCY}
			</Popover>
		</Flex>
	);
});
