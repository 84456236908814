import { combineCommonModels } from '@app/stores/helpers';
import { init } from '@rematch/core';
import { RematchStore } from '@rematch/core/dist/types';
import { cardDetail, nearby, statistics, tickets, session, requestId, isNearByLoading } from '../../models';
import { IMakeStoreParams, RootModel } from './types';

export const models = combineCommonModels<RootModel>({
	cardDetail,
	statistics,
	tickets,
	nearby,
	session,
	requestId,
	isNearByLoading,
});

export const makeStore = ({
	common,
	tickets,
	cardDetail,
	nearby,
	requestId,
	session,
	isNearByLoading,
}: IMakeStoreParams): RematchStore<RootModel, RootModel> => {
	const state = init<RootModel>({ models }).getState();

	return init<RootModel>({
		models,
		redux: { initialState: { ...state, ...common, cardDetail, tickets, nearby, requestId, session, isNearByLoading } },
	});
};
