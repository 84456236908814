import React, { memo, useCallback } from 'react';
import cls from '../HotelFilterGuests.module.css';
import useTranslation from 'next-translate/useTranslation';
import { Flex } from '@main/src/new/components/ui';
import { Counter } from '../../Counter';

export interface IRoom {
	adults: number;
	children: number[];
}

export interface IRoomProps {
	className?: string;
	index: number;
	room: IRoom;
	onChangeAdult(roomIndex: number): (v: number) => void;
	onChangeChild(roomIndex: number): (v: number) => void;
	onChangeChildAge(roomIndex: number, childIndex: number): (v: number) => void;
	onRemoveRoom(index: number): () => void;
}

export const Room = memo((props: IRoomProps) => {
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { className, index, room, onChangeChildAge, onChangeChild, onChangeAdult, onRemoveRoom } = props;
	const uiTranslations = useTranslation('ui');
	const isShowChildrenFields = room.children.length > 0;
	const handlerCustomViewValueFn = useCallback((value: number) => {
		return uiTranslations.t('_filter.filters.age', { count: value });
	}, []);

	return (
		<Flex className={cls.room} maxWidth direction='column' gap='20px'>
			<Flex direction='column' gap='10px' maxWidth key={index}>
				<Flex maxWidth direction='row' justifyContent='between' alignItems='end'>
					<div className={cls.title}>
						{index + 1} {uiTranslations.t('_filter.booking.room')}
					</div>
					<div className={cls.btnDelete}>
						{index > 0 && <div onClick={onRemoveRoom(index)}>{uiTranslations.t('_filter.filters.delete')}</div>}
					</div>
				</Flex>
				<Flex gap='10px' justifyContent='between' className={cls.item} alignItems='center' maxWidth>
					<p dangerouslySetInnerHTML={{ __html: uiTranslations.t('_filter.booking.adults') }} />
					<Counter
						className={cls.counter}
						value={room.adults}
						onChange={onChangeAdult(index)}
						maxValue={6}
						minValue={1}
					/>
				</Flex>
				<Flex gap='10px' justifyContent='between' className={cls.item} alignItems='center' maxWidth>
					<p dangerouslySetInnerHTML={{ __html: uiTranslations.t('_filter.booking.children') }}></p>
					<Counter
						className={cls.counter}
						value={room.children.length}
						onChange={onChangeChild(index)}
						maxValue={4}
						minValue={0}
					/>
				</Flex>
			</Flex>
			{isShowChildrenFields && (
				<Flex className={cls.items} direction='column' gap='10px' maxWidth>
					{room.children.map((child, indexChild) => {
						return (
							<Flex
								key={`${index}-${indexChild}`}
								gap='10px'
								justifyContent='between'
								className={cls.item}
								alignItems='center'
								maxWidth
							>
								<p>
									<span>
										{indexChild + 1} {uiTranslations.t('_filter.filters.child')}
									</span>
								</p>
								<Counter
									className={cls.counter}
									value={child}
									onChange={onChangeChildAge(index, indexChild)}
									maxValue={17}
									customViewValueFn={handlerCustomViewValueFn}
									minValue={0}
								/>
							</Flex>
						);
					})}
				</Flex>
			)}
		</Flex>
	);
});
