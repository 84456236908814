import { useSelector } from 'react-redux';
import React, { memo, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import dayjs from 'dayjs';

import { useSearchState } from '@common/hooks/useSearchState';
import { ICommonData } from '@main/src/types/common';
import { useHotelGuestState } from '@common/hooks';
import { Flex } from '@main/src/new/components/ui';
import { ESearchType } from '@main/src/types/pages/search';
import { DEFAULT_LOCALE } from '@app/constants/locales';
import { IconLoup, IconSettings } from '@new/components/icon';

import cls from './SearchInput.module.css';

interface ISearchInputProps {
	className?: string;
	onClick(): void;
	locationName?: string;
	type?: ESearchType;
	params?: string[];
}

export const SearchInput = memo(({ onClick, locationName, type, params }: ISearchInputProps) => {
	const { t } = useTranslation('ui');
	const { t: apiHotel } = useTranslation('api_hotel');
	const [lastSearch] = useSearchState();
	const lastSearchTitle = lastSearch ? lastSearch?.title.split(',')[0] : '';
	const { t: apiCmnTranslations } = useTranslation('api_cmn');
	const getCountryName = (cc?: string | null) => (cc ? apiCmnTranslations(`country.${cc}.name`) : '');
	const { locale } = useSelector((state: ICommonData) => state);
	const formatDate = (date: string) =>
		dayjs(date)
			.locale(locale?.code || DEFAULT_LOCALE.code)
			.format('DD MMM');
	const { bookGuestsState } = useHotelGuestState();

	const getDescriptionByType = useMemo(() => {
		if (type === 'accommodation') {
			return `${formatDate(bookGuestsState.fromDate)} - ${formatDate(bookGuestsState.toDate || '')}, ${apiHotel(
				'search.count_rooms',
				{ count: bookGuestsState.rooms.length }
			)}`;
		}
		return `${t('search.filters_count', { count: params?.length ? params?.length + 1 : 1 })}`;
	}, [type, params?.length, bookGuestsState, apiHotel]);

	const getTitle = () => {
		if (type && type !== 'any') return t(`search.filters.${type}.title`);
		if (lastSearchTitle) return lastSearchTitle;
		if (locationName) return locationName;
		return t('common.header.title');
	};

	const getDescription = () => {
		if (type && type !== 'any') return getDescriptionByType;
		if (lastSearch) return getCountryName((lastSearch as unknown as { cc: string }).cc);
		return t('common.header.description');
	};

	return (
		<Flex alignItems='center' className={cls.SearchInput} onClick={onClick}>
			<IconLoup className={cls.loup} />
			<div className={cls.input} />

			<Flex className={cls.content} direction='column'>
				<p>{getTitle()}</p>
				<span>{getDescription()}</span>
			</Flex>

			<IconSettings className={cls.setting} />
		</Flex>
	);
});
