import { useMemo, MouseEvent, useCallback, useState } from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { setCookies } from 'cookies-next';
import { useQueryClient } from '@tanstack/react-query';

import { ICurrency } from '@main/src/types/common';
import { useAuthContext } from '@main/src/new/auth';
import { Flex } from '@main/src/new/components/ui';
import { NEXT_PUBLIC_QVEDO_ROOT_DOMAIN } from '@app/constants';
import { COOKIE_CURRENCY, COOKIE_LOCALE, COOKIE_NEXT_LOCALE } from '@app/constants/cookie';
import { COOKIE_CLIENT_VERSION, MOBILE_COOKIE_CLIENT_VERSION_VALUE } from '@app/constants/version';
import { locales } from '@app/constants/locales';
import { currencies } from '@app/constants/currencies';

import cls from './Menu.module.css';
import { IconChat, IconWallet } from '@main/src/new/old/Icons';
import { useSelector } from 'react-redux';
import { RootState } from '@app/stores/pages/home';
import { IconLanguage, IconLogout, IconMenuClose, IconProfile } from '@main/src/new/components/icon';
import { Select } from '@main/src/new/components/ui/Select';
import { CascadeData, CustomConfig } from 'mobile-select';

interface IMenuProps {
	className?: string;
	onClick(): void;
}

interface Locale {
	code: string;
	originalName: string;
}

export const Menu = (props: IMenuProps) => {
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { onClick } = props;
	const { t } = useTranslation('ui');
	const router = useRouter();
	const queryClient = useQueryClient();
	const currency = useSelector((state: RootState) => state.currency);
	const locale = useSelector((state: { locale: Locale }) => state.locale);
	const [selectedLang, setLang] = useState<Locale>(locale);
	const [selectedCurrency, setCurrency] = useState<ICurrency>(currency);
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { getHasAnonymous, logout } = useAuthContext();
	const isLogin = !getHasAnonymous();

	const signOut = useCallback(
		(e: MouseEvent<HTMLElement>) => {
			e.preventDefault();
			void logout();

			if (router.pathname.startsWith('/profile')) {
				void router.push('/');
			} else if (router.pathname === '/') {
				// TODO dirty hack
				window.location.href = '/';
			}

			queryClient.clear();
		},
		[queryClient]
	);

	const profileMenu = useMemo(() => {
		if (isLogin) {
			return (
				<Flex gap='20px' direction='column' maxWidth>
					<Link href='/profile'>
						<Flex gap='10px' alignItems='center'>
							<div className={cls.svgBlock}>
								<IconProfile />
							</div>
							<p>{t('header.myCabinet')}</p>
						</Flex>
					</Link>
					<Link href='/profile/orders'>
						<Flex gap='10px' alignItems='center'>
							<div className={cls.svgBlock}>
								<IconChat />
							</div>
							<p>{t('header.tickets')}</p>
						</Flex>
					</Link>
					<Flex gap='10px' alignItems='center' onClick={signOut}>
						<div className={cls.svgBlock}>
							<IconLogout />
						</div>
						<p>{t('header.signOut')}</p>
					</Flex>
				</Flex>
			);
		}

		return null;
	}, [isLogin, signOut, t]);

	const handlerChangeLanguageCustom = useCallback(
		(item: Locale) => () => {
			setLang(item);
			setCookies(COOKIE_LOCALE, JSON.stringify(item), {
				domain: NEXT_PUBLIC_QVEDO_ROOT_DOMAIN,
				expires: new Date('01.01.2099'),
			});
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setCookies(COOKIE_NEXT_LOCALE, item.code, {
				domain: NEXT_PUBLIC_QVEDO_ROOT_DOMAIN,
				expires: new Date('01.01.2099'),
			});
			onClick();

			// await setLanguage(item.code);

			global.location.reload();
		},
		[onClick, global.location]
	);

	const handlerChangeCurrencyCustom = useCallback(
		(item: ICurrency) => () => {
			setCookies(COOKIE_CURRENCY, JSON.stringify(item), {
				domain: NEXT_PUBLIC_QVEDO_ROOT_DOMAIN,
				expires: new Date('01.01.2099'),
			});
			setCurrency(item);

			onClick();
			global.location.reload();
		},
		[onClick, global.location]
	);

	const configLocale = {
		ensureBtnText: t('header.changeApply'),
		cancelBtnText: t('header.changeCancel'),
		wheels: [
			{
				data: locales.map(i => ({
					id: i.code,
					value: i.originalName,
				})),
			},
		] as CascadeData[],
		onChange: (data: number[] | string[], indexArr: number[], instance: any) => {
			const [currentIndex] = indexArr;
			handlerChangeLanguageCustom(locales[currentIndex])();
		},
		initValue: locale.originalName,
	};

	const configCurrency = {
		ensureBtnText: t('header.changeApply'),
		cancelBtnText: t('header.changeCancel'),
		wheels: [
			{
				data: currencies.map(i => ({
					id: i.code,
					value: i.code,
				})),
			},
		],
		onChange: (data: number[] | string[], indexArr: number[], instance: any) => {
			const [currentIndex] = indexArr;
			handlerChangeCurrencyCustom(currencies[currentIndex])();
		},
		// TODO fix bug or use another library
		// initValue: currency.name,
	};

	const handleParentLinkClick = useCallback(() => {
		onClick();
	}, []);

	const handlerLinkVersionClick = useCallback(() => {
		setTimeout(() => {
			setCookies(COOKIE_CLIENT_VERSION, MOBILE_COOKIE_CLIENT_VERSION_VALUE, {
				domain: NEXT_PUBLIC_QVEDO_ROOT_DOMAIN,
				expires: new Date('01.01.2099'),
			});
		}, 0);
	}, []);

	const desktopUrl = `/${location.pathname}${location.search}`;
	// const desktopUrl = `${NEXT_PUBLIC_QVEDO_DESKTOP_SITE_HOST}${location.pathname}${location.search}`;

	return (
		<Flex className={cls.container} justifyContent='between' direction='column'>
			<div className={cls.icon} onClick={onClick}>
				<IconMenuClose />
			</div>
			<Flex direction='column' gap='24px' className={cls.menu} maxWidth onClick={handleParentLinkClick}>
				<Flex gap='20px' direction='column' maxWidth>
					<Link href='/'>{t('header.home')}</Link>

					<Link href={`https://qvedo.com/${locale.code}/blog`} target='_blank'>
						{t('header.blog')}
					</Link>
					<Link href={`https://qvedo.com/${locale.code}/about`} target='_blank'>
						{t('header.about')}
					</Link>
					<Link href={'/b2b'} target='_blank'>
						{t('header.b2b')}
					</Link>
					<Link href={`https://my.qvedo.com/`} target='_blank'>
						{t('header.office')}
					</Link>
					{/*<Link href={desktopUrl} onClick={handlerLinkVersionClick}>*/}
					{/*	{t('header.fullVersion')}*/}
					{/*</Link>*/}
					{!isLogin && <Link href='/login'>{t('header.signIn')}</Link>}
				</Flex>
				{profileMenu}
				<Flex gap='20px' direction='column'>
					<div>
						<Flex gap='10px' alignItems='center'>
							<div className={cls.svgBlock}>
								<IconLanguage />
							</div>
							<Flex className={cls.trigger} gap={'4px'}>
								{t('header.lang')}:
								<Select config={configLocale as unknown as Omit<CustomConfig, 'trigger'>}>
									<div className={cls.trigger}>{selectedLang.originalName}</div>
								</Select>
							</Flex>
						</Flex>
					</div>
					<div>
						<Flex gap='10px' alignItems='center'>
							<div className={cls.svgBlock}>
								<IconWallet />
							</div>
							<Flex className={cls.trigger} gap={'4px'}>
								{t('header.currency')}:
								<Select config={configCurrency as unknown as Omit<CustomConfig, 'trigger'>}>
									<div className={cls.trigger}>{selectedCurrency.code}</div>
								</Select>
							</Flex>
						</Flex>
					</div>
				</Flex>
			</Flex>
			<p className={cls.bottom}>{t('header.description')}</p>
		</Flex>
	);
};
Menu.displayName = 'Menu';
