import { IUserLocation } from '@main/src/types/common';
import { ILoginGeo } from '@main/src/types/geo';

export interface IGetCurrentUserLocation {
	locale: string;
	geo: ILoginGeo;
	locationName?: string;
	bbox?: [[number, number], [number, number]];
}

export const getUserLocationName = ({ locale, geo }: IGetCurrentUserLocation): string | undefined => {
	return geo.cityName && geo.cityName[0];

	// if (locale === 'ru') {
	// 	if (geo.cityName) {
	// 		if (geo.cityName.length === 1) {
	// 			return geo.cityName[0];
	// 		} else if (geo.cityName.length === 2) {
	// 			return geo.cityName[1];
	// 		}
	// 	}
	// } else {
	// 	if (geo.cityName && geo.cityName.length >= 1) {
	// 		return geo.cityName[0];
	// 	}
	// }
};

export const getCurrentUserLocation = ({ locale, geo, locationName, bbox }: IGetCurrentUserLocation) => {
	const displayName: string | undefined = getUserLocationName({ locale, geo });

	const currentLocation: IUserLocation = {
		// TODO refactor init coords always use loginGeo
		point: [geo.lat, geo.lng],
		city: {
			display_name: displayName || '',
			country_code: geo.cc,
			id: geo.cityId || '',
		},
		bbox,
	};

	if (locationName) {
		currentLocation.city = {
			display_name: locationName ? decodeURI(locationName) : '',
			country_code: '',
			id: '',
		};
	}

	return currentLocation;
};
