import { combineCommonModels } from '@app/stores/helpers';
import { IGuide } from '@main/src/types/entities/guide';
import { init, RematchStore } from '@rematch/core';
import { ICommonData } from 'src/types/common';
import { activityPage, guides } from '../../models';
import { RootModel } from './types';

export const models = combineCommonModels<RootModel>({ activityPage, guides });

export const makeStore = (initState: ICommonData, guides: IGuide[]): RematchStore<RootModel, RootModel> => {
	const state = init<RootModel>({ models }).getState();

	return init<RootModel>({
		models,
		redux: { initialState: { ...state, ...initState, guides } },
	});
};
