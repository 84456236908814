import React, { memo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Flex } from '@main/src/new/components/ui';
import { Controller, useFormContext } from 'react-hook-form';
import { Input } from '@new/pages/BookingHotel/components/Input';
import { useAuthContext } from '@main/src/new/auth';

import cls from './GuestData.module.css';

interface IGuestDataProps {
	index: number;
	className?: string;
	title?: string;
}

export const GuestData = memo((props: IGuestDataProps) => {
	const { className, index, title } = props;
	const { t } = useTranslation('ui');
	const { control } = useFormContext();
	const hotelTranslation = useTranslation('api_hotel');
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { getHasAnonymous } = useAuthContext();
	const isDisable = getHasAnonymous();

	return (
		<>
			<Controller
				name={`rooms.${index}.guests.0.first_name`}
				control={control}
				render={({ field, fieldState }) => {
					//eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					const value = field.value as string;

					return (
						<Input
							label={`${hotelTranslation.t('booking.first_name')} (${hotelTranslation.t('booking.name_help')})`}
							required
							placeholder='IVAN'
							value={value}
							onChange={field.onChange}
							invalid={fieldState.invalid}
							disabled={isDisable}
						/>
					);
				}}
				rules={{ required: true, minLength: 2 }}
			/>
			<Controller
				name={`rooms.${index}.guests.0.last_name`}
				control={control}
				render={({ field, fieldState }) => {
					//eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					const value = field.value as string;

					return (
						<Input
							label={`${hotelTranslation.t('booking.last_name')} (${hotelTranslation.t('booking.name_help')})`}
							required
							placeholder='IVANOV'
							value={value}
							onChange={field.onChange}
							invalid={fieldState.invalid}
							disabled={isDisable}
						/>
					);
				}}
				rules={{ required: true, minLength: 2 }}
			/>
		</>
	);
});

GuestData.displayName = 'GuestData';
