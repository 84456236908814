import { IRateImage, IRoomsRatesHotel } from '@main/src/types/booking/accomodationBooking';
import { IRoomsRatesHotelResp } from './types';
import { getCurrency } from '@common/helpers';
import { ICurrencyRates } from '@main/src/types/common/currencyRates';
import { ICurrency, ILocale } from '@main/src/types/common';
import dayjs from 'dayjs';

export const STUB_IMAGES = [
	{
		original: '/statics/images/no_img_placeholder.png',
		thumbnail: '/statics/images/no_img_placeholder.png',
	},
];
export const mapper = (
	checkinDate: string,
	checkoutDate: string,
	response: IRoomsRatesHotelResp,
	currencyRates?: ICurrencyRates | null,
	currency?: ICurrency | null,
	locale?: ILocale | null
): IRoomsRatesHotel | null => {
	const rates = response.rates.map(i => {
		const images: IRateImage[] | undefined =
			i.images && i.images.length > 0
				? i.images.map(image => {
						const preparedImage = image.replace('{size}', '1024x768');
						return {
							thumbnail: preparedImage,
							original: preparedImage,
						};
				  })
				: STUB_IMAGES;

		const [paymentOptions] = i.payment_options.payment_types;
		const priceValue = parseFloat(paymentOptions.show_amount);
		const priceCurrency = getCurrency(paymentOptions.show_currency_code);

		const getPriceValue = () => {
			if (currencyRates && currency && priceValue) {
				return Math.ceil(priceValue * currencyRates[paymentOptions.show_currency_code][currency?.code]);
			}
			return null;
		};

		let priceSecondValue: number | null;
		if (locale?.code !== 'ru') {
			priceSecondValue = currency?.code !== 'USD' ? getPriceValue() : null;
		} else {
			priceSecondValue = currency?.code !== 'RUB' ? getPriceValue() : null;
		}

		return {
			...i,
			images,
			price: {
				value: Math.ceil(priceValue),
				currency: priceCurrency,
				userPrice: priceSecondValue,
				userCurrency: currency,
			},
		};
	});

	const nightCount = dayjs(checkoutDate).diff(dayjs(checkinDate), 'days');

	return {
		...response,
		rates,
		nightCount,
	};
};
