import React, { FC, memo, useMemo } from 'react';
import cls from './BookingHotelName.module.css';
import classNames from 'classnames';
import Image from 'next/image';
import { Flex } from '@main/src/new/components/ui';
import { StarsBlock } from '@main/src/new/components/StarsBlock';
import { IconKingBad, IconPin } from '@main/src/new/old/Icons';
import { useSelector } from 'react-redux';
import { RootState } from '@app/stores/pages/booking';
import { TEtgRates } from '@main/src/types/booking/accomodationBooking';
import { ILocalStorageGuestsState } from '@new/pages/BookingHotel/types';
import useTranslation from 'next-translate/useTranslation';
import { IconMeal } from '@new/components/icon';
import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import { Fancybox } from '@fancyapps/ui';

import '@fancyapps/ui/dist/fancybox/fancybox.css';

interface IBookingHotelNameProps {
	className?: string;
	room: TEtgRates;
	bookGuestsState: ILocalStorageGuestsState;
	localeCode: string;
	starRating: number;
}

export const BookingHotelName = memo((props: IBookingHotelNameProps) => {
	const { className, room, bookGuestsState, localeCode, starRating } = props;
	const { cardDetail } = useSelector((state: RootState) => state.booking);
	const hotelStaticTranslation = useTranslation('api_hotel_static');
	const hotelTranslation = useTranslation('api_hotel');
	const hotelMiscTranslation = useTranslation('api_hotel_misc');

	const [paymentOptions, ...restPaymentOptions] = room.payment_options.payment_types;

	// TODO refactor
	const preparedBeddingType = useMemo(() => {
		switch (room?.rg_ext.bedding) {
			case 1:
				return {
					icon: 'hotelAmenitiesBed',
					text: hotelStaticTranslation.t('beddings.bunk_bed'),
				};
			case 2:
				return {
					icon: 'hotelAmenitiesBed',
					text: hotelTranslation.t('static_ext.beddings.single_bed'),
				};
			case 3:
				return {
					icon: 'hotelAmenitiesBed',
					text: hotelStaticTranslation.t('beddings.double'),
				};
			case 4:
				return {
					icon: 'hotelAmenitiesBed',
					text: hotelStaticTranslation.t('beddings.twin'),
				};
			case 0:
			case 7:
			default:
				return {
					icon: 'hotelAmenitiesBed',
					text: hotelTranslation.t('static_ext.beddings.multiple'),
				};
		}
	}, [room]);
	const amenities = useMemo(() => {
		return room?.amenities_data?.map((amenity, index) => {
			const translateKey = `room_amenities.${amenity}`;
			const text = hotelStaticTranslation.t(translateKey);

			if (text === translateKey) {
				return null;
			}

			return (
				<div key={index} className={cls.item}>
					<p>{text}</p>
				</div>
			);
		});
		// .filter(f => !!f)
	}, [room]);
	const meal = room?.meal || 'nomeal';

	let paymentTitle;

	// TODO isFinished undefined/not used
	// if (freeCancellationPolicy?.type === 'now' && props.isFinished) {
	// 	paymentTitle = t('api_hotel.booking.pay_now_finished');
	// } else
	if (paymentOptions?.type === 'now') {
		paymentTitle = hotelMiscTranslation.t('policies.payment.payOnline.title');
	} else {
		paymentTitle = hotelMiscTranslation.t('policies.payment.payAtHotel.title');
	}
	let paymentCardData;
	if (paymentOptions?.is_need_credit_card_data) {
		paymentCardData = hotelTranslation.t('payment.card_required');
	} else {
		paymentCardData = hotelTranslation.t('payment.no_card_required');
	}

	const fromDate = dayjs(bookGuestsState.fromDate).locale(localeCode).format('D MMM YYYY, dd');
	const toDate = dayjs(bookGuestsState.toDate).locale(localeCode).format('D MMM YYYY, dd');

	const textFreeCancellation = paymentOptions.cancellation_penalties.free_cancellation_before
		? hotelTranslation.t('rooms.free_cancellation_before', {
				date: dayjs(paymentOptions.cancellation_penalties.free_cancellation_before)
					.locale(localeCode)
					.format('D MMM YYYY HH:mm'),
		  })
		: hotelTranslation.t('rooms.no_cancellation');
	const textFreeCancellationArr = textFreeCancellation.split(' ');
	const textFreeCancellationPart1 = textFreeCancellationArr.slice(0, 3).join(' ');
	const textFreeCancellationPart2 = textFreeCancellationArr.slice(3).join(' ');

	const showFancy = () => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call
		new Fancybox(
			cardDetail?.imagesGallery?.map(src => ({
				src: src.thumbnail,
			})) || []
		);
	};

	return (
		<div className={classNames(className, cls.BookingHotelName)}>
			<Flex gap='24px' className={cls.name}>
				<div className={cls.img}>
					{cardDetail?.imagesGallery && cardDetail.imagesGallery[0].thumbnail && (
						<Image src={cardDetail.imagesGallery[0].thumbnail} fill alt='photo' onClick={showFancy} />
					)}
				</div>
				<div className={cls.info}>
					<p className={cls.type}>Отель</p>
					<div className={cls.nameBlock}>
						<div className={cls.header}>
							<p>{cardDetail?.nameLocation?.name}</p>
							{starRating > 0 && <StarsBlock type='withOutBackground' rating={starRating} />}
						</div>
						<Flex className={cls.address} gap='6px' alignItems='start'>
							<IconPin />
							<p>{cardDetail?.nameLocation?.address}</p>
						</Flex>
					</div>
					{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
					{/* @ts-ignore */}
					<p className={cls.room}>{room?.room_data_trans.main_name}</p>
					<Flex className={cls.tags} gap='4px' direction='column'>
						<div className={cls.tag}>
							<div className={cls.svg}>
								<IconKingBad />
							</div>
							<p>{preparedBeddingType.text}</p>
						</div>
						<Flex className={cls.tag}>
							<div className={classNames(cls.svg, meal === 'nomeal' && cls.nomeal)}>
								<IconMeal />
							</div>{' '}
							<p>{hotelStaticTranslation.t(`meals.${meal}`)}</p>
						</Flex>
					</Flex>
				</div>
			</Flex>
			<div className={cls.amenities}>
				{amenities && amenities.length > 0 && (
					<Flex className={cls.items} gap='14px'>
						{amenities}
					</Flex>
				)}
			</div>
			<Flex className={cls.dataTrip} alignItems='center' justifyContent='between'>
				<div className={cls.item}>
					<p>
						{hotelMiscTranslation.t('policies.checkIn')} {cardDetail?.ostrovok?.checkInTime}
						<br />
						<span>{fromDate}</span>
					</p>
				</div>
				<div className={cls.item}>
					<p>
						{hotelMiscTranslation.t('policies.checkOut')} {cardDetail?.ostrovok?.checkOutTime}
						<br />
						<span>{toDate}</span>
					</p>
				</div>
				<div className={cls.item}>
					<p>
						<span>
							{textFreeCancellationPart1}
							<br />
							{textFreeCancellationPart2}
						</span>
					</p>
				</div>
				<div className={cls.item}>
					<span>{paymentTitle}</span>
				</div>
			</Flex>
		</div>
	);
});
