import React, { memo } from 'react';
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';

import { ESearchType } from '@main/src/types/pages/search';
import { Flex } from '@main/src/new/components/ui';
import { IconActivityBlue, IconBed, IconCity, IconHumbugger, IconPlace } from '@new/old/Icons';

import cls from './FiltersType.module.css';

interface IFiltersProps {
	className?: string;
	onChangeType(type: ESearchType | ''): () => void;
	type: ESearchType | '';
}

export const LIST_TYPES = [
	{ title: 'search.filters.activity.title', icon: <IconActivityBlue />, type: ESearchType.activity },
	{ title: 'search.filters.accommodation.title', icon: <IconBed />, type: ESearchType.accommodation },
	{ title: 'search.filters.restaurant.title', icon: <IconHumbugger />, type: ESearchType.restaurant },
	{ title: 'search.filters.attraction.title', icon: <IconPlace />, type: ESearchType.attraction },
	{ title: 'search.filters.publicPlace.title', icon: <IconCity />, type: ESearchType.publicPlace },
];

export const FiltersType = memo(({ onChangeType, type }: IFiltersProps) => {
	const { t } = useTranslation('ui');

	return (
		<Flex alignItems='center' justifyContent='between' maxWidth>
			{LIST_TYPES.map((listType, index) => {
				return (
					<div
						key={index}
						className={classNames(cls.item, type === listType.type && cls.active)}
						onClick={onChangeType(type === listType.type ? '' : listType.type)}
					>
						<Flex justifyContent='flex-center' alignItems='center'>
							{listType.icon}
						</Flex>
						<p>{t(listType.title)}</p>
					</div>
				);
			})}
		</Flex>
	);
});
