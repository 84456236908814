import { captureException } from '@sentry/core';
import axios from 'axios';

export const post3DSM = async (params: {
	url: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	data: Record<string, unknown>;
}): Promise<OrderUsrEtgStatusResp | null> => {
	try {
		const payload = new URLSearchParams(params.data as Record<string, string>);
		const result = await axios.post(params.url, payload);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-return
		return result;
	} catch (error) {
		captureException(error);

		return null;
	}
};
