import classNames from 'classnames';
import React, { FC, memo } from 'react';
import { Flex } from '../ui';
import cls from './StarsBlock.module.css';
import { IconStarActivity } from '../icon';

interface IStarsBlockProps {
	className?: string;
	rating?: number;
	type?: 'default' | 'orange' | 'withOutBackground';
}

export const StarsBlock = memo((props: IStarsBlockProps) => {
	const { className, rating = 0, type = 'default' } = props;
	if (type === 'withOutBackground') {
		return (
			<Flex gap='4px' alignItems='center' className={classNames(cls.starsWithoutBack, className)}>
				{Array(5)
					.fill(0)
					.map((i, index) => (
						<IconStarActivity className={classNames(index < rating ? cls.activity : cls.none)} key={index} />
					))}
			</Flex>
		);
	}
	if (type === 'orange') {
		return (
			<Flex className={classNames(cls.star, cls.orange, className)} gap={'6px'}>
				{Array(5)
					.fill(0)
					.map((i, index) => (
						<IconStarActivity className={classNames(index < rating ? cls.activity : cls.none)} key={index} />
					))}
			</Flex>
		);
	}
	return (
		<Flex className={classNames(cls.star, className)} gap={'6px'}>
			{Array(5)
				.fill(0)
				.map((i, index) => (
					<IconStarActivity className={classNames(index < rating && cls.activity)} key={index} />
				))}
		</Flex>
	);
});
