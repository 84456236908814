import { TCardDetail } from '@main/src/types/pages/cardDetail';
import { createModel } from '@rematch/core';

export const initialState: TCardDetail = {
	// TODO remove
	work_time: null,
	contacts: null,
	type: 'restaurant',
	offers: [],
	id: '',
	center: null,
	activityTimeSlots: [],
	originalType: '1',
	de: 'dev',
};

export const cardDetail = createModel()({
	state: initialState,
});
