'use client';
import { Flex } from '@main/src/new/components/ui';
import classNames from 'classnames';
import React, { memo, useCallback } from 'react';
import cls from './Counter.module.css';
import { IconMinusGrey, IconPlus } from '@new/old/Icons';

interface ICounterProps {
	className?: string;
	value: number;
	onChange?: (i: number) => void;
	minValue?: number;
	maxValue?: number;
	customViewValueFn?(value: number): string;
}

export const Counter = memo((props: ICounterProps) => {
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { className, value, onChange, minValue = 0, maxValue, customViewValueFn } = props;

	const disabledMinus = value && value <= minValue;
	const disabledPlus = value && maxValue && value >= maxValue;

	const handlerPlus = useCallback(() => {
		onChange?.(value + 1);
	}, [value, onChange]);

	const handlerMinus = useCallback(() => {
		const newValue = value - 1 < minValue ? minValue : value - 1;
		onChange?.(newValue);
	}, [onChange, value, minValue]);
	return (
		<Flex className={classNames(className, cls.Counter)} gap='10px' alignItems='center'>
			<div className={classNames(cls.button, cls.minus, { [cls.disabled]: disabledMinus })} onClick={handlerMinus}>
				<IconMinusGrey />
			</div>
			<p>{customViewValueFn ? customViewValueFn(value) : value}</p>

			<div className={classNames(cls.button, cls.plus, { [cls.disabled]: disabledPlus })} onClick={handlerPlus}>
				<IconPlus />
			</div>
		</Flex>
	);
});
