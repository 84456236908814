import { ChangeEvent, FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FilterInput } from './../FilterInput';
import { Flex } from '@main/src/new/components/ui';
import cls from './CityInput.module.css';
import { IconPin, IconCross } from '@new/components/icon';
import useTranslation from 'next-translate/useTranslation';
import { useSearchCityWord } from '@common/dataContext/countries/useSearchCityWord';
import { DEFAULT_LOCALE } from '@app/constants/defaults';
import { IUserLocation } from '@main/src/types/common';
import { useDebounce } from 'usehooks-ts';
import { Translate } from 'next-translate';
import { getCountry } from '@main/shared/utils/country_utils';
import regions from '@shared/taxonomy/cmn/region.json';
import { ImageWithFallback } from '@main/src/new/components/ImageWithFallback';
import { useLoginGeoState } from '@common/hooks/useLoginGeoState';
import { useRouter } from 'next/router';
import { EActivityType } from '@main/src/types/entities/activity';
import { useSearchState } from '@common/hooks/useSearchState';
import { Loader } from '@main/src/new/components/Loader';
import { useAuthContext } from '@main/src/new/auth';

export interface ICityInputProps {
	className?: string;
	cityName?: string;
	onChange(value: IUserLocation): void;
}

interface IRegion {
	geo: {
		bbox: string;
		location: number[];
	};
}

type IRegionCities = Record<string, { featured_destinations: Record<string, IRegion> }>;

export const getDistance = (dist: number, t: Translate) => {
	let distance: string;

	const distanceKilometers = dist / 1000;

	if (distanceKilometers > 10) {
		distance = t(`_filter.distance.from_you_kilometers`, {
			distance: distanceKilometers.toFixed(0),
		});
	} else if (distanceKilometers > 1) {
		distance = t(`_filter.distance.from_you_kilometers`, {
			distance: distanceKilometers.toFixed(0),
		});
	} else {
		distance = t(`_filter.distance.from_you_meters`, {
			distance: dist.toFixed(0),
		});
	}

	return distance;
};

export const CityInput: FC<ICityInputProps> = memo(props => {
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { cityName, onChange } = props;
	const [lastSearch, setLastSearch] = useSearchState();
	const lastSearchTitle = lastSearch ? lastSearch?.title.split(',')[0] : '';
	const { loginGeo } = useLoginGeoState();
	const uiTranslations = useTranslation('ui');
	const apiCmnTranslations = useTranslation('api_cmn');
	const [value, setValue] = useState(lastSearchTitle || cityName || '');
	const [visible, setVisible] = useState(false);
	const [hideDiv, setHideDiv] = useState(cityName !== '');
	const debouncedSearchCity = useDebounce(value, 200);
	const router = useRouter();
	const { data, isLoading } = useSearchCityWord(debouncedSearchCity, visible, uiTranslations.lang || DEFAULT_LOCALE, [
		loginGeo.lat,
		loginGeo.lng,
	]);
	const { clientWidth } = useAuthContext();
	const isMobile = Number(clientWidth) < 1001;

	useEffect(() => {
		if (isMobile) {
			setVisible(false);
		}
	}, [isMobile]);

	const result = useMemo(() => {
		return {
			cards: data?.cards.map((i, index) => ({ ...i, key: [i.location, i.title, index] })),
			places: data?.places.map((i, index) => ({ ...i, key: [i.location, i.title, index], bbox: i.bbox })),
		};
	}, [data]);
	const country = getCountry(loginGeo.cc);
	const region = country?.region || 'cis_asia';

	const handlerCityChange = useCallback(
		(nextValue: { title: string; location: [number, number]; bbox?: [[number, number], [number, number]] }) => () => {
			onChange({
				city: {
					display_name: nextValue.title,
					country_code: '',
					id: '',
				},
				point: nextValue.location,
				bbox: nextValue.bbox,
			});
			const cityArr = nextValue.title.split(',');
			setValue(cityArr[0]);
			setLastSearch(nextValue);
			if (inputRef.current) inputRef.current.value = cityArr[0];
			setVisible(false);
		},
		[onChange]
	);

	const handlerObjectChange = useCallback(
		(nextValue: {
				id: string;
				title: string;
				location: [number, number];
				bbox?: [[number, number], [number, number]];
				type: EActivityType;
			}) =>
			() => {
				onChange({
					city: {
						display_name: nextValue.title,
						country_code: '',
						id: '',
					},
					point: nextValue.location,
					bbox: nextValue.bbox,
				});
				const cityArr = nextValue.title.split(',');
				setValue(cityArr[0]);
				setLastSearch(nextValue);
				if (inputRef.current) inputRef.current.value = cityArr[0];
				setVisible(false);
				const urlType = nextValue.type === 'accommodation' ? 'hotel' : nextValue.type;
				const linkToCard = `/${urlType}/${nextValue.id}`;
				void router.push(linkToCard);
			},
		[onChange]
	);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const defaultCitiesView = useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument
		return Object.entries((regions as IRegionCities)[region].featured_destinations).map(([key, i]) => {
			const displayCityName = apiCmnTranslations.t(`destination.${key}`, {
				defaultValue: key,
			});
			const bbox = i.geo.bbox.split(',');
			return (
				<div
					key={key}
					className={cls.city}
					onClick={handlerCityChange({
						title: displayCityName,
						location: i.geo.location as [number, number],
						bbox: [
							[+bbox[1], +bbox[0]],
							[+bbox[3], +bbox[2]],
						],
					})}
				>
					<div>
						<ImageWithFallback
							src={`/statics/new/stub/cityCards/${key}.jpg`}
							alt={displayCityName}
							layout='fill'
							title={displayCityName}
							fallbackSrc='/statics/new/notFoundImg.png'
						/>
					</div>
					<p>{displayCityName}</p>
				</div>
			);
		});
	}, [region, apiCmnTranslations, handlerCityChange]);
	const content = useMemo(() => {
		let info;

		if (value.length < 3) {
			info = <p>{uiTranslations.t('_filter.cities.enter')}</p>;
		} else if (isLoading) {
			info = <Loader />;
		} else if ((result?.cards && result.cards.length > 0) || (result?.places && result.places.length > 0)) {
			info = (
				<>
					{result.places && result.places?.length > 0 && (
						<Flex gap='14px' direction='column' className={cls.group}>
							<div className={cls.group_name}>{uiTranslations.t('_filter.autocomplete_places')}</div>
							{result.places.map((i, index) => {
								const cityArr = i.title.split(',');
								return (
									<Flex key={index} gap='14px' className={cls.name} onClick={handlerCityChange(i)}>
										<IconPin className={cls.pin} />
										<Flex gap='4px' direction='column'>
											<p>{cityArr[0]}</p>
											<span>{i.address}</span>
											<span>{getDistance(i.distance, uiTranslations.t)}</span>
										</Flex>
									</Flex>
								);
							})}
						</Flex>
					)}
					{result.cards && result.cards?.length > 0 && (
						<Flex gap='14px' direction='column' className={cls.group}>
							<div className={cls.group_name}>{uiTranslations.t('_filter.autocomplete_cards')}</div>
							{result.cards.map((i, index) => {
								const cityArr = i.title.split(',');
								return (
									<Flex key={index} gap='14px' className={cls.name} onClick={handlerObjectChange(i)}>
										<IconPin className={cls.pin} />
										<Flex gap='4px' direction='column'>
											<p>{cityArr[0]}</p>
											<span>
												{uiTranslations.t(`cardActivity.${i.type}`)}
												{i.cc && ', '}
												{i.cc &&
													apiCmnTranslations.t(`country.${i.cc}.name`, {
														defaultValue: '',
													})}
											</span>
											<span>{getDistance(i.distance, uiTranslations.t)}</span>
										</Flex>
									</Flex>
								);
							})}
						</Flex>
					)}
				</>
			);
		} else {
			info = <p>{uiTranslations.t('_filter.cities.empty')}</p>;
		}

		return (
			<Flex gap='20px' className={cls.ContentModal}>
				<Flex className={cls.names} direction='column' gap='10px' alignItems='start'>
					{info}
				</Flex>
				<Flex className={cls.cities} gap='20px' wrap>
					{defaultCitiesView}
				</Flex>
			</Flex>
		);
	}, [result, handlerCityChange, defaultCitiesView, value, isLoading]);

	const inputRef = useRef<HTMLInputElement | null>(null);

	const changeVisible = useCallback(
		(i: boolean) => {
			if (i) {
				setVisible(true);
				inputRef.current?.focus();
			} else {
				setVisible(false);
				inputRef.current?.blur();
			}
		},
		[inputRef]
	);
	const handlerChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setValue(e.target.value);
		setVisible(e.target.value.length > 0);
	}, []);

	// TODO remove
	useEffect(() => {
		if (inputRef.current && cityName) {
			inputRef.current.value = lastSearchTitle || cityName;
		}
	}, []);

	return (
		<FilterInput
			visible={visible}
			content={content}
			changeVisible={changeVisible}
			className={cls.CityInput}
			trigger='hover'
		>
			<IconPin className={cls.pin} />
			{!hideDiv && <div onClick={() => setHideDiv(true)}>{uiTranslations.t('_filter.cities.title')}</div>}
			<input
				ref={inputRef}
				placeholder={uiTranslations.t('_filter.cities.placeholder')}
				defaultValue={cityName}
				onChange={handlerChange}
			/>
			<IconCross
				className='cross'
				onClick={() => {
					setValue('');
					setLastSearch(null);
					if (inputRef.current) inputRef.current.value = '';
				}}
			/>
		</FilterInput>
	);
});
