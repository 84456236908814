import React, { memo, useCallback, useEffect, useState } from 'react';
import cls from './Filters.module.css';
import { WhiteContainer } from '@new/components/WhiteContainer';
import classNames from 'classnames';
import { Flex, Button } from '@main/src/new/components/ui';
import { CityInput } from './CityInput';
import { SelectType } from './SelectType';
import { useSelector } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import { ILocale, IUserLocation } from '@main/src/types/common';
import { useRouter } from 'next/router';
import { DEFAULT_LOCALE } from '@app/constants/defaults';
import { IS_DEV } from '@app/constants';
import { ESearchType } from '@main/src/types/pages/search';
import { EResult, useMapContext } from '@components/QMap3/context';
import { FiltersPopup } from './FiltersInput';
import { DateRangeInput } from '@new/layouts/components/Header/components/Filters/DateRangeInput';
import { GuestsInput } from '@new/layouts/components/Header/components/Filters/GuestsInput';
import { useHotelGuestState } from '@common/hooks';
import { IRoom } from '@new/pages/BookingHotel/types';
import { DEFAULT_CHILDREN_AGE } from '@new/layouts/components/Header/components/Filters/Filters';
import { useMap } from 'react-map-gl';
import { useLoginGeoState } from '@common/hooks/useLoginGeoState';
import { getCurrentUserLocation } from '@common/helpers/filters/getCurrentUserLocation';
import { useSearchState } from '@common/hooks/useSearchState';

export interface IMapFiltersProps {
	className?: string;
}

export const MapFilters = memo((props: IMapFiltersProps) => {
	const { className } = props;
	const { t } = useTranslation('ui');
	const router = useRouter();
	const currentMap = useMap();
	const urlType = router.query.type as string;
	const urlLocationName = router.query.locationName as string;
	const isSearchPage = router.pathname.startsWith('/search') || router.pathname.startsWith('/list');

	// eslint-disable-next-line @typescript-eslint/unbound-method
	const {
		params,
		setLocationCenter,
		setLocationName,
		setSelectType,
		selectType,
		changeParams,
		setResultStatus,
		setCards,
	} = useMapContext();

	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
	const locale: ILocale = useSelector((state: any) => state.locale);
	const { bookGuestsState, setGuestsState } = useHotelGuestState();
	const { loginGeo } = useLoginGeoState();
	const [lastSearch] = useSearchState();

	const currentUserLocation: IUserLocation = getCurrentUserLocation({
		locale: locale?.code || DEFAULT_LOCALE,
		geo: lastSearch
			? {
					lat: lastSearch.location[0],
					lng: lastSearch.location[1],
					lat6: lastSearch.location[0] * 1000000,
					lon6: lastSearch.location[1] * 1000000,
					cityName: [lastSearch.title],
					cc: locale?.code || DEFAULT_LOCALE,
			  }
			: loginGeo,
		locationName: urlLocationName,
	});

	const [currentType, setCurrentType] = useState<string | null>(selectType || urlType || '');
	const [currentCity, setCurrentCity] = useState<IUserLocation>(currentUserLocation);

	const handleClick = useCallback(async () => {
		let nextUrl = `/${locale.code || DEFAULT_LOCALE}/search?zoom=12`;
		if (currentType) {
			nextUrl += `&type=${currentType}`;
		}
		if (currentCity?.bbox) {
			nextUrl += `&bounds=${currentCity.bbox.flat().join(',')}`;
		} else if (currentCity?.point) {
			nextUrl += `&center=${currentCity.point[0]},${currentCity.point[1]}`;
		}
		if (currentCity?.city.display_name) {
			const splitName = currentCity.city.display_name.split(',');
			nextUrl += `&locationName=${splitName[0]}`;
		}
		// TODO dirty hack
		if (router.pathname === '/search') {
			await router.push(encodeURI(nextUrl));
		} else {
			window.location.href = `${IS_DEV ? 'http' : 'https'}://${window.location.host}${nextUrl}`;
		}
	}, [currentCity, currentType, router, locale]);

	const searchHandlerChangeCity = useCallback(
		(nextValue: IUserLocation) => {
			setResultStatus(EResult.loading);
			setCards([]);
			setCurrentCity(nextValue);
			setLocationName(nextValue.city.display_name || '');

			if (currentMap.default && nextValue.bbox) {
				currentMap.default.fitBounds(nextValue.bbox);
			} else {
				setLocationCenter(nextValue.point);
			}
		},
		[params, currentMap]
	);

	const searchHandlerChangeType = useCallback(
		(nextValue: string) => {
			setCurrentType(nextValue);
			setSelectType(nextValue as ESearchType);
			changeParams([]);
		},
		[params]
	);

	const handlerChangeCity = isSearchPage ? searchHandlerChangeCity : setCurrentCity;
	const handlerChangeType = isSearchPage ? searchHandlerChangeType : setCurrentType;

	const handlerChangeAdult = useCallback(
		(roomIndex: number) => (value: number) => {
			const cloneRooms = [...bookGuestsState.rooms];
			cloneRooms[roomIndex].adults = value;

			setGuestsState({
				...bookGuestsState,
				rooms: cloneRooms,
			});
		},
		[bookGuestsState]
	);
	const handlerChangeChild = useCallback(
		(roomIndex: number) => (value: number) => {
			const cloneRooms = [...bookGuestsState.rooms];
			const cloneChildren = [...cloneRooms[roomIndex].children];

			if (cloneChildren.length >= value) {
				cloneRooms[roomIndex].children = cloneChildren.slice(0, value);
			} else if (value === cloneChildren.length + 1) {
				cloneRooms[roomIndex].children = [...cloneChildren, ...[DEFAULT_CHILDREN_AGE]];
			}

			setGuestsState({
				...bookGuestsState,
				rooms: cloneRooms,
			});
		},
		[bookGuestsState]
	);
	const handlerChangeChildAge = useCallback(
		(roomIndex: number, childIndex: number) => (value: number) => {
			const cloneRooms = [...bookGuestsState.rooms];
			cloneRooms[roomIndex].children[childIndex] = value;

			setGuestsState({
				...bookGuestsState,
				rooms: cloneRooms,
			});
		},
		[bookGuestsState]
	);
	const handlerAddRoom = useCallback(() => {
		setGuestsState({
			...bookGuestsState,
			rooms: [...bookGuestsState.rooms, ...[{ adults: 2, children: [] }]],
		});
	}, [bookGuestsState]);
	const handlerRemoveRoom = useCallback(
		(index: number) => () => {
			const nextRooms = bookGuestsState.rooms
				.map((i, indexR) => {
					if (index === indexR) {
						return null;
					}

					return i;
				})
				.filter(f => !!f) as IRoom[];
			setGuestsState({
				...bookGuestsState,
				rooms: nextRooms,
			});
		},
		[bookGuestsState]
	);
	const hasShowHotelFilters = currentType === 'accommodation';

	useEffect(() => {
		setCurrentType(selectType);
	}, [selectType]);

	return (
		<WhiteContainer border='6px' className={classNames(className, cls.Filters)}>
			<Flex justifyContent='between'>
				<Flex gap='12px'>
					<CityInput cityName={currentCity.city.display_name} onChange={handlerChangeCity} />
					<SelectType onChange={handlerChangeType} value={currentType} />
					{hasShowHotelFilters && <DateRangeInput />}
					{hasShowHotelFilters && (
						<GuestsInput
							bookGuestsState={bookGuestsState}
							onChangeAdult={handlerChangeAdult}
							onChangeChild={handlerChangeChild}
							onChangeChildAge={handlerChangeChildAge}
							onAddRoom={handlerAddRoom}
							onRemoveRoom={handlerRemoveRoom}
						/>
					)}
				</Flex>
				<Flex gap='16px' className={cls.buttons}>
					{selectType !== 'any' && <FiltersPopup />}
					{!isSearchPage && (
						<Button outline onClick={handleClick}>
							{t('common.findNow')}
						</Button>
					)}
				</Flex>
			</Flex>
		</WhiteContainer>
	);
});

MapFilters.displayName = 'MapFilters';
