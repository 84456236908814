import { createModel } from '@rematch/core';
import { IBookingPageData } from '@main/src/types/booking/page';

const initialState: Partial<IBookingPageData> = {
	cardDetail: null,
	isError: false,
};

export const booking = createModel()({
	state: initialState,
});
