import { useMemo } from 'react';
import { useFormState } from 'react-final-form';

type DirtyValues = {
	[key: string]: any;
};

export const useDirtyValues = () => {
	const { values, dirtyFields } = useFormState();

	return useMemo<DirtyValues>(() => {
		return Object.keys(dirtyFields).reduce((dirtyValues: DirtyValues, key) => {
			if (key in values) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				dirtyValues[key] = values[key];
			}

			return dirtyValues;
		}, {});
	}, [dirtyFields, values]);
};
