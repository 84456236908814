import { FC, useMemo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { IconUsersAlt } from '@new/components/icon';
import { ILocalStorageGuestsState } from '@new/pages/BookingHotel/types';

import { HotelFilterGuests } from './components/HotelFilterGuests';
import { FilterInput } from './../FilterInput';

import cls from './GuestsInput.module.css';

interface ICityInputProps {
	className?: string;
	bookGuestsState: ILocalStorageGuestsState;
	onChangeAdult(roomIndex: number): (value: number) => void;
	onChangeChild(roomIndex: number): (value: number) => void;
	onChangeChildAge(roomIndex: number, childIndex: number): (value: number) => void;
	onAddRoom(): void;
	onRemoveRoom(roomIndex: number): () => void;
	trigger?: 'hover' | 'focus' | 'click';
	withOverlay?: boolean;
}

export const GuestsInput: FC<ICityInputProps> = props => {
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const {
		className,
		// eslint-disable-next-line @typescript-eslint/unbound-method
		onChangeAdult,
		// eslint-disable-next-line @typescript-eslint/unbound-method
		onChangeChild,
		// eslint-disable-next-line @typescript-eslint/unbound-method
		onChangeChildAge,
		// eslint-disable-next-line @typescript-eslint/unbound-method
		onAddRoom,
		// eslint-disable-next-line @typescript-eslint/unbound-method
		onRemoveRoom,
		bookGuestsState,
		trigger = 'hover',
		withOverlay,
	} = props;
	const [visible, setVisible] = useState(false);
	const uiTranslations = useTranslation('ui');
	const hotelTranslations = useTranslation('api_hotel');

	const content = useMemo(() => {
		return (
			<HotelFilterGuests
				rooms={bookGuestsState.rooms}
				onChangeAdult={onChangeAdult}
				onChangeChild={onChangeChild}
				onChangeChildAge={onChangeChildAge}
				onAddRoom={onAddRoom}
				onRemoveRoom={onRemoveRoom}
			/>
		);
	}, [bookGuestsState]);

	const guestsCount = useMemo(() => {
		const guestsCount = bookGuestsState.rooms.reduce(
			(acc, item) => {
				return {
					adults: acc.adults + item.adults,
					children: acc.children + item.children.length,
				};
			},
			{ adults: 0, children: 0 }
		);

		return `${uiTranslations.t('_filter.filters.guest', {
			count: guestsCount.adults + guestsCount.children,
		})}, ${hotelTranslations.t('search.count_rooms', { count: bookGuestsState.rooms.length })}`;
	}, [bookGuestsState]);

	return (
		<FilterInput
			visible={visible}
			content={content}
			changeVisible={setVisible}
			className={cls.GuestInput}
			trigger={trigger}
			withOverlay={withOverlay}
		>
			<IconUsersAlt />
			<input value={guestsCount} disabled={true} />
		</FilterInput>
	);
};
