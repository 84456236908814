import React, { memo, useCallback, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

import { WhiteContainer } from '@new/components/WhiteContainer';
import { Flex, Button } from '@main/src/new/components/ui';
import { ICommonData, IUserLocation } from '@main/src/types/common';
import { DEFAULT_LOCALE } from '@app/constants/defaults';
import { DateRangeInput } from '@new/layouts/components/Header/components/Filters/DateRangeInput';
import { GuestsInput } from '@new/layouts/components/Header/components/Filters/GuestsInput';
import { useHotelGuestState } from '@common/hooks';
import { IRoom } from '@main/src/new/pages/BookingHotel/types';
import { useLoginGeoState } from '@common/hooks/useLoginGeoState';
import { getCurrentUserLocation } from '@common/helpers/filters/getCurrentUserLocation';
import { useSearchState } from '@common/hooks/useSearchState';

import { CityInput } from './CityInput';
import { SelectType } from './SelectType';

import cls from './Filters.module.css';

export interface IFiltersProps {
	className?: string;
	type?: string;
	locationName?: string;
}

export const DEFAULT_CHILDREN_AGE = 7;

export const Filters = memo((props: IFiltersProps) => {
	const { className, type, locationName } = props;
	const { t } = useTranslation('ui');

	const locale = useSelector((state: ICommonData) => state.locale);
	const { bookGuestsState, setGuestsState } = useHotelGuestState();
	const { loginGeo } = useLoginGeoState();
	const [lastSearch] = useSearchState();

	const currentUserLocation: IUserLocation = getCurrentUserLocation({
		locale: locale?.code || DEFAULT_LOCALE,
		geo: lastSearch
			? {
					lat: lastSearch.location[0],
					lng: lastSearch.location[1],
					lat6: lastSearch.location[0] * 1000000,
					lon6: lastSearch.location[1] * 1000000,
					cityName: [lastSearch.title],
					cc: locale?.code || DEFAULT_LOCALE,
			  }
			: loginGeo,
		locationName,
		bbox: lastSearch?.bbox,
	});

	const [currentType, setCurrentType] = useState<string | null>(type || '');
	const [currentCity, setCurrentCity] = useState<IUserLocation>(currentUserLocation);
	const router = useRouter();

	const handlerChangeAdult = useCallback(
		(roomIndex: number) => (value: number) => {
			const cloneRooms = [...bookGuestsState.rooms];
			cloneRooms[roomIndex].adults = value;

			setGuestsState({
				...bookGuestsState,
				rooms: cloneRooms,
			});
		},
		[bookGuestsState]
	);
	const handlerChangeChild = useCallback(
		(roomIndex: number) => (value: number) => {
			const cloneRooms = [...bookGuestsState.rooms];
			const cloneChildren = [...cloneRooms[roomIndex].children];

			if (cloneChildren.length >= value) {
				cloneRooms[roomIndex].children = cloneChildren.slice(0, value);
			} else if (value === cloneChildren.length + 1) {
				cloneRooms[roomIndex].children = [...cloneChildren, ...[DEFAULT_CHILDREN_AGE]];
			}

			setGuestsState({
				...bookGuestsState,
				rooms: cloneRooms,
			});
		},
		[bookGuestsState]
	);
	const handlerChangeChildAge = useCallback(
		(roomIndex: number, childIndex: number) => (value: number) => {
			const cloneRooms = [...bookGuestsState.rooms];
			cloneRooms[roomIndex].children[childIndex] = value;

			setGuestsState({
				...bookGuestsState,
				rooms: cloneRooms,
			});
		},
		[bookGuestsState]
	);
	const handlerAddRoom = useCallback(() => {
		setGuestsState({
			...bookGuestsState,
			rooms: [...bookGuestsState.rooms, ...[{ adults: 2, children: [] }]],
		});
	}, [bookGuestsState]);
	const handlerRemoveRoom = useCallback(
		(index: number) => () => {
			const nextRooms = bookGuestsState.rooms
				.map((i, indexR) => {
					if (index === indexR) {
						return null;
					}

					return i;
				})
				.filter(f => !!f) as IRoom[];
			setGuestsState({
				...bookGuestsState,
				rooms: nextRooms,
			});
		},
		[bookGuestsState]
	);

	const handleClick = useCallback(async () => {
		let nextUrl = `/${locale?.code || DEFAULT_LOCALE}/search?zoom=12`;
		if (currentType) {
			nextUrl += `&type=${currentType}`;
		}

		if (currentCity?.bbox) {
			nextUrl += `&bounds=${currentCity.bbox.flat().join(',')}`;
		}
		if (currentCity?.point && currentCity.point.length === 2) {
			if (currentUserLocation?.point && currentUserLocation.point.length === 2) {
				nextUrl += `&center=${currentUserLocation.point[0]},${currentUserLocation.point[1]}`;
				if (currentUserLocation?.city.display_name) {
					const splitName = currentUserLocation.city.display_name.split(',');
					nextUrl += `&locationName=${splitName[0]}`;
				}
			}
			// TODO dirty hack for fast fix bug default city
			else if (currentCity.point[0] && currentCity.point[1]) {
				nextUrl += `&center=${currentCity.point[0]},${currentCity.point[1]}`;

				if (currentCity?.city.display_name) {
					const splitName = currentCity.city.display_name.split(',');
					nextUrl += `&locationName=${splitName[0]}`;
				}
			}
		}

		await router.push(encodeURI(nextUrl));
	}, [currentCity, currentType, router, locale, currentUserLocation]);

	const hasShowHotelFilters = currentType === 'accommodation';

	return (
		<WhiteContainer border='6px' className={classNames(className, cls.Filters)}>
			<Flex justifyContent='between'>
				<Flex gap='12px'>
					<CityInput cityName={currentCity.city.display_name} onChange={setCurrentCity} />
					<SelectType onChange={setCurrentType} value={currentType} />
					{hasShowHotelFilters && <DateRangeInput />}
					{hasShowHotelFilters && (
						<GuestsInput
							bookGuestsState={bookGuestsState}
							onChangeAdult={handlerChangeAdult}
							onChangeChild={handlerChangeChild}
							onChangeChildAge={handlerChangeChildAge}
							onAddRoom={handlerAddRoom}
							onRemoveRoom={handlerRemoveRoom}
						/>
					)}
				</Flex>
				<Flex gap='16px' className={cls.buttons}>
					<Button outline onClick={handleClick}>
						{t('common.findNow')}
					</Button>
				</Flex>
			</Flex>
		</WhiteContainer>
	);
});
