import React, { memo, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { Input } from '@new/pages/BookingHotel/components/Input';
import { Controller, useFormContext } from 'react-hook-form';
import cls from './ClientData.module.css';
import { ISelectOption, ILocalStorageGuestsState, ILocalStorageState } from '@new/pages/BookingHotel/types';
import API_COUNTRY_CODES from '@shared/taxonomy/cmn/country.json';
import { DEFAULT_LOCALE } from '@app/constants/defaults';
import { useSelector } from 'react-redux';
import { ICommonData } from '@main/src/types/common';
import dayjs from 'dayjs';
import { RootState } from '@app/stores/pages/booking';
import { SelectBooking } from '../../../SelectBooking';
import { useAuthContext } from '@main/src/new/auth';
import { PhoneInput } from '@main/src/new/components/ui/PhoneInput';

interface IClientDataProps {
	className?: string;
	bookState: ILocalStorageState;
	bookGuestsState: ILocalStorageGuestsState;
}

export const genTimeSlots = (
	locale: string,
	checkInDate: Date,
	defaultValue: ISelectOption,
	checkInHours: number,
	checkOutHours: number
): ISelectOption[] => {
	const result: ISelectOption[] = [defaultValue];
	const startDate = dayjs(checkInDate);
	let currentDate = dayjs(startDate).add(checkInHours, 'hours');
	const endDate = startDate.add(1, 'days').add(checkOutHours, 'hours');
	while (currentDate <= endDate) {
		const customFormat = endDate.diff(currentDate, 'hours') > checkOutHours ? 'HH:mm' : 'DD MMM HH:mm';
		result.push({
			value: currentDate.format('YYYY-MM-DDTHH:mm:00.000') + 'Z',
			label: currentDate.locale(locale).format(customFormat),
		});
		currentDate = currentDate.add(30, 'minutes');
	}

	return result;
};

export const ClientData = memo((props: IClientDataProps) => {
	const { className, bookState, bookGuestsState } = props;
	const { control } = useFormContext();
	const { locale } = useSelector((state: ICommonData) => state);
	const { cardDetail } = useSelector((state: RootState) => state.booking);
	const currentLocaleCode = locale?.code || DEFAULT_LOCALE;
	const hotelTranslation = useTranslation('api_hotel');
	const cmnTranslations = useTranslation('api_cmn');
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { getHasAnonymous } = useAuthContext();
	const isDisable = getHasAnonymous();

	const currentResidencyCountry = useMemo(() => {
		const country = Object.keys(API_COUNTRY_CODES)
			.map(cc => ({
				value: cc,
				label: cmnTranslations.t(`country.${cc}.name`),
			}))
			.find(f => f.value.toLowerCase().startsWith(bookState.info?.residency?.toLowerCase() || ''));

		return country;
	}, [bookState]);

	const listCountries = useMemo(() => {
		const codes = Object.keys(API_COUNTRY_CODES)
			.map(cc => ({
				value: cc,
				label: cmnTranslations.t(`country.${cc}.name`),
			}))
			.sort((a, b) => {
				return a.label.localeCompare(b.label, currentLocaleCode);
			});
		// .filter(f => f.label.toLowerCase().startsWith(searchValue?.toLowerCase() || ''))
		// if (!searchValue || searchValue === '') {
		// 	codes.unshift({
		// 		value: 'NOT_SET',
		// 		label: hotelTranslations.t('booking.not_specified'),
		// 	});
		// }

		return [...[{ label: hotelTranslation.t('booking.not_specified'), value: 'booking.not_specified' }], ...codes];
	}, []);

	const [checkInHours, checkInMinutes] = cardDetail?.ostrovok?.checkInTime.split(':') || [];
	const checkinDate = dayjs(bookGuestsState.fromDate).toDate();
	const checkInStartHours = checkInHours ? parseInt(checkInHours, 10) : 14;

	const [checkOutHours, checkOutMinutes] = cardDetail?.ostrovok?.checkOutTime.split(':') || [];
	const checkOutDate = dayjs(bookGuestsState.toDate).toDate();
	const checkOutStartHours = checkOutDate ? parseInt(checkOutHours, 10) : 14;

	const timeSlots = useMemo(() => {
		return genTimeSlots(
			currentLocaleCode,
			checkinDate,
			{ label: hotelTranslation.t('booking.not_specified'), value: 'booking.not_specified' },
			checkInStartHours,
			checkOutStartHours
		);
	}, [checkinDate]);

	return (
		<>
			<Controller
				name='email'
				control={control}
				render={({ field, fieldState }) => {
					//eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					const value = field.value as string;

					return (
						<Input
							placeholder='exapmle@mail.com'
							label={hotelTranslation.t('booking.email')}
							required={true}
							value={value}
							onChange={field.onChange}
							invalid={fieldState.invalid}
							disabled={isDisable}
						/>
					);
				}}
				rules={{
					required: true,
					pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
				}}
			/>
			<Controller
				name='phone'
				control={control}
				render={({ field, fieldState }) => {
					const value = field.value as string;
					return (
						<div>
							<p className={cls.phoneInputLabel}>{hotelTranslation.t('booking.phone_number')}</p>
							<PhoneInput
								value={value}
								onChange={field.onChange}
								placeholder='+1 234 567-89-01'
								required={true}
								invalid={fieldState.invalid}
								disabled={isDisable}
								className={cls.phoneInput}
								name='phone'
							/>
						</div>
					);
				}}
				rules={{
					required: true,
				}}
			/>
			<Controller
				name='residency'
				control={control}
				render={({ field, fieldState }) => {
					//eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					const value = field.value as string;

					return (
						<SelectBooking
							label={hotelTranslation.t('booking.citizenship')}
							options={listCountries}
							value={value}
							onChange={field.onChange}
							labelPopupContent={
								<span className={cls.toolTip}>
									<p>{hotelTranslation.t('booking.citizenship_help')}</p>
								</span>
							}
							disabled={isDisable}
						/>
					);
				}}
				rules={{
					required: false,
				}}
			/>
			<Controller
				name='arrivalDateTime'
				control={control}
				render={({ field, fieldState }) => {
					//eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					const value = field.value as string;

					return (
						<SelectBooking
							label={hotelTranslation.t('booking.arrival_time')}
							options={timeSlots}
							value={value}
							onChange={field.onChange}
							disabled={isDisable}
						/>
					);
				}}
				rules={{
					required: false,
				}}
			/>
			{/*<InputText*/}
			{/*	placeholder='IVAN'*/}
			{/*	label={`${hotelTranslation.t('booking.first_name')} (${hotelTranslation.t('booking.name_help')})`}*/}
			{/*	name='firstName'*/}
			{/*	required={true}*/}
			{/*	validationMin={2}*/}
			{/*/>*/}
			{/*<InputText*/}
			{/*	placeholder='IVANOV'*/}
			{/*	label={`${hotelTranslation.t('booking.last_name')} (${hotelTranslation.t('booking.name_help')})`}*/}
			{/*	name='lastName'*/}
			{/*	required={true}*/}
			{/*	validationMin={2}*/}
			{/*/>*/}
		</>
	);
});

ClientData.displayName = 'ClientData';
