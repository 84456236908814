import React, { memo, useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import queryString from 'query-string';
import { debounce } from 'throttle-debounce';
import { useRouter } from 'next/router';

import { Flex } from '@main/src/new/components/ui';
import { IconArrowBlueDown, IconGlobe, IconList } from '@new/components/icon';
import { IUserLocation } from '@main/src/types/common';
import { ESearchType, TTypePageContent } from '@main/src/types/pages/search';
import { useSearchState } from '@common/hooks/useSearchState';
import { useUserLocation } from '@common/hooks/useUserLocation';

import { Navigate } from './components/Navigate';
import { Filters } from './components/Filters';
import { FiltersTypeMain } from './components/FiltersTypeMain';
import { SearchInput } from './components/SearchInput';
import { FiltersModal } from './components/FiltersModal';

import cls from './Header.module.css';
import { ISearch } from '@main/src/types/map/searchArea';
import { BrowserHistory } from 'history';

export type TPage = 'index' | 'map' | 'any';

export interface IHeaderProps {
	filters?: React.ReactElement;
	locationName?: string;
	backRouter?: () => void;
	page?: TPage;
	userLocation?: IUserLocation;
	typePageContent?: TTypePageContent;
	setTypePageContent?: (value: TTypePageContent) => void;
	params?: string[];
	withTitle?: boolean;
	result?: ISearch;
	setSelectType?: (type: ESearchType) => void;
	browserHistory?: BrowserHistory;
	changeParams?: (value: string[]) => () => void;
	currentType?: ESearchType;
}

export const Header = memo(
	({
		filters,
		locationName,
		backRouter,
		page = 'index',
		userLocation,
		typePageContent,
		setTypePageContent,
		params,
		withTitle,
		result,
		setSelectType,
		browserHistory,
		changeParams,
		currentType,
	}: IHeaderProps) => {
		const router = useRouter();
		const [lastSearch] = useSearchState();
		const lastSearchTitle = lastSearch ? lastSearch?.title.split(',')[0] : '';
		const [hasSearchOpened, setSearchOpened] = useState<boolean>(false);
		const { city, setCity, currentUserLocation } = useUserLocation({ locationName });
		const [currentLocation, setCurrentLocation] = useState<IUserLocation>(currentUserLocation);
		const urlType = router.query.type as string;
		const [type, setType] = useState<ESearchType>(currentType || (urlType as ESearchType) || ESearchType.any);

		useEffect(() => {
			currentType && setType(currentType);
		}, [currentType]);

		const onChangeType = useCallback(
			(nextType: ESearchType) => () => {
				debounce(100, () => {
					setType(nextType);
					setSelectType?.(nextType);
				})();
			},
			[]
		);

		const filterView = filters ?? <Filters locationName={locationName} />;

		const searchParams = userLocation?.point
			? `?${queryString.stringify(
					{
						// bounds: bounds.bounds,
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
						center: lastSearch?.location || userLocation.point,
						zoom: 12,
						// tags: params,
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						locationName: lastSearchTitle || userLocation.city.display_name,
						type,
					},
					{ arrayFormat: 'comma' }
			  )}`
			: '';

		const handlerTypePageClick = useCallback(() => {
			const nextType = typePageContent === 'map' ? 'list' : 'map';
			setTypePageContent?.(nextType);
		}, [typePageContent]);

		return (
			<>
				<header className={cls.Header}>
					<Navigate />
					<Flex alignItems='center' justifyContent='around' className={cls.navCenter} maxWidth>
						{backRouter && (
							<div onClick={backRouter} className={classNames(cls.navBtn, cls.navBtnCircle)}>
								<IconArrowBlueDown className={classNames(cls.navBtnIcon, cls.navBtnIconBack)} />
							</div>
						)}
						<SearchInput
							onClick={() => setSearchOpened(true)}
							locationName={locationName}
							type={type}
							params={params}
						/>
						{page === 'index' && (
							<Link href={`/search${searchParams}`} className={classNames(cls.navBtn, cls.navBtnCircle)}>
								<IconGlobe className={cls.navBtnIcon} />
							</Link>
						)}
						{page === 'map' && (
							<div className={classNames(cls.navBtn, cls.navBtnCircle)} onClick={handlerTypePageClick}>
								{typePageContent === 'map' && <IconList className={cls.navBtnIcon} />}
								{typePageContent === 'list' && <IconGlobe className={cls.navBtnIcon} />}
							</div>
						)}
					</Flex>
					{filterView}
					{withTitle && <FiltersTypeMain onChangeType={onChangeType} openFiltersModal={() => setSearchOpened(true)} />}
				</header>
				<FiltersModal
					closeModal={() => setSearchOpened(false)}
					city={city}
					onCityChange={setCity}
					type={currentType || type}
					onChangeType={onChangeType}
					currentLocation={currentLocation}
					onChangeCurrentLocation={setCurrentLocation}
					hasSearchOpened={hasSearchOpened}
					result={result}
					browserHistory={browserHistory}
					setParams={changeParams}
				/>
			</>
		);
	}
);

Header.displayName = 'Header';
