export const apps = [
	{
		src: '/statics/new/apps/AppGallery.png',
		href: 'https://appgallery.huawei.com/app/C107116213',
	},
	{
		src: '/statics/new/apps/AppStore.png',
		href: 'https://apps.apple.com/ru/app/id1644404139',
	},
	{
		src: '/statics/new/apps/GooglePlay.png',
		href: 'https://play.google.com/store/apps/details?id=com.qvedo.qvedoapp',
	},
	{
		src: '/statics/new/apps/RuStore.png',
		href: 'https://apps.rustore.ru/app/com.qvedo.qvedoapp',
	},
];
