import { getBrowserHttpClient } from '@app/api/browserHttpApi';
import { captureException } from '@sentry/core';
import { TEtgStatusError } from '../etgStatus/type';
import { TEtgFinishError, TEtgFinishOstorovokError, TEtgFinishParams } from './types';

export const fetchOrderEtgFinish = async ({
	visitorSession,
	order_id,
	payment_type,
}: TEtgFinishParams): Promise<boolean | TEtgFinishError | TEtgFinishOstorovokError> => {
	const api = await getBrowserHttpClient();
	try {
		const result = await api.post<unknown, OrderUsrEtgFinishReq>(
			'/client/order/etg-finish',
			{
				order_id,
				payment_type,
			},
			{
				headers: {
					'x-visitor-ses': visitorSession,
				},
			}
		);

		return true;
	} catch (error) {
		captureException(error);
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (error?.status === 412) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			const errors = error?.data?.errors as string[];

			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return errors[0] as TEtgStatusError;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
		} else if (error?.status === 422) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			const errors = error?.data?.errors as string[];
			return errors[0] as TEtgStatusError;
		}

		return 'unknown error';
	}
};
