import classNames from 'classnames';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import queryString from 'query-string';
import { BrowserHistory } from 'history';
import useTranslation from 'next-translate/useTranslation';

import { getFilterConfig } from '@shared/filters';
import { ESearchType } from '@main/src/types/pages/search';
import { IUserLocation } from '@main/src/types/common';
import { ISearch } from '@main/src/types/map/searchArea';

import { useSearchState } from '@common/hooks/useSearchState';
import { GroupFilters } from './components/GroupFilters';
import { MobileModal } from '@main/src/new/components/ui/MobileModal';
import { IconCross } from '@main/src/new/old/Icons';
import { Flex } from '@main/src/new/components/ui';
import { useUserLocation } from '@common/hooks/useUserLocation';
import { MobileButton } from '@main/src/new/components/ui/MobileButton';
import { IconLoopWhite } from '@main/src/new/components/icon';
import { convertTypeToGlobMapCardType } from '@app/api/services/shared/serp/mapper';
import { CAT_TAGS } from '../Filters/FiltersInput/constants';

import { FiltersType } from '../FiltersType';
import { FiltersModalPreview } from './components/FiltersModalPreview';
import { SearchCity } from './components/SearchCity';

import cls from './FiltersModal.module.css';
import { useAuthContext } from '@main/src/new/auth';

interface IFiltersProps {
	className?: string;
	closeModal: () => void;
	city: string;
	onCityChange(nextValue: string): void;
	type: ESearchType;
	onChangeType(type: ESearchType | ''): () => void;
	currentLocation: IUserLocation;
	onChangeCurrentLocation(nextValue: IUserLocation): void;
	browserHistory?: BrowserHistory;
	params?: string[];
	setParams?: (e: string[]) => void;
	result?: ISearch;
	hasSearchOpened: boolean;
}

export const FiltersModal = memo(
	({
		className,
		closeModal,
		// city,
		// eslint-disable-next-line @typescript-eslint/unbound-method
		onCityChange,
		type,
		// eslint-disable-next-line @typescript-eslint/unbound-method
		onChangeType,
		currentLocation,
		// eslint-disable-next-line @typescript-eslint/unbound-method
		onChangeCurrentLocation,
		browserHistory,
		params,
		// eslint-disable-next-line @typescript-eslint/unbound-method
		setParams,
		result,
		hasSearchOpened,
	}: IFiltersProps) => {
		const router = useRouter();

		const { city, currentUserLocation } = useUserLocation({});

		const [lastSearch] = useSearchState();
		const lastSearchTitle = lastSearch ? lastSearch?.title.split(',')[0] : '';
		const [localCity, setLocalCityValue] = useState(lastSearchTitle || city);
		const tagsTranslations = useTranslation('api_cat');
		const { t: apiCmnTranslations } = useTranslation('api_cmn');
		const { t } = useTranslation('ui');
		const [localParams, changeLocalParams] = useState(params);
		const [localType, setLocalType] = useState<ESearchType | ''>(type);
		const [hasShowLocations, setHasShowLocations] = useState<boolean>(false);
		const getCountryName = (cc?: string | null) => (cc ? apiCmnTranslations(`country.${cc}.name`) : '');
		const [countryName, setCountryName] = useState(
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			getCountryName(lastSearch ? (lastSearch.cc as string) : currentUserLocation.city.country_code)
		);
		const { clientWidth } = useAuthContext();
		const isMobile = Number(clientWidth) < 1001;

		useEffect(() => {
			if (!isMobile) closeModal();
		}, [isMobile]);

		useEffect(() => {
			setCountryName(
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				getCountryName(lastSearch ? (lastSearch.cc as string) : currentUserLocation.city.country_code)
			);
		}, [lastSearch, currentUserLocation]);

		useEffect(() => {
			if (type !== localType) {
				setParams?.([]);
				changeLocalParams([]);
			}
		}, [type, localType]);

		useEffect(() => {
			setLocalType(type);
		}, [type]);

		useEffect(() => {
			setLocalCityValue(lastSearchTitle || city);
		}, [city, lastSearchTitle]);

		useEffect(() => {
			changeLocalParams(params);
		}, [params]);

		const handleChangeLocalParams = React.useCallback(
			(value: string) => () => {
				let nextParams: string[] = [];
				if (localParams?.includes(value)) {
					nextParams = localParams.filter(f => f !== value);
				} else {
					nextParams = [...(localParams || []), value];
				}
				changeLocalParams(nextParams);
			},
			[localParams, changeLocalParams]
		);

		const handleFilterClear = useCallback(() => {
			onCityChange('');
			setLocalCityValue('');
			onChangeType(ESearchType.any)();
			setParams && setParams([]);
			changeLocalParams([]);
			closeModal();
		}, []);

		const handlerOpenMap = useCallback(() => {
			onChangeType(localType)();
			const queryStringParams = queryString.stringify(
				{
					bounds: currentLocation.bbox,
					center:
						lastSearch?.location ||
						(currentLocation.bbox ? undefined : [currentLocation.point[0], currentLocation.point[1]]),
					zoom: 12,
					tags: localParams,
					locationName: localCity,
					type: localType,
					typePage: isMobile ? 'list' : 'map',
				},
				{ arrayFormat: 'comma' }
			);

			if (browserHistory) {
				setParams && setParams(localParams || []);
				browserHistory.replace({
					pathname: 'search',
					search: queryStringParams,
				});
				closeModal();
			} else {
				void router.replace({
					pathname: '/search',
					query: queryStringParams,
				});
			}
		}, [router, currentLocation, localCity, localType, browserHistory, setParams, localParams, lastSearch]);

		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		const filterBlocks = useMemo(() => {
			if (result) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				const preparedType = convertTypeToGlobMapCardType(localType as string);
				if (preparedType) {
					const filtersByType = getFilterConfig(preparedType, {});
					const catTagsSorted = CAT_TAGS[preparedType];

					if (result.tagGroups.length === 0) {
						return <p className={cls.empty}>{t('search.filters.empty')}</p>;
					}

					return catTagsSorted.map((category, index) => {
						// eslint-disable-next-line @typescript-eslint/ban-ts-comment
						// @ts-ignore
						// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
						const currentCategory = filtersByType.filters[category];
						// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-argument
						const currentFilters = Object.entries(currentCategory.values).map(([key, value]) => {
							// eslint-disable-next-line @typescript-eslint/ban-ts-comment
							// @ts-ignore
							// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
							return {
								key,
								value: (value as { t: string }).t,
								count: result.tags[key] || 0,
							};
						});

						if (currentCategory) {
							return (
								<GroupFilters
									key={index}
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
									title={currentCategory.title.t}
									// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
									tags={currentFilters}
									type={localType}
									params={localParams}
									onChangeLocalParams={handleChangeLocalParams}
								/>
							);
						}
					});
				}
			}

			return <p className={cls.empty}>{t('search.filters.empty')}</p>;
		}, [handleChangeLocalParams, localParams, result, localType, tagsTranslations, t]);

		return (
			<MobileModal isOpen={hasSearchOpened} top='15%' close={closeModal} type='drawer' zIndex={998}>
				<div className={classNames(cls.Filters, className)}>
					<IconCross className={cls.close} onClick={closeModal} />
					<Flex
						gap='20px'
						direction='column'
						className={classNames(cls.selectType, hasShowLocations && cls.selectTypeActive)}
						maxWidth
					>
						<SearchCity
							className={cls.input}
							value={localCity}
							onChange={setLocalCityValue}
							onChangeCurrentLocation={nextValue => {
								setCountryName(getCountryName(nextValue.city.country_code));
								onChangeCurrentLocation(nextValue);
							}}
							hasSearchOpened={hasSearchOpened}
							countryName={countryName}
							hasShowLocations={hasShowLocations}
							setHasShowLocations={setHasShowLocations}
						/>
					</Flex>
					<Flex direction='column' gap='14px' maxWidth className={cls.content}>
						<FiltersType onChangeType={type => () => setLocalType(type)} type={localType} className={cls.selectType} />

						{localType === 'accommodation' && <FiltersModalPreview />}

						{(localType === 'any' || !localType) && <p className={cls.empty}>{t('search.emptyFilters')}</p>}

						{browserHistory && (
							<Flex gap='14px' direction='column' className={cls.filterBlocks}>
								{filterBlocks}
							</Flex>
						)}
					</Flex>
					<Flex className={cls.buttons} gap='10px' justifyContent='flex-center' maxWidth>
						<MobileButton theme='white' onClick={handleFilterClear} className={cls.clear}>
							{t('common.filtersModal.btns.reset')}
						</MobileButton>
						<MobileButton onClick={handlerOpenMap} className={cls.searchButton}>
							<IconLoopWhite />
							{t('common.filtersModal.btns.show')}
						</MobileButton>
					</Flex>
				</div>
			</MobileModal>
		);
	}
);
