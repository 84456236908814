import { makeContext } from '@common/utils/makeContext';
import { ICardResultV2, IPoint, ISearch } from '../../../types/map/searchArea';
import { RefObject } from 'react';
import { BrowserHistory } from 'history';
import { ESearchType, TTypePageContent } from '@main/src/types/pages/search';

export enum EResult {
	initial = 'initial',
	loading = 'loading',
	success = 'success',
	error = 'error',
}

export interface IMapBbox {
	tl: YandexMap.TCoord;
	tr: YandexMap.TCoord;
	bl: YandexMap.TCoord;
	br: YandexMap.TCoord;
}

export interface IBounds {
	center: YandexMap.TCoord;
	zoom: number;
	precision: number;
	bounds?: [YandexMap.TCoord, YandexMap.TCoord];
	activePoint?: IPoint | null;
	bbox?: IMapBbox;
}

export interface IMapSearchParams {
	[key: string]: string[];
}

export interface IMapContext {
	bounds: IBounds;
	result: ISearch;
	resultStatus: EResult;
	resultPage: number;
	// TODO fix type TPoint -> IPoint
	activePoint?: IPoint;
	setBounds(bounds: IBounds): void;
	setResult(result: ISearch): void;
	setActivePoint(point: IPoint | undefined): void;
	setResultStatus(status: EResult): void;
	setResultPage(page: number): void;
	listRef: RefObject<HTMLDivElement> | null;
	history?: BrowserHistory;
	params: string[];
	changeParams(value: string[]): () => void;
	cards: ICardResultV2[];
	setCards(cards: ICardResultV2[]): void;
	activeCards: ICardResultV2[];
	setActiveCards(cards: ICardResultV2[]): void;
	selectType: ESearchType;
	setSelectType(type: ESearchType): void;
	activeResultStatus: EResult;
	setActiveResultStatus(status: EResult): void;
	// TODO move to center
	locationCenter: [number, number];
	setLocationCenter(center: [number, number]): void;
	currentCenter: [number, number];
	setCurrentCenter(center: [number, number]): void;
	locationName: string;
	setLocationName(name: string): void;
	currentZoom: number;
	setCurrentZoom(zoom: number): void;
	mapSession: string;
	typePageContent: TTypePageContent;
	setTypePageContent(zoom: TTypePageContent): void;
	hasOutOfRange: boolean;
	setHasOutOfRange(v: boolean): void;
}

export const {
	Context: MapContext,
	useContext: useMapContext,
	withContext: withMapContext,
} = makeContext<IMapContext>({
	contextName: 'MapContext',
});
