import { combineCommonModels } from '@app/stores/helpers';
import { init, RematchStore } from '@rematch/core';
import { ICommonData } from 'src/types/common';
import { RootModel } from './types';
import { booking } from '@app/stores/models/booking';
import { IBookingPageData } from '@main/src/types/booking/page';

export const models = combineCommonModels<RootModel>({ booking });

export const makeStore = ({
	common,
	booking,
}: {
	common: ICommonData;
	booking: IBookingPageData;
}): RematchStore<RootModel, RootModel> => {
	const state = init<RootModel>({ models }).getState();

	return init<RootModel>({
		models,
		redux: { initialState: { ...state, ...common, booking } },
	});
};
