import React, { memo } from 'react';
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';

import { Flex } from '@main/src/new/components/ui';
import { useAuthContext } from '@main/src/new/auth';
import { PasswordLess } from '@new/pages/BookingHotel/components/BookingAuthBlock/components/Passwordless/Passwordless';
import { useLoginGeoState } from '@common/hooks/useLoginGeoState';
import { IconLogoFullBlue } from '@main/src/new/components/icon';
import { IconArrowDown } from '@main/src/new/old/Icons';

import { makeOAuthConfig } from './configProviders';
import { useLogin } from './useLogin';
import { AuthDocs } from './components/AuthDocs';

import cls from './BookingAuthBlock.module.css';

interface IBookingAuthBlockProps {
	className?: string;
	onClickBack?: () => void;
}

export const BookingAuthBlock = memo(({ className, onClickBack }: IBookingAuthBlockProps) => {
	const { t } = useTranslation('ui');
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { login, sendPasswordlessCode } = useAuthContext();
	const { loginGeo } = useLoginGeoState();
	const { socialButtons, loginStatus, email, loading, error, sendCode, doLogin, onTimerEnd, backToMailForm, setEmail } =
		useLogin({
			oauthConfig: makeOAuthConfig(t, loginGeo.cc),
			login,
			sendPasswordlessCode,
			onSuccess: () => {
				// void router.replace(urlReplace);
			},
		});

	return (
		<div className={classNames(className, cls.BookingAuthBlock, cls.open)}>
			<Flex gap='8px' className={cls.header} alignItems='start' direction='column'>
				{onClickBack && (
					<div className={cls.back} onClick={onClickBack}>
						<IconArrowDown />
					</div>
				)}
				<div className={cls.title}>{t('authPages.auth.title')}</div>
				<div className={cls.desc}>{t('authPages.auth.subtitle')}</div>
			</Flex>
			<Flex className={classNames(cls.content)} alignItems='start' direction='row' justifyContent='flex-start'>
				<Flex gap='10px' className={cls.providers} direction='column'>
					<IconLogoFullBlue className={cls.logoFull} />
					<div className={classNames(cls.title, cls.titleDesc)}>{t('authPages.auth.social')}</div>
					<div className={classNames(cls.title, cls.titleMobile)}>{t('authPages.auth.socialMobile')}</div>
					{socialButtons.map(({ Component, ...rest }, index) => (
						<div key={index} className={cls.social}>
							<Component {...{ ...rest, order: index }} />
						</div>
					))}
				</Flex>
				<div className={cls.delimiter} />
				<PasswordLess
					loginStatus={loginStatus}
					email={email}
					setEmail={setEmail}
					loading={loading}
					error={error}
					sendPasswordLessCode={sendCode}
					login={doLogin}
					onTimerEnd={onTimerEnd}
					backToMailForm={backToMailForm}
				/>
			</Flex>
			<Flex className={cls.docs} direction='column'>
				<AuthDocs />
			</Flex>
		</div>
	);
});
