import React, { useState } from 'react';
import { useTimer } from 'react-timer-hook';
import cls from './Timer.module.css';

interface TimerProps {
	text: string;
	timeInSec: number;
	onExpire: () => void;
	restart?: () => void;
}

const curTimePlusNSec = (sec: number) => {
	const time = new Date();
	time.setSeconds(time.getSeconds() + sec);
	return time;
};

const pretifyTime = (val: number) => {
	if (String(val).length > 1) return val;
	return String(val).padStart(2, '0');
};

export const Timer = ({ text, timeInSec, onExpire }: TimerProps) => {
	const [expiryTimestamp] = useState(curTimePlusNSec(timeInSec));

	const { seconds, minutes } = useTimer({
		expiryTimestamp,
		onExpire,
	});

	return (
		<div className={cls.timer}>
			{text} {`${pretifyTime(minutes)}:${pretifyTime(seconds)}`}
		</div>
	);
};
