import classNames from 'classnames';
import React, { memo } from 'react';
import cls from './Footer.module.css';
import Image from 'next/image';
import Link from 'next/link';
import { apps, partners } from '@new/app/constants';
import { SOCIALS } from '@new/app/constants/socials';
import useTranslation from 'next-translate/useTranslation';
import { IconLongBlue } from '@new/components/icon';
import { Flex } from '@new/components/ui';

interface IFooterProps {
	className?: string;
}

export const Footer = memo((props: IFooterProps) => {
	const { className } = props;
	const { t } = useTranslation('ui');

	return (
		<section className={classNames(className, cls.Footer)}>
			<Flex alignItems='start' justifyContent='between'>
				<Flex className={cls.left} direction='column' justifyContent='between'>
					<div className={cls.desc}>
						<IconLongBlue />
						<p>{t('footer.description')}</p>
						<p className={cls.email}>
							{t('footer.mail')}: <Link href='mailto:hello@qvedo.com'>hello@qvedo.com</Link>
						</p>
						<p>
							<br />
							<strong>QVEDO, spletne storitve, d.o.o.</strong>
							<br />
							Ob dolenjski železnici 12, Ljubljana, 1000
						</p>
					</div>
					<Flex className={cls.partners} wrap>
						<Flex alignItems='center' justifyContent='between' maxWidth>
							{partners.map((props, index) => index < 3 && <Image alt='img app' {...props} key={props.src} />)}
						</Flex>
						<Flex alignItems='center' justifyContent='between' maxWidth>
							{partners.map((props, index) => index > 2 && <Image alt='img app' {...props} key={props.src} />)}
						</Flex>
					</Flex>
				</Flex>
				<div className={cls.center}>
					<Flex className={cls.list} direction='column'>
						<p className={cls.title}>{t('footer.company.title')}</p>
						<Link href='/about'>{t('footer.company.about')}</Link>
						<Link href='/blog'>{t('footer.company.blog')}</Link>
						{/* <Link href=''>
							СМИ
						</Link> */}
					</Flex>
					<Flex className={cls.list} direction='column'>
						<p className={cls.title}>{t('footer.suppliers.title')}</p>

						<Link href='/b2b'>{t('footer.suppliers.business')}</Link>
						<Link href='https://my.qvedo.com'>{t('footer.suppliers.enter')}</Link>
						{/* <Link href=''>
								Обучающие материалы
							</Link> */}
					</Flex>
					{/*<Flex className={cls.list} direction='column'>*/}
					{/*	<p className={cls.title}>B2B</p>*/}

					{/*	<Link href=''>*/}
					{/*		<a dangerouslySetInnerHTML={{ __html: t('b2b.regionProfile') }} />*/}
					{/*	</Link>*/}
					{/*	<Link href=''>*/}
					{/*		<a dangerouslySetInnerHTML={{ __html: t('b2b.internationalActivity') }} />*/}
					{/*	</Link>*/}
					{/*</Flex>*/}
					<Flex className={cls.list} direction='column'>
						<p className={cls.title}>{t('footer.documents.title')}</p>
						<Link href='/privacy-policy' dangerouslySetInnerHTML={{ __html: t('footer.documents.police') }}></Link>
						<Link href='/cookie-policy'>{t('footer.documents.cookies')}</Link>
						<Link href='/data-use'>{t('footer.documents.use')}</Link>
						<Link
							href='/user-agreement'
							dangerouslySetInnerHTML={{ __html: t('footer.documents.userAgreement') }}
						></Link>
						<Link
							href='/terms-of-service'
							dangerouslySetInnerHTML={{ __html: t('footer.documents.termsOfService') }}
						></Link>
					</Flex>
				</div>
				<Flex direction='column' justifyContent='between' className={cls.right}>
					<div className={cls.apps}>
						<p className={cls.title}>{t('footer.app.title')}</p>
						<p>{t('footer.app.description')}</p>
						<Flex wrap alignItems='center'>
							{apps.map(({ src, href }) => (
								<Link href={href} key={src} passHref target={'_blank'}>
									<Image src={src} alt='img app' height={38} width={134} />
								</Link>
							))}
						</Flex>
					</div>
					<div className={cls.socials}>
						<p className={cls.title}>{t('footer.socially')}</p>
						<Flex alignItems='center' wrap>
							{SOCIALS.map(({ icon, text, href }) => (
								<Link href={href} key={text} passHref target={'_blank'}>
									<Flex className={cls.social} alignItems='center'>
										<Flex justifyContent='flex-center' alignItems='center'>
											{icon}
										</Flex>{' '}
										<p>{text}</p>
									</Flex>
								</Link>
							))}
						</Flex>
					</div>
				</Flex>
			</Flex>
		</section>
	);
});
