import { notFound } from '@app/stores/models';
import { combineCommonModels } from '@app/stores/helpers';
import { init, RematchStore } from '@rematch/core';

import { RootModel } from './types';

export const models = combineCommonModels<RootModel>({ notFound });

export const makeStore = (): RematchStore<RootModel, RootModel> => {
	const state = init<RootModel>({ models }).getState();

	return init<RootModel>({
		models,
		redux: { initialState: { ...state } },
	});
};
