import React, { memo, useCallback, useMemo } from 'react';
import { useDebounce } from 'usehooks-ts';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import { Translate } from 'next-translate';

import { Flex } from '@main/src/new/components/ui';
import { useSearchCityWord } from '@common/dataContext/countries';
import { EActivityType } from '@main/src/types/entities/activity';
import { IUserLocation } from '@main/src/types/common';
import { useSearchState } from '@common/hooks/useSearchState';
import { useLoginGeoState } from '@common/hooks/useLoginGeoState';
import { IconCross, IconPin } from '@main/src/new/old/Icons';
import { IconMouse } from '@main/src/new/components/icon';
import { MobileInput } from '@main/src/new/components/ui/MobileInput';
import { DEFAULT_LOCALE } from '@app/constants/locales';

import cls from './SearchCity.module.css';

interface ISearchCityProps {
	className?: string;
	value: string;
	onChange(nextValue: string): void;
	onChangeCurrentLocation(nextValue: IUserLocation): void;
	hasSearchOpened: boolean;
	countryName?: string;
	hasShowLocations: boolean;
	setHasShowLocations: (val: boolean) => void;
}

export const getDistance = (dist: number, t: Translate) => {
	let distance: string;

	const distanceKilometers = dist / 1000;

	if (distanceKilometers > 10) {
		distance = t(`common.distance.from_you_kilometers`, {
			distance: distanceKilometers.toFixed(0),
		});
	} else if (distanceKilometers > 1) {
		distance = t(`common.distance.from_you_kilometers`, {
			distance: distanceKilometers.toFixed(0),
		});
	} else {
		distance = t(`common.distance.from_you_meters`, {
			distance: dist.toFixed(0),
		});
	}

	return distance;
};

export const SearchCity = memo((props: ISearchCityProps) => {
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const {
		className,
		value,
		// eslint-disable-next-line @typescript-eslint/unbound-method
		onChange,
		// eslint-disable-next-line @typescript-eslint/unbound-method
		onChangeCurrentLocation,
		hasSearchOpened,
		countryName,
		hasShowLocations,
		setHasShowLocations,
	} = props;
	const { t, lang } = useTranslation('ui');
	const { t: apiCmnTranslations } = useTranslation('api_cmn');
	const [, setLastSearch] = useSearchState();
	const debouncedSearchCity = useDebounce(value, 200);
	const router = useRouter();
	const { loginGeo } = useLoginGeoState();
	const { data, isLoading } = useSearchCityWord(debouncedSearchCity, hasSearchOpened, lang || DEFAULT_LOCALE.code, [
		loginGeo.lat,
		loginGeo.lng,
	]);
	const getCountryName = (cc?: string | null) => (cc ? apiCmnTranslations(`country.${cc}.name`) : '');

	const result = useMemo(() => {
		return {
			cards: data?.cards.map((i, index) => ({ ...i, key: [i.location, i.title, index] })),
			places: data?.places.map((i, index) => ({ ...i, key: [i.location, i.title, index], bbox: i.bbox })),
		};
	}, [data]);

	const handleCityClear = useCallback(() => {
		setHasShowLocations(false);
		onChange('');
		setLastSearch(null);
	}, [onChange, hasShowLocations]);

	const handleChangeObject = useCallback(
		(nextValue: {
				id: string;
				title: string;
				location: [number, number];
				bbox?: [[number, number], [number, number]];
				cc: string | null;
				type: EActivityType;
			}) =>
			() => {
				setHasShowLocations(false);
				onChange(nextValue.title);
				onChangeCurrentLocation({
					city: {
						id: '2',
						country_code: nextValue.cc || '2',
						display_name: nextValue.title,
					},
					point: nextValue.location,
					bbox: nextValue.bbox,
				});
				setLastSearch(nextValue);
				const urlType = nextValue.type === 'accommodation' ? 'hotel' : nextValue.type;
				const linkToCard = `/${urlType}/${nextValue.id}`;
				void router.push(linkToCard);
			},
		[onChange, onChangeCurrentLocation]
	);

	const handleChangeLocation = useCallback(
		(nextValue: {
				title: string;
				location: [number, number];
				bbox?: [[number, number], [number, number]];
				cc: string | null;
			}) =>
			() => {
				setHasShowLocations(false);
				onChange(nextValue.title);
				onChangeCurrentLocation({
					city: {
						id: '2',
						country_code: nextValue.cc || '2',
						display_name: nextValue.title,
					},
					point: nextValue.location,
					bbox: nextValue.bbox,
				});
				setLastSearch(nextValue);
			},
		[onChange, onChangeCurrentLocation]
	);

	const handleChangeCity = useCallback(
		(nextValue: string) => {
			setHasShowLocations(true);
			onChange(nextValue);
		},
		[onChange]
	);

	const handleFocusedCityYes = useCallback(() => {
		if (!hasShowLocations) {
			setHasShowLocations(true);
		}
	}, [hasShowLocations]);

	const handleFocusedCityNo = useCallback(() => {
		if (hasShowLocations) {
			setTimeout(() => setHasShowLocations(false), 200);
		}
	}, [hasShowLocations]);

	return (
		<div
			className={classNames(
				className,
				cls.SearchCity,
				hasShowLocations &&
					value?.length > 0 &&
					((result.cards && result.cards?.length > 0) || (result.places && result.places?.length > 0)) &&
					cls.active
			)}
			onBlur={handleFocusedCityNo}
		>
			<div className={cls.inputWrapper}>
				<IconMouse className={cls.iconMouse} />
				<MobileInput
					className={classNames(cls.input, !value && cls.inputEmpty)}
					placeholder={t(`common.filtersModal.inputAddress.near`)}
					value={value}
					onChange={handleChangeCity}
					onFocused={handleFocusedCityYes}
				/>
				<div className={cls.inputInfo}>{value ? countryName : ''}</div>
				<div className={cls.iconRight}>
					{value?.length > 3 && isLoading && (
						<div className={cls.ldsRing}>
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					)}
					{value?.length > 0 && !isLoading && <IconCross className={cls.icon} onClick={handleCityClear} />}
				</div>
			</div>
			{hasShowLocations &&
				value?.length > 0 &&
				((result.places && result.places.length > 0) || (result.cards && result.cards.length > 0)) && (
					<div className={cls.cities}>
						<AnimatePresence mode='wait'>
							<motion.div
								style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
								key={value}
								initial={{ y: 10, opacity: 0, display: 'none' }}
								animate={{ y: 0, opacity: 1, display: 'flex' }}
								exit={{ y: -10, opacity: 0, display: 'none' }}
								transition={{ duration: 0.2 }}
							>
								{result.places && result.places.length > 0 && (
									<>
										<h5 className={cls.title}>{t('common.filters.autocomplete_places')}</h5>
										{result.places.map((i, index) => {
											return (
												<Flex key={index} gap='14px' className={cls.name} onClick={handleChangeLocation(i)}>
													<IconPin />
													<Flex gap='4px' direction='column'>
														<p>{i.title}</p>
														<span>
															{i.type && apiCmnTranslations(`location.${i.type}.name`)}
															{i.type && ', '}
															{i.address ?? getCountryName(i.cc)}
														</span>
														<span>{getDistance(i.distance, t)}</span>
													</Flex>
												</Flex>
											);
										})}
									</>
								)}

								{result.cards && result.cards.length > 0 && (
									<>
										<h5 className={cls.title}>{t('common.filters.autocomplete_cards')}</h5>
										{result.cards.map((i, index) => {
											return (
												<Flex key={index} gap='14px' className={cls.name} onClick={handleChangeObject(i)}>
													<IconPin />
													<Flex gap='4px' direction='column'>
														<p>{i.title}</p>
														<span>
															{t(`common.types.${i.type}`)}
															{i.cc && ', '}
															{i.cc && apiCmnTranslations(`country.${i.cc}.name`)}
														</span>
														<span>{getDistance(i.distance, t)}</span>
													</Flex>
												</Flex>
											);
										})}
									</>
								)}
							</motion.div>
						</AnimatePresence>
					</div>
				)}
		</div>
	);
});
