import classNames from 'classnames';
import React, { ReactNode, memo, ButtonHTMLAttributes } from 'react';

import cls from './MobileButton.module.css';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
	className?: string;
	children: ReactNode;
	maxWidth?: boolean;
	theme?: 'outline' | 'invert' | 'white' | 'default' | 'mapToggleBtn';
	disable?: boolean;
}

export const MobileButton = memo((props: IButtonProps) => {
	const { className, children, theme = 'default', maxWidth, disable = false, ...otherProps } = props;

	return (
		<button
			className={classNames(className, cls.Button, cls[theme], {
				[cls.disable]: disable,
				[cls.maxWidth]: maxWidth,
			})}
			{...otherProps}
		>
			{children}
		</button>
	);
});
