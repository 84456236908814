import classNames from 'classnames';
import React, { memo, useCallback, useMemo } from 'react';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { useQueryClient } from '@tanstack/react-query';
import { IconDoorLogin, IconDoorLogout, IconProfileHeader, IconTicket, IconUser } from '@new/components/icon';
import { useFetchCountNew } from '@common/dataContext/messages';

import { Flex } from '../ui';
import { useAuthContext } from '../../auth';

import cls from './LoginButton.module.css';

export interface ILoginButtonProps {
	className?: string;
}

export const LoginButton = memo((props: ILoginButtonProps) => {
	const { className } = props;
	const router = useRouter();
	const { t } = useTranslation('ui');
	const queryClient = useQueryClient();
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { logout, getHasAnonymous } = useAuthContext();
	const isLogin = !getHasAnonymous();
	const { data } = useFetchCountNew(isLogin, 60 * 10 * 1000);

	const signOut = useCallback(async () => {
		await logout();
		if (router.pathname.startsWith('/profile')) {
			await router.push('/');
		}

		void queryClient?.invalidateQueries();
	}, [router]);

	const currentView = useMemo(() => {
		if (!isLogin) {
			return (
				<Link href='/login' className={cls.loginButton}>
					<IconDoorLogin className={cls.desktop} />
					<IconProfileHeader className={cls.mobile} />
					<span>{t('header.signIn')}</span>
				</Link>
			);
		}
		return (
			<>
				<Link href='/profile/settings' className={cls.loginButton}>
					<IconUser />
					<span className={cls.desktop}>{t('header.profile')}</span>
				</Link>
				<Link href='/profile/orders' className={classNames(cls.OrderLink, data && data > 0 && cls.OrderLinkActive)}>
					<IconTicket />
					<span className={cls.desktop}>{t('header.tickets')}</span>
				</Link>
				<div onClick={signOut} className={cls.desktop}>
					<IconDoorLogout />
					<span>{t('header.signOut')}</span>
				</div>
			</>
		);
	}, [isLogin, data]);

	return (
		<Flex className={classNames(cls.login, className)} alignItems='center' gap='20px'>
			{currentView}
		</Flex>
	);
});
