import { useEffect, useMemo } from 'react';
import { useLocalStorage } from 'usehooks-ts';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import { pick } from 'lodash';

import { ILocalStorageGuestsState, IRoom } from '@new/pages/BookingHotel/types';
import { LOCAL_STORAGE_BOOKING } from '@new/pages/BookingHotel/BookingHotel';
import { urlParams } from '@common/helpers/urtParams';

export const useHotelGuestState = () => {
	const { asPath: url } = useRouter();

	const guestsData: ILocalStorageGuestsState | undefined = useMemo(() => {
		const currentDayBase = dayjs().add(5, 'days');
		const currentDay = currentDayBase
			.subtract(currentDayBase.hour(), 'hours')
			.subtract(currentDayBase.minute(), 'minute')
			.subtract(currentDayBase.second(), 'second')
			.subtract(currentDayBase.millisecond(), 'milliseconds')
			.add(14, 'hours');
		const fromDate = currentDay;
		const toDate = currentDay.add(1, 'days').subtract(currentDay.hour(), 'hours').add(12, 'hours');

		return {
			toDate: toDate.format('YYYY-MM-DD'),
			fromDate: fromDate.format('YYYY-MM-DD'),
			rooms: [
				{
					adults: 2,
					children: [],
				},
			],
		};
	}, []);
	const [bookGuestsState, setGuestsState] = useLocalStorage(`qvd/${LOCAL_STORAGE_BOOKING}/hotel/guests`, guestsData);

	useEffect(() => {
		const params = pick(urlParams().parse(url), ['fromDate', 'toDate', 'rooms']);
		const { fromDate, toDate } = bookGuestsState;

		if (Object.keys(params).length > 0) {
			setGuestsState({
				...bookGuestsState,
				fromDate: (params['fromDate'] as string) ?? fromDate,
				toDate: (params['toDate'] as string) ?? toDate,
				rooms: params['rooms'] as IRoom[],
			});
		}

		const now = dayjs();
		const mFromDate = dayjs(fromDate);
		const mToDate = dayjs(toDate);
		const hasFromDateBeforeNow = mFromDate.isBefore(now, 'days');

		if (hasFromDateBeforeNow) {
			const diffRange = mToDate.diff(mFromDate, 'days');
			setGuestsState({
				...bookGuestsState,
				fromDate: now.format('YYYY-MM-DD'),
				toDate: now.add(diffRange, 'days').format('YYYY-MM-DD'),
			});
		}
	}, []);

	return {
		bookGuestsState,
		setGuestsState,
	};
};
