import React, { ElementType, memo, useCallback } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Flex } from '@main/src/new/components/ui';
import { IconProfile } from '@main/src/new/components/icon';

import { HotelFilterInput } from '../HotelFilterInput';
import { Counter } from '../../../Filters/GuestsInput/components/Counter';

import cls from '../../../Filters/GuestsInput/components/HotelFilterGuests/HotelFilterGuests.module.css';

export interface IRoom {
	adults: number;
	children: number[];
}

export interface IRoomProps {
	className?: string;
	index: number;
	room: IRoom;
	onChangeAdult(roomIndex: number): (v: number) => void;
	onChangeChild(roomIndex: number): (v: number) => void;
	onChangeChildAge(roomIndex: number, childIndex: number): (v: number) => void;
	onRemoveRoom(index: number): () => void;
	wPreview?: boolean;
	onEdit?: (index: number | null) => void;
	isEdit?: boolean;
}

export const Room = memo((props: IRoomProps) => {
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { index, room, onChangeChildAge, onChangeChild, onChangeAdult, onRemoveRoom, wPreview, onEdit, isEdit } = props;
	const { t } = useTranslation('ui');
	const { t: apiHotelTranslation } = useTranslation('api_hotel');
	const isShowChildrenFields = room.children.length > 0;

	const handlerCustomViewValueFn = useCallback((value: number) => {
		return t('common.age', { count: value });
	}, []);

	const getRoomValue = (room: IRoom, i: number) => {
		const res: string[] = [apiHotelTranslation('search.index_room', { count: i + 1 })];
		res.push(apiHotelTranslation('search.count_adults', { count: room.adults }));
		if (room.children.length) res.push(apiHotelTranslation('search.count_children', { count: room.children.length }));

		return res.join(', ');
	};

	const onTitleClick = () => {
		onEdit?.(isEdit ? null : index);
	};

	return (
		<Flex className={cls.room} maxWidth direction='column' gap='10px'>
			<Flex direction='column' gap='10px' maxWidth key={index} onClick={onTitleClick}>
				<Flex maxWidth direction='row' justifyContent='between' alignItems='end'>
					<div className={cls.title}>
						{index + 1} {t('detail.room')}
					</div>
					<div className={cls.btnDelete}>
						{index > 0 && <div onClick={onRemoveRoom(index)}>{t('common.delete')}</div>}
					</div>
				</Flex>
				{wPreview && <HotelFilterInput value={getRoomValue(room, index)} icon={IconProfile as ElementType} invert />}
			</Flex>
			{isEdit && (
				<Flex direction='column' gap='10px' maxWidth>
					<Flex gap='10px' justifyContent='between' className={cls.item} alignItems='center' maxWidth>
						<p dangerouslySetInnerHTML={{ __html: t('_filter.booking.adults') }} />
						<Counter
							className={cls.counter}
							value={room.adults}
							onChange={onChangeAdult(index)}
							maxValue={6}
							minValue={1}
						/>
					</Flex>
					<Flex gap='10px' justifyContent='between' className={cls.item} alignItems='center' maxWidth>
						<p dangerouslySetInnerHTML={{ __html: t('_filter.booking.children') }}></p>
						<Counter
							className={cls.counter}
							value={room.children.length}
							onChange={onChangeChild(index)}
							maxValue={4}
							minValue={0}
						/>
					</Flex>
					{isShowChildrenFields && (
						<Flex className={cls.items} direction='column' gap='10px' maxWidth>
							{room.children.map((child, indexChild) => {
								return (
									<Flex
										key={`${index}-${indexChild}`}
										gap='10px'
										justifyContent='between'
										className={cls.item}
										alignItems='center'
										maxWidth
									>
										<p>
											<span>
												{indexChild + 1} {t('common.child')}
											</span>
										</p>
										<Counter
											className={cls.counter}
											value={child}
											onChange={onChangeChildAge(index, indexChild)}
											maxValue={17}
											customViewValueFn={handlerCustomViewValueFn}
										/>
									</Flex>
								);
							})}
						</Flex>
					)}
				</Flex>
			)}
		</Flex>
	);
});
