import { IS_PROD } from '@app/constants';
import { YandexMetric } from '@new/components/Metrics/components/Yandex';
import { PixelMail } from '@new/components/Metrics/components/PixelMail';
import { PixelMeta } from '@new/components/Metrics/components/PixelMeta';

export const Metrics = () => (
	<>
		{IS_PROD && (
			<>
				<YandexMetric />
				<PixelMail />
				<PixelMeta />
			</>
		)}
	</>
);
