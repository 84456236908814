import { qr } from '@app/stores/models';
import { combineCommonModels } from '@app/stores/helpers';
import { init, RematchStore } from '@rematch/core';

import { RootModel } from './types';
import { ICommonData } from '@main/src/types/common';

export const models = combineCommonModels<RootModel>({ qr });

export const makeStore = (initState: ICommonData): RematchStore<RootModel, RootModel> => {
	const state = init<RootModel>({ models }).getState();

	return init<RootModel>({
		models,
		redux: { initialState: { ...state, ...initState } },
	});
};
