import classNames from 'classnames';
import React, { MouseEvent, ReactNode, useMemo } from 'react';

import { Portal } from '../Portal';

import cls from './MobileModal.module.css';

interface IModalDefaultProps {
	className?: string;
	isOpen?: boolean;
	close?: () => void;
	children: ReactNode;
	zIndex?: number;
	wrapperClassName?: string;
}
interface IModalPositionBottom extends IModalDefaultProps {
	type: 'drawer';
	top?: string;
	position?: 'bottom' | 'top' | 'center';
}
interface IModalPositionCenter extends IModalDefaultProps {
	type: 'popup';
	position?: 'bottom' | 'top' | 'center';
}

type TModalProps = IModalPositionBottom | IModalPositionCenter;

export const MobileModal = ({
	className,
	isOpen = false,
	close,
	children,
	type,
	zIndex = 997,
	wrapperClassName,
	...props
}: TModalProps) => {
	const handlerClick = (e: MouseEvent<HTMLElement>) => {
		e.stopPropagation();
	};

	const styleProps = useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (type === 'drawer' && props.top) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			const top = props.top || '0%';
			return {
				// eslint-disable-next-line @typescript-eslint/restrict-template-expressions
				height: `calc(100% - ${top})`,
				// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
				top: top,
				borderRadius: top === '0%' ? 0 : ' 12px 12px 0 0',
			};
		}
		return {};
	}, [type, props]);

	return (
		<Portal>
			<div
				className={classNames(cls.Modal, cls[type], isOpen && cls.open, wrapperClassName)}
				onClick={close}
				style={{ zIndex: zIndex }}
			>
				<div
					onClick={handlerClick}
					className={classNames(
						cls.content,
						!isOpen && cls.close,
						className,
						type === 'popup' && cls[props.position || 'center']
					)}
					style={styleProps}
				>
					{children}
				</div>
			</div>
		</Portal>
	);
};
