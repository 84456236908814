import { useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Flex } from '@main/src/new/components/ui';

import { MAX_ROOMS } from '../../../Filters/GuestsInput/components/HotelFilterGuests/HotelFilterGuests';
import { IRoom, Room } from '../Room';

import cls from './FilterModalGuestsEdit.module.css';

interface FilterModalGuestsEditProps {
	rooms: IRoom[];
	onChangeAdult(roomIndex: number): (v: number) => void;
	onChangeChild(roomIndex: number): (v: number) => void;
	onChangeChildAge(roomIndex: number, childIndex: number): (v: number) => void;
	onAddRoom(): void;
	onRemoveRoom(v: number): () => void;
}

export const FilterModalGuestsEdit = ({
	rooms,
	onChangeAdult,
	onChangeChild,
	onChangeChildAge,
	onAddRoom,
	onRemoveRoom,
}: FilterModalGuestsEditProps) => {
	const { t } = useTranslation('ui');
	const [activeIndex, setActiveIndex] = useState<null | number>(null);

	return (
		<Flex direction='column' className={cls.FilterModalGuestsEdit}>
			<Flex direction='column' className={cls.roomList} gap='16px'>
				{rooms.map((room, index) => (
					<Room
						key={index}
						room={room}
						index={index}
						onChangeAdult={onChangeAdult}
						onChangeChild={onChangeChild}
						onChangeChildAge={onChangeChildAge}
						onRemoveRoom={onRemoveRoom}
						wPreview
						onEdit={setActiveIndex}
						isEdit={activeIndex === index}
					/>
				))}
				{rooms.length < MAX_ROOMS && (
					<Flex
						className={cls.btn}
						maxWidth
						gap='10px'
						alignItems='center'
						justifyContent='flex-center'
						onClick={onAddRoom}
					>
						{t('booking.btns.addRoom')}
					</Flex>
				)}
			</Flex>
		</Flex>
	);
};
