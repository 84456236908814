/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState, useRef, useCallback } from 'react';

export const useDestroy = (handler: any) => {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	useEffect(() => handler, []);
};

export type TimerState = 'init' | 'runned' | 'stoped';

export const useTimer = (tickInterval: number, step: number) => {
	const [timerTicks, setTicks] = useState(0);
	const [timerState, setTimerState] = useState<TimerState>('init');
	const timerId = useRef<any>(0);

	const runTicks = useCallback(
		(currentTick: number) => {
			setTicks(currentTick);
			const nextTick = currentTick - step;
			if (nextTick >= 0) {
				timerId.current = setTimeout(
					tick => {
						runTicks(tick);
					},
					tickInterval,
					nextTick
				);
			} else {
				setTimerState('stoped');
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				clearTimeout(timerId.current);
			}
		},
		[tickInterval, step]
	);

	const startTimer = useCallback(
		(tickCount: number) => {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			clearTimeout(timerId.current);
			runTicks(tickCount);
			setTimerState('runned');
		},
		[runTicks, setTimerState]
	);

	const stopTimer = useCallback(() => {
		setTimerState('stoped');
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		clearTimeout(timerId.current);
	}, [setTimerState]);

	const resetTimer = useCallback(() => {
		setTimerState('init');
		setTicks(0);
	}, [setTimerState]);

	// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
	useDestroy(() => clearTimeout(timerId.current));

	return {
		timerTicks,
		timerState,
		startTimer,
		stopTimer,
		resetTimer,
	};
};
