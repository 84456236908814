import React, { InputHTMLAttributes, memo, ReactNode, useState } from 'react';
import cls from './SelectBooking.module.css';
import classNames from 'classnames';
import { Tooltip } from '@new/components/ui/Tooltip';
import { IconInfoCircle } from '@new/components/icon';
import { ISelectOption } from '@new/pages/BookingHotel/types';
import { Select } from 'antd';
import { DefaultOptionType } from 'rc-select/lib/Select';

interface IInputProps {
	className?: string;
	label?: string;
	error?: string;
	isError?: boolean;
	invalid?: boolean;
	labelPopupContent?: ReactNode;
	options: ISelectOption[];
	required?: boolean;
	value?: string;
	onChange?: (value: string, option: DefaultOptionType | DefaultOptionType[]) => void;
	disabled?: boolean;
}

export const SelectBooking = memo((props: IInputProps) => {
	const { className, label, required, error, isError, invalid, labelPopupContent, options, disabled, ...otherProps } =
		props;

	return (
		<div
			className={classNames(
				className,
				'SelectBooking',
				cls.container,
				required && cls.required,
				invalid && cls.invalid
			)}
		>
			{label && !labelPopupContent && <p className={cls.label}>{label}</p>}
			{label && labelPopupContent && (
				<Tooltip classNamePopup={cls.popup} content={labelPopupContent}>
					<div className={cls.toolTipWrapper}>
						<p className={cls.label}>{label}</p> <IconInfoCircle />
					</div>
				</Tooltip>
			)}
			<Select options={options} value={otherProps.value} onChange={otherProps.onChange} disabled={disabled} />
			{error && isError && <div className={cls.error}>{error}</div>}
		</div>
	);
});
