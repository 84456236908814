import { Models } from '@rematch/core';
import {
	countries,
	currency,
	locale,
	user,
	useCookie,
	currencyRates,
	mobileStoreUrl,
	hasAnonymous,
	hasMobile,
} from '../models';

export const combineCommonModels = <T extends Models<T>>(model: T): T => {
	const commonModels: Models<T> = {
		countries,
		currency,
		locale,
		user,
		useCookie,
		currencyRates,
		mobileStoreUrl,
		hasAnonymous,
		hasMobile,
	};

	return { ...model, ...commonModels };
};
