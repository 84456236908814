import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { ICommonData, IUserLocation } from '@main/src/types/common';
import { getCurrentUserLocation } from '@common/helpers/filters/getCurrentUserLocation';
import { DEFAULT_LOCALE } from '@app/constants/locales';

import { useLoginGeoState } from './useLoginGeoState';

export const useUserLocation = ({ locationName }: { locationName?: string }) => {
	const { loginGeo } = useLoginGeoState();
	const { locale } = useSelector((state: ICommonData) => state);
	const [city, setCity] = useState<string>('');

	const currentUserLocation: IUserLocation = getCurrentUserLocation({
		locale: locale?.code || DEFAULT_LOCALE.code,
		geo: loginGeo,
		locationName,
	});

	const [currentCity] = (currentUserLocation?.city.display_name || '').split(',');

	useEffect(() => {
		setCity(currentCity);
	}, [currentCity]);

	return {
		currentUserLocation,
		city,
		setCity,
	};
};
