import React, { FC, memo, useCallback, useState } from 'react';
import cls from './GoToMobile.module.css';
import classNames from 'classnames';
import Image from 'next/image';
import { IconCloseRed } from '@new/old/Icons';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { setCookies } from 'cookies-next';
import { COOKIE_MOBILE_ONBOARD } from '@app/constants/cookie';

interface IGoToMobileProps {
	className?: string;
	url: string;
}

export const GoToMobile = memo((props: IGoToMobileProps) => {
	const { className, url } = props;
	const { t } = useTranslation('ui');
	const [hasOpen, setHasOpen] = useState<boolean>(true);
	const handleClick = useCallback(() => {
		setCookies(COOKIE_MOBILE_ONBOARD, true, {
			maxAge: 60 * 60 * 24 * 365,
		});
		setHasOpen(false);
	}, []);

	if (hasOpen) {
		return (
			<>
				<div className={cls.blur}></div>
				<div className={classNames(className, cls.GoToMobile)}>
					<IconCloseRed onClick={handleClick} />
					<Image src='/statics/new/ICO.png' alt='icon' width={200} height={200} />
					<p>{t('_modalBlur.title')}</p>
					<div className={cls.btnWrapper} onClick={handleClick}>
						<Link href={url} className='ant-btn ant-btn-primary btn--big' target='_blank'>
							{t('_modalBlur.btn')}
						</Link>
					</div>
				</div>
			</>
		);
	}

	return null;
});
