import { IconPublicPlace } from '@main/src/new/components/icon';
import { IconActivity, IconKingBad, IconLunchBurger, IconTour } from '@new/old/Icons';
import React from 'react';

export interface ITypeDesc {
	image: string;
	icon: React.ReactElement;
	type: string;
	key: string;
}
export const LIST_TYPES: ITypeDesc[] = [
	{
		key: 'common.filters.types.activity',
		icon: <IconActivity />,
		type: 'activity',
		image: '/statics/new/filters/activity.png',
	},
	{
		key: 'common.filters.types.attraction',
		icon: <IconTour />,
		type: 'attraction',
		image: '/statics/new/filters/attraction.png',
	},
	{
		key: 'common.filters.types.restaurant',
		icon: <IconLunchBurger />,
		type: 'restaurant',
		image: '/statics/new/filters/restaurant.png',
	},
	{
		key: 'common.filters.types.accommodation',
		icon: <IconKingBad />,
		type: 'accommodation',
		image: '/statics/new/filters/accommodation.png',
	},
	{
		key: 'common.filters.types.publicPlace',
		icon: <IconPublicPlace />,
		type: 'public_place',
		image: '/statics/new/filters/public_place.png',
	},
];
