import classNames from 'classnames';
import React, { memo, useCallback, useMemo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Flex } from '@main/src/new/components/ui';
import { IconArrowBlueDown } from '@main/src/new/components/icon';

import { ItemTag } from './components/ItemTag';

import cls from './GroupFilters.module.css';

interface IGroupFiltersProps {
	className?: string;
	title: string;
	tags: {
		key: string;
		value: string;
		count: number;
	}[];
	type: string;
	onChangeLocalParams(value: string): () => void;
	params?: string[];
}

export const GroupFilters = memo((props: IGroupFiltersProps) => {
	// eslint-disable-next-line @typescript-eslint/unbound-method
	const { className, tags, title, type, onChangeLocalParams, params } = props;
	const tagsTranslations = useTranslation('api_cat');
	const { t } = useTranslation('ui');
	const [isFull, setFull] = useState(false);
	const handlerShowFull = useCallback(() => {
		setFull(true);
	}, []);
	const items = useMemo(() => {
		let list = tags;
		if (!isFull) {
			list = tags.filter((val, index) => index < 3);
		}

		return list
			.map(i => {
				const tag = `${type}.${i.key}.name`;
				let translatedTag = tagsTranslations.t(i.value);
				if (translatedTag === i.value && i.value.includes('lang')) {
					translatedTag = tagsTranslations.t(`activity.${i.value}`);
				}

				if (tag !== translatedTag) {
					return (
						<ItemTag
							key={i.key}
							onClick={onChangeLocalParams(i.key)}
							value={(params || []).includes(i.key)}
							translatedTag={translatedTag}
							tag={i}
						/>
					);
				}

				return null;
			})
			.filter(Boolean);
	}, [tags, isFull, type, params]);

	return (
		<div className={classNames(className, cls.GroupFilters)}>
			<p className={cls.title}>{tagsTranslations.t(title)}</p>
			<Flex gap='8px' direction='column'>
				{items}
				{tags.length > 3 && !isFull && (
					<Flex gap='10px' className={cls.showAll} onClick={handlerShowFull}>
						<p>{t('search.filters.showAll')}</p>
						<IconArrowBlueDown />
					</Flex>
				)}
			</Flex>
		</div>
	);
});
