import React, { memo, useState } from 'react';
import Link from 'next/link';
import useTranslation from 'next-translate/useTranslation';

import { Flex } from '@new/components/ui';
import { IconLogoWhite, IconMenu } from '@new/components/icon';
import { LoginButton } from '@new/components/LoginButton';
import { LangCurrencyButton } from '@main/src/new/components/LangCurrencyButton';

import { NavigateModal } from '../NavigateModal';

import cls from './Navigate.module.css';
import { useRouter } from 'next/router';

export const Navigate: React.FC = memo(() => {
	const { t } = useTranslation('ui');

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const handlerHomeClick = (e: React.MouseEvent<HTMLElement>) => {
		e.stopPropagation();
		e.preventDefault();

		window.location.href = '/';
	};

	return (
		<>
			<Flex className={cls.Navigate} alignItems='center' justifyContent='between'>
				<div className={cls.left}>
					<div className={cls.navBtn} onClick={() => setIsMenuOpen(true)}>
						<IconMenu className={cls.navBtnIcon} />
					</div>
					<Link href='/' onClick={handlerHomeClick}>
						<IconLogoWhite />
					</Link>
					<Flex alignItems='center' gap='30px' className={cls.menu}>
						<Link href='/'>{t('header.home')}</Link>
						<Link href='/blog'>{t('header.blog')}</Link>
						<Link href='/about'>{t('header.about')}</Link>
						<Link href='/b2b'>{t('header.b2b')}</Link>
						<Link href='https://my.qvedo.com' passHref target='_blank'>
							{t('header.office')}
						</Link>
					</Flex>
				</div>
				<Flex className={cls.right} alignItems='center' gap='20px'>
					<LangCurrencyButton />
					<LoginButton />
				</Flex>
			</Flex>
			<NavigateModal isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
		</>
	);
});
