import { useRouter } from 'next/router';
import queryString from 'query-string';
import React, { memo, useCallback } from 'react';
import useTranslation from 'next-translate/useTranslation';

import { Flex } from '@main/src/new/components/ui';
import { IUserLocation } from '@main/src/types/common';
import { ESearchType } from '@main/src/types/pages/search';

import cls from './FiltersTypeMain.module.css';

interface IFiltersProps {
	className?: string;
	onChangeType?(type: ESearchType): () => void;
	userLocation?: IUserLocation;
	openFiltersModal?(): void;
}

export const LIST_TYPES = [
	{
		title: 'attraction',
		type: ESearchType.attraction,
		icon: '/statics/icon_bg_attraction.png',
	},
	{ title: 'activity', type: ESearchType.activity, icon: '/statics/icon_bg_activity.png' },
	{
		title: 'restaurant',
		type: ESearchType.restaurant,
		icon: '/statics/icon_bg_restaurant.png',
	},
	{
		title: 'hotel',
		type: ESearchType.accommodation,
		icon: '/statics/icon_bg_accommodation.png',
	},
	{
		title: 'institutions',
		type: ESearchType.publicPlace,
		icon: '/statics/icon_bg_public_place.png',
	},
];

export const FiltersTypeMain = memo(({ onChangeType, userLocation, openFiltersModal }: IFiltersProps) => {
	const router = useRouter();
	const { t } = useTranslation('ui');

	const handleFilter = useCallback(
		(listType: ESearchType) => {
			if (listType === 'accommodation') {
				onChangeType?.(ESearchType.accommodation)();
				openFiltersModal?.();
				return;
			}
			const queryStringParams = queryString.stringify(
				{
					center: userLocation?.point,
					zoom: 12,
					type: listType,
				},
				{ arrayFormat: 'comma' }
			);

			void router.replace({
				pathname: '/search',
				query: queryStringParams,
			});
		},
		[router, userLocation?.point, onChangeType, openFiltersModal]
	);

	return (
		<Flex alignItems='center' justifyContent='between' maxWidth gap='10px' wrap className={cls.FiltersTypeMain}>
			{LIST_TYPES.map((listType, index) => {
				return (
					<div key={index} onClick={() => handleFilter(listType.type)}>
						<img src={listType.icon} alt={listType.title} />
						<p dangerouslySetInnerHTML={{ __html: t(`common.filtersMain.${listType.title}`) }} />
					</div>
				);
			})}
		</Flex>
	);
});
