import React, { memo } from 'react';
import classNames from 'classnames';

import { IconCheck } from '@main/src/new/old/Icons';

import cls from './Checkout.module.css';

interface ICheckoutProps {
	className?: string;
	value?: boolean;
	disable?: boolean;
	onClick?: () => void;
}

export const Checkout = memo(({ className, value = false, disable = false, onClick }: ICheckoutProps) => {
	return (
		<div className={classNames(className, cls.Checkout, disable && cls.disable)} onClick={onClick}>
			{value && <IconCheck />}
		</div>
	);
});
