import classNames from 'classnames';

import { Flex } from '@main/src/new/components/ui';

import cls from './HotelFilterInput.module.css';

interface HotelFilterInputProps {
	value: string;
	className?: string;
	icon?: React.ElementType;
	invert?: boolean;
}

export const HotelFilterInput = ({ className, value, icon: Icon, invert }: HotelFilterInputProps) => {
	return (
		<Flex
			className={classNames(cls.HotelFilterInput, className, invert && cls.invert)}
			gap='10px'
			justifyContent='flex-start'
		>
			{Icon && <Icon />} <p>{value}</p>
		</Flex>
	);
};
