import type { ConfigProps } from 'antd/lib/notification';
import notification from 'antd/lib/notification';
import { notificationConfig } from '@app/constants';

export const useNotification = () => {
	notification.config(notificationConfig);

	return {
		info: (title = '', description = '', config: ConfigProps = {}) =>
			notification.info({
				message: title,
				description: description,
				...config,
			}),
		success: (title = '', description = '', config: ConfigProps = {}) =>
			notification.success({
				message: title,
				description: description,
				...config,
			}),
		error: (title = '', description = '', config: ConfigProps = {}) =>
			notification.error({
				message: title,
				description: description,
				...config,
			}),
	};
};
